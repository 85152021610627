import { defineStore } from '#src/stores/state-wrapper.js';

import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';
import { usePrimaryInsuredStore, INSURED_TYPES } from '#src/stores/insured.js';
import { useEappStore } from '#src/stores/electronic-application.js';

import { CONCEPTS, PRODUCTS, ProductType } from '#src/structures/ProductType.js';

import { SocketService } from '#src/services/socket.service.js';
import { InitializationsService } from '#src/services/initializations.service.js';
import { IllustrationsService } from '#src/services/illustrations.service.js';
import { QuotesService } from '#src/services/quotes.service.js';
import { validatePartyData } from '#src/structures/quote-params/QuoteParameterValidation.js';

export function useQuotingStore(pinia, hot) {
  const illustrationsService = new IllustrationsService(pinia);
  const initializationsService = new InitializationsService(pinia);
  const quotesService = new QuotesService(pinia);
  const socketService = new SocketService(pinia);
  return defineStore('quoting', {
    state: () => ({
      rollbackPoint: {},
      useDefaultsOnCompare: true,
      requestPreferences: {
        carriers: [],
        products: [],
      },
      params: {
        age_value: null,
        benefit_period: null,
        cash_value: null,
        crediting_rate: null,
        discount: null,
        elimination_period: null,
        face_amount: null,
        monthly_benefit: null,
        partner_discount: null,
        filter_chronic_illness_and_ltc: null,
        guaranteed_issue: null,
        home_health_care_waiver: null,
        income_end_age: null,
        income_solve: null,
        income_start_age: null,
        inflation_percentage: null,
        lapse_protection_to_age: null,
        loan_type: null,
        living_benefits: null,
        ltc_rider_percentage: null,
        mode: null,
        pool_of_money: null,
        pay_duration: null,
        premium: null,
        return_of_premium_rider: null,
        section_1035_exchange_amount: null,
        save_age: null,
        selected_type: null,
        subtype: null, // not stored in db
        shared_care: null,
        joint_waiver_of_premium: null,
        solve: null,
        state: null,
        term_duration: null,
        vitality_level: null,
      },
    }),
    getters: {
      applicationState() {
        return this.params.state;
      },
      selectedQuoteType() {
        return this.rollbackPoint?.selected_type;
      },
      selectedProductType() {
        return new ProductType(this.selectedQuoteType);
      },
      partnerDiscountVerboseInfoIsValid() {
        const items = validatePartyData(pinia, INSURED_TYPES.JOINT, {
          verbose: true,
          requireMaritalStatus: true,
        });
        return Object.values(items).every((v) => v.validation.length === 0);
      },
      partnerDiscountBasicInfoIsValid() {
        const items = validatePartyData(pinia, INSURED_TYPES.JOINT, {
          verbose: false,
          requireMaritalStatus: true,
        });
        return Object.values(items).every((v) => v.validation.length === 0);
      },
      // TODO: drop this
      baseQuoteParams() {
        const insured = usePrimaryInsuredStore(pinia);

        const instanceSettings = useInstanceSettingsStore(pinia);
        const eApp = useEappStore(pinia);
        return {
          mode: this.params.mode,
          state: this.params.state,
          birthdate: insured.birthdate.model,
          gender: insured.gender.model,
          first_name: insured.first_name.model,
          last_name: insured.last_name.model,
          height: insured.height.model,
          weight: insured.weight.model || null,
          smoker: insured.smokerForRequests.smoker,
          health: insured.health.model,
          usages: insured.smokerForRequests.usages,
          agent: { id: instanceSettings.agent.id },
          electronic_application_id: eApp.uuid || undefined,
        };
      },
    },
    actions: {
      setRollbackPoint() {
        this.rollbackPoint = { ...this.params };
      },
      getQuotes({
        params,
        websocket_uuid,
        pdf = false,
        summary = undefined,
        scenarios = undefined,
      } = {}) {
        const instance = useInstanceSettingsStore(pinia);
        const body = {
          quote: params,
          summary,
          websocket_uuid,
          scenarios,
          deferred: true,
          domain_key: instance.domain_key,
        };

        if (this.requestPreferences.carriers.length) {
          body.quote.carriers = this.requestPreferences.carriers;
        }
        if (this.requestPreferences.products.length && !body.quote?.products?.length) {
          body.quote.products = this.requestPreferences.products;
        }

        if (scenarios) body.scenarios = scenarios;

        return quotesService.getQuotes({ pdf, body });
      },
      async getQuoteIllustration() {
        // TODO: FIX THIS
        //eslint-disable-next-line
        return new Promise(async (resolve, reject) => {
          let pusher;
          const pusherDisconnect = () => {
            if (pusher.disconnect) pusher.disconnect();
          };
          const failHandler = pusherDisconnect;
          const endHandler = async () => {
            pusherDisconnect();
            const settingStore = useInstanceSettingsStore(pinia);
            const eApp = useEappStore(pinia);
            const params = new URLSearchParams();
            params.append('electronic_application_id', eApp.id);
            params.append('uuid', eApp.uuid);
            const query = params.toString();
            const illustrationUrl = `${settingStore.api_url}/strife/v1/illustrations/${eApp.selectedQuote.real_id}?${query}`;
            resolve(illustrationUrl);
          };

          const eApp = useEappStore(pinia);
          const illustrationFetch = async () => {
            try {
              const { status } = await illustrationsService.getIllustration(
                eApp.selectedQuote.real_id,
              );
              if (status === 200) {
                pusherDisconnect();
                endHandler();
              }
            } catch (e) {
              pusherDisconnect();
              reject(e);
            }
          };
          try {
            pusher = await socketService.initSocketForIllustration(illustrationFetch, eApp.uuid, {
              failHandler,
              endHandler,
            });
          } catch (e) {
            reject(e);
          }
        });
      },
      handleProductChange() {
        const productType = new ProductType(this.params.selected_type);
        const defaults = productType?.defaults;
        let productConverter = {};
        if (productType?.eAppParser) {
          const insuredStore = usePrimaryInsuredStore(pinia);
          productConverter = productType.eAppParser({
            ...this.params,
            age: insuredStore.age,
          });
        }
        Object.keys(productConverter).forEach((key) => {
          if (!(key in this.params)) return;
          let value = productConverter[key] || defaults[key];
          this.params[key] = value;
        });
      },
      initializeQuoteParams(payload) {
        Object.keys(payload).forEach((key) => {
          if (!(key in this.params)) return;
          this.params[key] = payload[key];
        });
      },
      rollbackQuoteParams() {
        this.params = this.rollbackPoint;
      },
      storeQuoteParamsFromQuote(payload) {
        for (const [key, value] of Object.entries(payload)) {
          if (!(key in this.params)) continue;
          this.params[key] = value;
        }
      },
      storeQuoteParametersFromEApp(payload, primaryQuote) {
        let productParsedValues = {};
        this.useDefaultsOnCompare = false;

        const params = {
          ...payload,
          ...primaryQuote,
        };

        const { selected_type, ...restOfParams } = params;

        if (selected_type) {
          const ulSubtypes = [
            PRODUCTS.ACCUMULATION_IUL,
            PRODUCTS.PROTECTION_IUL,
            PRODUCTS.PROTECTION_VUL,
            PRODUCTS.UNIVERSAL_LIFE,
          ];

          if (ulSubtypes.includes(selected_type)) {
            params.selected_type = CONCEPTS.ALL_UL;
            params.subtype = selected_type;
          } else {
            params.selected_type = selected_type;
          }

          const productType = new ProductType(selected_type);
          const insured = usePrimaryInsuredStore(pinia);
          if (productType?.eAppParser) {
            productParsedValues = productType.eAppParser({
              ...restOfParams,
              age: insured.age,
            });
            delete productParsedValues.selected_type;
          }
        }

        Object.keys(this.params).forEach((key) => {
          if (key in productParsedValues) {
            this.params[key] = productParsedValues[key];
          } else if (key in params) {
            this.params[key] = params[key];
          }
        });

        if (this.params.benefit_period === null) {
          this.params.benefit_period = 4;
        }

        this.setRollbackPoint(this.params);
      },
      async setLocationByIp() {
        let quoteState, ipState;
        const instanceSettings = useInstanceSettingsStore(pinia);
        try {
          ipState = await initializationsService.fetchStateByIp();
          const value = this.params.state || instanceSettings.override_state || ipState;

          ipState = instanceSettings.agent.insurance_licenses.find(
            (license) => license.state === value,
          )?.state;
          if (ipState) quoteState = ipState;
        } catch (e) {
          //do nothing
        }
        if (!quoteState) {
          const instanceSettings = useInstanceSettingsStore(pinia);
          const agent = instanceSettings.agent;
          if (agent.insurance_licenses?.length) {
            let license = agent.insurance_licenses.find((license) => license.resident);
            if (!license) license = agent.insurance_licenses[0];
            quoteState = license?.state;
          }
        }

        this.params.state = quoteState || ipState; // use IP as a fallback
      },
    },
  })(pinia, hot);
}
