export const QUESTION_TYPES = {
  SELECT: 'select',
  TEXT: 'text',
};

export function SelectQuestion(model = {}) {
  return {
    id: model?.id || null,
    question: model?.question || null,
    required: model?.required || null,
    selectOptions: model?.selectOptions || [],
    get inputType() {
      return QUESTION_TYPES.SELECT;
    },
  };
}

export function TextQuestion(model = {}) {
  return {
    id: model?.id || null,
    question: model?.question || null,
    required: model?.required || null,
    get inputType() {
      return QUESTION_TYPES.TEXT;
    },
  };
}

function setSelectQuestionFromRaw(raw = {}) {
  const question = SelectQuestion();
  question.id = raw.id;
  question.question = raw.question;
  question.selectOptions = [];
  question.required = raw.required;
  if (raw.select_options.length) {
    question.selectOptions = raw.select_options.map((r) => ({ text: r.text, value: r.value }));
  }
  return question;
}

function setTextQuestionFromRaw(raw = {}) {
  const question = TextQuestion();
  question.id = raw.id;
  question.question = raw.question;
  question.required = raw.required;
  return question;
}

export function setQuestionFromRaw(raw = {}) {
  switch (raw.input_type) {
    case QUESTION_TYPES.TEXT:
      return setTextQuestionFromRaw(raw);
    case QUESTION_TYPES.SELECT:
      return setSelectQuestionFromRaw(raw);
  }
}
