import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from '#src/stores/state-wrapper.js';

import { useStepHealthStore } from '#src/stores/step-health.js';
import { useStepNameStore } from '#src/stores/step-name.js';
import { useGenderForm } from '#src/components/steps/gender/gender-form.composable.js';

export const useStepGenderStore = (pinia, hot) =>
  defineStore('step-gender', {
    getters: {
      step: () => steps.GENDER,
      routeName: (s) => stepToRouteName[s.step],
      next: () => {
        const healthStep = useStepHealthStore(pinia);
        if (healthStep.inactive) return healthStep.next;
        return healthStep.step;
      },
      previous: () => {
        const name = useStepNameStore(pinia);
        return name.step;
      },
    },
    actions: {
      generateForm(module, args) {
        return useGenderForm(pinia, module, args);
      },
    },
  })(pinia, hot);
