export const insuredQuoteData = (insured) => ({
  birthdate: insured.birthdate.model,
  first_name: insured.first_name.model,
  gender: insured.gender.model,
  height: insured.height.model,
  last_name: insured.last_name.model,
  weight: insured.weight.model,
  visa_type: insured.visa_type.model,
  health: insured.health.model,
  usages: insured.smokerForRequests.usages,
  smoker: insured.smokerForRequests.smoker,
  marital_status: insured.marital_status.model,
});
