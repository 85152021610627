<template>
  <div>
    <v-text-field
      :id="$attrs['data-testid']"
      v-bind="$attrs"
      ref="inputref"
      v-model="model"
      v-maska:[options]
      :aria-label="$attrs?.label"
      inputmode="numeric"
    >
      <template v-if="$slots.message" #message="{ message }">
        <v-row v-if="`${message}`.trim()" class="ma-0">
          {{ message }}
          <v-spacer />
          <slot name="message" />
        </v-row>
        <slot v-else name="message" />
      </template>
      <template v-if="$slots.append" #append>
        <slot name="append" />
      </template>
      <template v-if="$slots.prepend" #prepend>
        <slot name="prepend" />
      </template>
      <template v-if="$slots['append-inner']" #append-inner>
        <slot name="append-inner" />
      </template>
    </v-text-field>
  </div>
</template>

<script setup>
import { vMaska } from 'maska';
import { watch, ref, toRef } from 'vue';

const props = defineProps({
  mask: {
    type: String,
    required: true,
    default: null,
  },
  modelValue: {
    type: [Number, String],
    required: false,
    default: null,
  },
});

const propMask = toRef(props, 'mask');
const options = {
  mask: propMask.value,
};

const model = ref(null);
const initialValue = toRef(props, 'modelValue');
if (initialValue.value || initialValue.value === 0) {
  model.value = initialValue.value;
}

watch(
  () => props.modelValue,
  () => {
    let v = null;
    if (props.modelValue || props.modelValue === 0) v = props.modelValue;
    if (v === model.value) return;

    model.value = v;
  },
  { flush: 'post' },
);

const emit = defineEmits(['update:modelValue']);
const update = (v) => emit('update:modelValue', v);

watch(
  model,
  () => {
    if (model.value === props.modelValue) return;
    update(model.value);
  },
  { flush: 'post' },
);

const inputref = ref(null); // template ref
function focus() {
  if (inputref.value?.focus) inputref.value.focus();
}

defineExpose({ focus });
</script>

<style lang="scss">
.v-text-field {
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}
</style>
