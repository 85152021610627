<template>
  <div>
    <v-text-field
      :id="dataTestid"
      v-model="email"
      type="email"
      name="email"
      required
      data-lpignore="true"
      :prepend-inner-icon="mdiEmailOutline"
      variant="outlined"
      density="compact"
      inputmode="email"
      :aria-label="$attrs?.label"
      :data-testid="dataTestid"
      :loading="validating"
      :autofocus="autofocus"
      :label="label"
      :class="{ 'success-field': success, 'error-field': errorMessages.length }"
      :base-color="success ? 'success' : 'basic'"
      :color="success ? 'success' : 'primary'"
      :error-messages="validating || isTyping ? [] : errorMessages"
      @update:model-value="handleTyping"
    >
      <template v-if="$slots['append']" #append>
        <slot name="append" />
      </template>
      <template v-if="$slots['append-inner']" #append-inner>
        <slot name="append-inner" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { ValidationsService } from '#src/services/validations.service';
import { email as emailValidator } from '#src/util/helpers';
import { mdiEmailOutline } from '@mdi/js';
import { inject } from 'vue';
export default {
  name: 'BasicEmailInput',
  props: {
    autofocus: Boolean,
    success: Boolean,
    errorMessages: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
    dataTestid: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'valid'],
  setup() {
    const pinia = inject('pinia');
    const validationsService = new ValidationsService(pinia);
    return { validationsService };
  },
  data() {
    return {
      emailTimer: null,
      emailLoading: false,
      validating: false,
      typingTimer: null,
      isTyping: false,
      mdiEmailOutline,
    };
  },
  computed: {
    email: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
        this.validate(v);
      },
    },
  },
  methods: {
    handleTyping() {
      this.isTyping = true;
      this.$emit('valid', null);
      if (this.typingTimer) clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        this.isTyping = false;
      }, 500);
    },
    async validate(val) {
      if (this.emailTimer) clearTimeout(this.emailTimer);

      if (!emailValidator(val)) return;

      this.emailTimer = setTimeout(async () => {
        this.validating = true;
        const result = await this.validationsService.validateEmail(val);
        this.$emit('valid', result);
        this.validating = false;
      }, 300);
    },
  },
};
</script>
