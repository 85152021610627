<template>
  <v-row
    data-testid="app-header"
    align="center"
    class="ma-0 px-6"
    :style="{ height: lgAndUp ? '64px' : '56px' }"
  >
    <app-progress v-if="isInternallyEmbedded" />
    <v-row v-if="showActionsRow" align="center">
      <v-tooltip v-if="showShare" attach="#application-flow" bottom aria-label="Share">
        <template #activator="{ props }">
          <v-btn
            data-testid="share"
            variant="text"
            v-bind="props"
            color="basic-icon"
            :icon="mdiShareVariant"
            aria-label="Share"
            @click="dialog.showShareApplicationDialog()"
          />
        </template>
        <span>Share</span>
      </v-tooltip>
      <v-tooltip
        v-if="showDarkModeToggle"
        bottom
        attach="#application-flow"
        :aria-label="isDarkMode ? 'Enable Light Mode' : 'Enable Dark Mode'"
      >
        <template #activator="{ props }">
          <v-btn
            data-testid="dark-mode"
            v-bind="props"
            variant="text"
            :aria-label="isDarkMode ? 'Enable Light Mode' : 'Enable Dark Mode'"
            :icon="isDarkMode ? mdiWeatherSunny : mdiWeatherNight"
            :color="isDarkMode ? 'orange' : '#880b9d'"
            @click="toggleDarkMode"
          />
        </template>
        <span>
          <template v-if="isDarkMode">Enable Light Mode</template>
          <template v-else>Enable Dark Mode</template>
        </span>
      </v-tooltip>

      <v-spacer />

      <v-btn
        v-if="showNeedHelp"
        data-testid="need-help"
        class="text-none"
        text
        @click="dialog.showHelpDialog()"
      >
        Need Help?
      </v-btn>
      <v-tooltip
        v-if="showModalFullscreenToggle"
        bottom
        attach="#application-flow"
        :aria-label="useFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'"
      >
        <template #activator="{ props }">
          <v-btn
            data-testid="modal-fullscreen-toggle"
            variant="text"
            v-bind="props"
            color="basic-icon"
            :aria-label="useFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'"
            :icon="useFullscreen ? mdiFullscreenExit : mdiFullscreen"
            @click="instance.useFullscreen = !instance.useFullscreen"
          />
        </template>
        <span v-if="useFullscreen">Exit Fullscreen</span>
        <span v-else>Enter Fullscreen</span>
      </v-tooltip>
      <v-tooltip v-if="showModalExit" bottom attach="#application-flow" aria-label="Exit">
        <template #activator="{ props }">
          <v-btn
            v-if="showModalExit"
            data-testid="modal-exit"
            v-bind="props"
            variant="text"
            data-dismiss="modal"
            color="basic-icon"
            aria-label="Exit"
            :icon="mdiClose"
            @click="widgetCommunicatorService.closeModal()"
          />
        </template>
        <span> Exit </span>
      </v-tooltip>
    </v-row>
    <app-progress v-if="!isInternallyEmbedded" />
  </v-row>
</template>

<script setup>
import {
  mdiShareVariant,
  mdiWeatherSunny,
  mdiWeatherNight,
  mdiFullscreenExit,
  mdiFullscreen,
  mdiClose,
} from '@mdi/js';

import { storeToRefs } from 'pinia';
import { useDialogStore } from '#src/stores/dialog';
import { useInstanceSettingsStore } from '#src/stores/instance-settings';
import { useEappStore } from '#src/stores/electronic-application';
import { useFlowStore } from '#src/stores/flow';

import AppProgress from '#src/components/AppProgress.vue';
import { useDisplay, useTheme } from 'vuetify';
import { WidgetCommunicatorService } from '#src/services/widget-communicator.service';
import { computed, inject } from 'vue';

const pinia = inject('pinia');

const widgetCommunicatorService = new WidgetCommunicatorService();
const theme = useTheme();
const dialog = useDialogStore(pinia);
const instance = useInstanceSettingsStore(pinia);
const flow = useFlowStore(pinia);
const eapp = useEappStore(pinia);

const { lgAndUp } = useDisplay();
const { activeStep } = storeToRefs(flow);
const { isDarkMode, chat, use_modal, use_webapp, isInternallyEmbedded, useFullscreen } =
  storeToRefs(instance);

const { selectedQuote, id: eAppId, isAdvisorUseOnly } = storeToRefs(eapp);

const showDarkModeToggle = computed(() => {
  return Boolean(use_webapp.value);
});

const showShare = computed(() => {
  return (
    Boolean(eAppId.value) &&
    Boolean(selectedQuote.value.id) &&
    !isAdvisorUseOnly.value &&
    !activeStep.value.hidesShare
  );
});

const showNeedHelp = computed(() => {
  return !chat.value;
});

const showModalFullscreenToggle = computed(() => {
  return lgAndUp.value && use_modal.value;
});

const showModalExit = computed(() => {
  return use_modal.value;
});

const showActionsRow = computed(() => {
  return [
    showShare.value,
    showDarkModeToggle.value,
    showModalFullscreenToggle.value,
    showModalExit.value,
    showNeedHelp.value,
  ].includes(true);
});

function toggleDarkMode() {
  isDarkMode.value = !isDarkMode.value;
  theme.global.name.value = isDarkMode.value ? 'dark' : 'light';
}
</script>
