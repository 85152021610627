import { defineStore } from '#src/stores/state-wrapper.js';
import { differenceInMonths } from 'date-fns/differenceInMonths';
import { parse } from 'date-fns/parse';
import { format } from 'date-fns/format';
import { isValid as dlValidation } from 'usdl-regex';

import { SMOKING_NEVER, SMOKING_STATUSES } from '#src/structures/SmokingUsage.js';

import {
  OWNER,
  JOINT_OWNER,
  PRIMARY_BENEFICIARY,
  CONTINGENT_BENEFICIARY,
  INSURED,
  JOINT_INSURED,
  SPOUSE,
  generateRoleData,
  PAYOR,
  JOINT_PAYOR,
} from '#src/structures/Role.js';

import {
  dateToYears,
  generateUuid,
  yearsToDate,
  percentToInteger,
  integerToPercent,
  timestampFormatter,
  dateIsAfter,
  email as emailValidator,
  ssn as ssnValidation,
  numOrNull,
  boolOrNull,
} from '#src/util/helpers.js';

import { VISA_OPTIONS } from '#src/data/visas.js';
import { STATES_AND_COUNTRIES, STATES, COUNTRIES_WITH_US } from '#src/data/states-and-countries.js';
import { GENDER_ITEMS } from '#src/data/genders.js';
import heights from '#src/data/heights.js';
import { noExamReasons } from '#src/data/no-exam-reasons.js';
import { BANKRUPTCY_TYPE_OPTIONS } from '#src/data/bankruptcy.js';
import { maritalStatuses } from '#src/data/maritalStatuses.js';
import { AVAILABLE_HEALTH_CLASSES, AVAILABLE_LTC_HEALTH_CLASSES } from '#src/data/healthTypes.js';

import { HttpIndividualsService } from '#src/services/http-individuals.service.js';

import { getPartyStore, useEappStore } from '#src/stores/electronic-application.js';
import { getGuardianKeyGenWithSideffect, useIndividualStore } from '#src/stores/individual.js';
import { smokingUsageNameGenerator, useSmokingUsage } from '#src/stores/insured-smoking-usage.js';

import { INSURED_RELATIONSHIP_OPTIONS } from '#src/data/relationships.js';
import {
  savablePropertyRequestWrapper,
  useSavableProperty,
} from '#src/composables/savable-property.composable.js';

import {
  validateAccountNumber,
  validateBoolean,
  validateIsBeforeNow,
  validatePhone,
  validateRoutingNumber,
  validateText,
} from '#src/composables/savable-property-validators.mjs';
import { doctorVisitKeygen, useDoctorVisit } from '#src/stores/doctor-visit.js';
import { useInsuredOccupationStore } from '#src/stores/insured-occupation.js';

export const INSURED_TYPES = { INSURED: 'insured', JOINT: 'joint-insured' };
export const OTHER_INSURED_TYPE = {
  [INSURED_TYPES.INSURED]: INSURED_TYPES.JOINT,
  [INSURED_TYPES.JOINT]: INSURED_TYPES.INSURED,
};

// QOL Helpers
export const isPrimaryInsured = (partyKey) => partyKey === INSURED_TYPES.INSURED;
export const isJointInsured = (partyKey) => partyKey === INSURED_TYPES.JOINT;
export const usePrimaryInsuredStore = (pinia) => useInsuredStore(INSURED_TYPES.INSURED, pinia);
export const useJointInsuredStore = (pinia) => useInsuredStore(INSURED_TYPES.JOINT, pinia);
export const getPayorRoleForInsured = (insuredId, pinia) => {
  const isPrimaryInsured = usePrimaryInsuredStore(pinia).id === insuredId;
  return isPrimaryInsured ? PAYOR : JOINT_PAYOR;
};

const REQ_GROUPS = {
  INSURED: 'INSURED',
  CONTACT_ACTIONS: 'CONTACT_ACTIONS',
  BENEFICIARY: 'BENEFICIARY',
  DRIVERS_LICENSE: 'DRIVERS_LICENSE',
  EFT_NUMBERS: 'EFT_NUMBERS',
  EXAM_ADDRESS: 'EXAM_ADDRESS',
  EXAM_SKIP: 'EXAM_SKIP',
  HOME_ADDRESS: 'HOME_ADDRESS',
  ROLE: 'ROLE',
  EFT: 'EFT',
};

export function useInsuredStore(partyKey, pinia, hot) {
  const individualService = new HttpIndividualsService(pinia);
  return defineStore(partyKey, {
    state: () => ({
      address_id: null,
      exam_address_id: null,
      id: null,

      intro_last_sent_at: null,
      exam_date: null,
      medical_sources: [],
      smoking_statuses: [],
      doctor_visits: [],

      account_last_4: null,
      routing_last_4: null,

      roles: {
        [JOINT_OWNER]: null,
        [OWNER]: null,
        [PRIMARY_BENEFICIARY]: null,
        [CONTINGENT_BENEFICIARY]: null,
        [SPOUSE]: null,
        [INSURED]: null,
        [JOINT_INSURED]: null,
        [PAYOR]: null,
        [JOINT_PAYOR]: null,
      },
      role_for_id: {
        [PAYOR]: null,
        [JOINT_PAYOR]: null,
      },

      // savable properties
      draft_day_of_month: useSavableProperty({
        requestMap: 'draft_day_of_month',
        group: REQ_GROUPS.EFT,
        rules: {
          inRange: {
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return store.draft_day_of_month.model >= 1 && store.draft_day_of_month.model <= 28;
            },
            message: 'Must be between 1 and 28',
          },
        },
      }),
      sync_now: useSavableProperty({
        group: REQ_GROUPS.INSURED,
        requestMap: 'sync_now',
        rules: {
          isTrueOrFalse: validateBoolean(() => useInsuredStore(partyKey, pinia).sync_now.model),
        },
      }),

      exam_skip_reason: useSavableProperty({
        group: REQ_GROUPS.EXAM_SKIP,
        requestMap: 'exam_skip_reason',
        rules: {
          inList: {
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return noExamReasons.some(({ value }) => value === store.exam_skip_reason.model);
            },
            message: 'Must be a valid reason',
          },
        },
      }),
      account_number: useSavableProperty({
        group: REQ_GROUPS.EFT_NUMBERS,
        requestMap: 'account_number',
        rules: {
          validLength: validateAccountNumber(
            () => useInsuredStore(partyKey, pinia).account_number.model,
          ),
        },
      }),
      routing_number: useSavableProperty({
        group: REQ_GROUPS.EFT_NUMBERS,
        requestMap: 'routing_number',
        rules: {
          validLength: validateRoutingNumber(
            () => useInsuredStore(partyKey, pinia).routing_number.model,
          ),
        },
      }),
      eft: useSavableProperty({
        group: REQ_GROUPS.INSURED,
        requestMap: 'eft',
        rules: {
          isTrueOrFalse: validateBoolean(() => useInsuredStore(partyKey, pinia).eft.model),
        },
      }),
      marital_status: useSavableProperty({
        group: REQ_GROUPS.INSURED,
        requestMap: 'marital_status',
        rules: {
          isInList: {
            message: `Must one of the following: ${maritalStatuses.map((v) => v.value).join(', ')}`,
            v: () => {
              const insured = useInsuredStore(partyKey, pinia);
              return maritalStatuses.some(({ value }) => value === insured.marital_status.model);
            },
          },
        },
      }),
      smoker: useSavableProperty({
        group: REQ_GROUPS.INSURED,
        requestMap: 'smoker',
        rules: {
          isInList: {
            v: () => {
              const insured = useInsuredStore(partyKey, pinia);
              return SMOKING_STATUSES.some(({ value }) => value === insured.smoker.model);
            },
            message: () => {
              const values = SMOKING_STATUSES.map((v) => v.value);
              return `Must be in list ${values.join(', ')}`;
            },
          },
        },
      }),
      health: useSavableProperty({
        group: REQ_GROUPS.INSURED,
        requestMap: 'health',
        rules: {
          isInList: {
            v: () => {
              const insured = useInsuredStore(partyKey, pinia);
              return (
                AVAILABLE_HEALTH_CLASSES.some(({ value }) => value === insured.health.model) ||
                AVAILABLE_LTC_HEALTH_CLASSES.some(({ value }) => value === insured.health.model)
              );
            },
            message: () => {
              const values = [
                ...AVAILABLE_HEALTH_CLASSES.map((v) => v.value),
                ...AVAILABLE_LTC_HEALTH_CLASSES.map((v) => v.value),
              ];
              const uniqueValues = [...new Set(values)];
              return `Must be in list ${uniqueValues.sort().join(', ')}`;
            },
          },
        },
      }),
      relationship: useSavableProperty({
        requestMap: 'relationship',
        group: REQ_GROUPS.ROLE,
        rules: {
          inList: {
            message: 'Must be in list',
            v: () => {
              const store = useInsuredStore(partyKey, pinia);

              return INSURED_RELATIONSHIP_OPTIONS.some(
                ({ value }) => value === store.relationship.model,
              );
            },
          },
        },
      }),
      send_client_intro_message: useSavableProperty({
        requestMap: 'send_client_intro_message',
        group: REQ_GROUPS.CONTACT_ACTIONS,
        rules: {
          isTrueOrFalse: validateBoolean(
            () => useInsuredStore(partyKey, pinia).send_client_intro_message.model,
          ),
        },
      }),
      email: useSavableProperty({
        requestMap: 'email',
        group: REQ_GROUPS.INSURED,
        rules: {
          cannotMatchOtherInsured: {
            message: 'Each insured must have a unique email',
            v: () => {
              const otherStore = useInsuredStore(OTHER_INSURED_TYPE[partyKey], pinia);
              const currentStore = useInsuredStore(partyKey, pinia);
              return !otherStore.email.model || otherStore.email.model !== currentStore.email.model;
            },
          },
          isValid: {
            message: 'Must be a valid email',
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return store.email.requestMeta.externallyValid === true;
            },
          },
          regexMatch: {
            message: 'Must be a valid email, fails regular expression test',
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return emailValidator(store.email.model);
            },
          },
        },
      }),
      height: useSavableProperty({
        requestMap: 'height',
        group: REQ_GROUPS.INSURED,
        rules: {
          isInList: {
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return heights.some(({ value }) => value === store.height.model);
            },
            message: 'Must be in list',
          },
        },
      }),
      weight: useSavableProperty({
        requestMap: 'weight',
        group: REQ_GROUPS.INSURED,
        rules: {
          greaterThanZero: {
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return store.weight.model > 0;
            },
            message: 'Required',
          },
        },
      }),
      first_name: useSavableProperty({
        requestMap: 'first_name',
        group: REQ_GROUPS.INSURED,
        rules: {
          validLength: validateText(() => useInsuredStore(partyKey, pinia).first_name.model, {
            minLength: 1,
            maxLength: 50,
          }),
        },
      }),
      last_name: useSavableProperty({
        requestMap: 'last_name',
        group: REQ_GROUPS.INSURED,
        rules: {
          validLength: validateText(() => useInsuredStore(partyKey, pinia).last_name.model, {
            minLength: 1,
            maxLength: 50,
          }),
        },
      }),
      beneficiary_amount: useSavableProperty({
        requestMap: 'beneficiary_amount',
        group: REQ_GROUPS.BENEFICIARY,
        requestFormatter: (v) => +integerToPercent(v),
        rules: {
          inRange: {
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return store.beneficiary_amount.model >= 1 && store.beneficiary_amount.model <= 100;
            },
            message: 'Must be between 1 and 100',
          },
        },
      }),
      gender: useSavableProperty({
        requestMap: 'gender',
        group: REQ_GROUPS.INSURED,
        rules: {
          validLength: {
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return GENDER_ITEMS.some(({ value }) => value === store.gender.model);
            },
            message: 'Must be ' + GENDER_ITEMS.map(({ title }) => title).join(' or '),
          },
        },
      }),
      phone_mobile: useSavableProperty({
        requestMap: 'phone_mobile',
        group: REQ_GROUPS.INSURED,
        rules: {
          validNumber: validatePhone(() => useInsuredStore(partyKey, pinia).phone_mobile.model),
        },
      }),
      birthdate: useSavableProperty({
        requestMap: 'birthdate',
        group: REQ_GROUPS.INSURED,
        rules: {
          dateIsBeforeNow: validateIsBeforeNow(
            () => useInsuredStore(partyKey, pinia).birthdate.model,
          ),
          isAtMost90: {
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              const ninetyYearsAgo = new Date();
              ninetyYearsAgo.setFullYear(ninetyYearsAgo.getFullYear() - 90);
              return dateIsAfter(format(ninetyYearsAgo, 'yyyy-MM-dd'), store.birthdate.model);
            },
            message: 'May not be older than 90',
          },
        },
      }),
      street_address: useSavableProperty({
        requestMap: 'street_address',
        group: REQ_GROUPS.HOME_ADDRESS,
        rules: {
          validLength: validateText(() => useInsuredStore(partyKey, pinia).street_address.model, {
            minLength: 2,
            maxLength: 255,
          }),
        },
      }),
      city: useSavableProperty({
        requestMap: 'city',
        group: REQ_GROUPS.HOME_ADDRESS,
        rules: {
          validLength: validateText(() => useInsuredStore(partyKey, pinia).city.model, {
            minLength: 2,
            maxLength: 255,
          }),
        },
      }),
      state: useSavableProperty({
        requestMap: 'state',
        group: REQ_GROUPS.HOME_ADDRESS,
        rules: {
          inList: {
            message: 'Must be a valid state',
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return STATES.some(({ value }) => value === store.state.model);
            },
          },
        },
      }),
      zip: useSavableProperty({
        requestMap: 'zip',
        group: REQ_GROUPS.HOME_ADDRESS,
        rules: {
          validLength: validateText(() => useInsuredStore(partyKey, pinia).zip.model, {
            exactLength: 5,
          }),
        },
      }),
      country: useSavableProperty({
        requestMap: 'country',
        group: REQ_GROUPS.HOME_ADDRESS,
        rules: {
          minLength: {
            message: 'Must be a valid country',
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return COUNTRIES_WITH_US.some(({ value }) => value === store.country.model);
            },
          },
        },
      }),
      years_at_address: useSavableProperty({
        requestMap: 'start_date',
        group: REQ_GROUPS.HOME_ADDRESS,
        requestFormatter: (v) => yearsToDate(v),
        rules: {
          isDefined: {
            message: () => {
              const store = useInsuredStore(partyKey, pinia);
              return `Must be less than or equal to ${store.first_name.model}'s age (${store.age})`;
            },
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return ![null, undefined].includes(store.years_at_address.model);
            },
          },
          atMostAge: {
            message: () => {
              const store = useInsuredStore(partyKey, pinia);
              return `Must be less than or equal to ${store.first_name.model}'s age (${store.age})`;
            },
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return store.years_at_address.model <= store.age;
            },
          },
        },
      }),
      exam_street_address: useSavableProperty({
        requestMap: 'street_address',
        group: REQ_GROUPS.EXAM_ADDRESS,
        rules: {
          validLength: validateText(
            () => useInsuredStore(partyKey, pinia).exam_street_address.model,
            { minLength: 2, maxLength: 255 },
          ),
        },
      }),
      exam_city: useSavableProperty({
        requestMap: 'city',
        group: REQ_GROUPS.EXAM_ADDRESS,
        rules: {
          validLength: validateText(() => useInsuredStore(partyKey, pinia).exam_city.model, {
            minLength: 2,
            maxLength: 255,
          }),
        },
      }),
      exam_state: useSavableProperty({
        requestMap: 'state',
        group: REQ_GROUPS.EXAM_ADDRESS,
        rules: {
          inList: {
            message: 'Must be a valid state',
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return STATES.some(({ value }) => value === store.exam_state.model);
            },
          },
        },
      }),
      exam_zip: useSavableProperty({
        requestMap: 'zip',
        group: REQ_GROUPS.EXAM_ADDRESS,
        rules: {
          validLength: validateText(() => useInsuredStore(partyKey, pinia).zip.model, {
            exactLength: 5,
          }),
        },
      }),
      exam_country: useSavableProperty({
        requestMap: 'country',
        group: REQ_GROUPS.EXAM_ADDRESS,
        rules: {
          minLength: {
            message: 'Must be a valid country',
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return COUNTRIES_WITH_US.some(({ value }) => value === store.exam_country.model);
            },
          },
        },
      }),
      signature_city: useSavableProperty({
        requestMap: 'signature_city',
        group: REQ_GROUPS.INSURED,
        rules: {
          validLength: validateText(() => useInsuredStore(partyKey, pinia).signature_city.model, {
            minLength: 2,
            maxLength: 255,
          }),
        },
      }),
      ssn: useSavableProperty({
        requestMap: 'ssn',
        group: REQ_GROUPS.INSURED,
        rules: {
          validSsn: {
            message: 'Must be a valid SSN',
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return ssnValidation(store.ssn.model);
            },
          },
        },
      }),
      assets: useSavableProperty({
        requestMap: 'assets',
        group: REQ_GROUPS.INSURED,
        rules: {
          greaterThanOrEqualToZero: {
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return store.assets.model >= 0;
            },
            message: 'Must be at least zero',
          },
          isInteger: {
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return Number.isInteger(store.assets.model);
            },
            message: 'Must be an integer',
          },
        },
      }),
      bankruptcy_discharge_date: useSavableProperty({
        requestMap: 'bankruptcy_discharge_date',
        group: REQ_GROUPS.INSURED,
        rules: {
          dateIsBeforeNow: validateIsBeforeNow(
            () => useInsuredStore(partyKey, pinia).bankruptcy_discharge_date.model,
          ),
          dateIsAfterBirthdate: {
            message: () => {
              const store = useInsuredStore(partyKey, pinia);
              if (!store.birthdate.model) return 'Missing insureds birthdate';
              return `Must be after ${store.first_name.asString()}'s birthdate ${timestampFormatter(
                store.birthdate.model,
                'sole-day',
                'basic',
              )}`;
            },
            v: () => {
              const store = useInsuredStore(partyKey, pinia);

              return dateIsAfter(store.birthdate.model, store.bankruptcy_discharge_date.model);
            },
          },
        },
      }),
      bankruptcy_type: useSavableProperty({
        requestMap: 'bankruptcy_type',
        group: REQ_GROUPS.INSURED,
        rules: {
          inList: {
            message: 'Must be in list',
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return BANKRUPTCY_TYPE_OPTIONS.includes(store.bankruptcy_type.model);
            },
          },
        },
      }),
      bankruptcy: useSavableProperty({
        requestMap: 'bankruptcy',
        group: REQ_GROUPS.INSURED,
        rules: {
          isTrueOrFalse: validateBoolean(() => useInsuredStore(partyKey, pinia).bankruptcy.model),
        },
      }),
      birth_location: useSavableProperty({
        requestMap: 'birth_location',
        group: REQ_GROUPS.INSURED,
        rules: {
          isInList: {
            message: 'Must be in list',
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return STATES_AND_COUNTRIES.some(({ value }) => value === store.birth_location.model);
            },
          },
        },
      }),
      country_of_citizenship: useSavableProperty({
        requestMap: 'country_of_citizenship',
        group: REQ_GROUPS.INSURED,
        rules: {
          isInList: {
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return COUNTRIES_WITH_US.some(
                ({ value }) => store.country_of_citizenship.model === value,
              );
            },
            message: 'Must be a valid country',
          },
        },
      }),
      drivers_license_has: useSavableProperty({
        rules: {
          isTrueOrFalse: validateBoolean(
            () => useInsuredStore(partyKey, pinia).drivers_license_has.model,
          ),
        },
      }),
      drivers_license_state: useSavableProperty({
        requestMap: 'state',
        group: REQ_GROUPS.DRIVERS_LICENSE,
        validationSideEffects: () => {
          const store = useInsuredStore(partyKey, pinia);
          if (!store.drivers_license_number?.validation) return;
          store.drivers_license_number.validation.validate();
        },
        rules: {
          isInList: {
            message: 'Must be a valid US state',
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return STATES.some(({ value }) => value === store.drivers_license_state.model);
            },
          },
          matchesLicenseState: {
            message: 'Must be a valid drivers license number + state match',
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              const dlState = store.drivers_license_state.model;
              const dlNumber = store.drivers_license_number.model;
              if (!dlState || !dlNumber) return false;
              return dlValidation(dlState, dlNumber);
            },
          },
        },
      }),
      drivers_license_number: useSavableProperty({
        requestMap: 'number',
        group: REQ_GROUPS.DRIVERS_LICENSE,
        validationSideEffects: () => {
          const store = useInsuredStore(partyKey, pinia);
          if (!store.drivers_license_state?.validation) return;
          store.drivers_license_state.validation.validate();
        },
        rules: {
          matchesLicenseState: {
            message: 'Must be a valid drivers license number + state match',
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              const dlState = store.drivers_license_state.model;
              const dlNumber = store.drivers_license_number.model;
              if (!dlState || !dlNumber) return false;
              return dlValidation(dlState, dlNumber);
            },
          },
        },
      }),
      drivers_licenseless_reason: useSavableProperty({
        requestMap: 'no_drivers_license_reason',
        group: REQ_GROUPS.DRIVERS_LICENSE,
        rules: {
          validLength: validateText(
            () => useInsuredStore(partyKey, pinia).drivers_licenseless_reason.model,
            { minLength: 1, maxLength: 255 },
          ),
        },
      }),
      liabilities: useSavableProperty({
        requestMap: 'liabilities',
        group: REQ_GROUPS.INSURED,
        rules: {
          greaterThanOrEqualToZero: {
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return store.liabilities.model >= 0;
            },
            message: 'Must be at least zero',
          },
          isInteger: {
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return Number.isInteger(store.liabilities.model);
            },
            message: 'Must be an integer',
          },
        },
      }),
      income: useSavableProperty({
        requestMap: 'income',
        group: REQ_GROUPS.INSURED,
        rules: {
          greaterThanOrEqualToZero: {
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return store.income.model >= 0;
            },
            message: 'Must be at least zero',
          },
          isInteger: {
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return Number.isInteger(store.income.model);
            },
            message: 'Must be an integer',
          },
        },
      }),
      us_citizen: useSavableProperty({
        requestMap: 'us_citizen',
        group: REQ_GROUPS.INSURED,
        rules: {
          isTrueOrFalse: validateBoolean(() => useInsuredStore(partyKey, pinia).us_citizen.model),
        },
      }),
      us_entry_date: useSavableProperty({
        requestMap: 'us_entry_date',
        group: REQ_GROUPS.INSURED,
        rules: {
          dateIsBeforeNow: validateIsBeforeNow(
            () => useInsuredStore(partyKey, pinia).us_entry_date.model,
          ),
          dateIsAfterBirthdate: {
            message: () => {
              const store = useInsuredStore(partyKey, pinia);
              if (!store.birthdate.model) return "Missing insured's birthdate";

              return `Must be after ${store.first_name.asString()}'s birthdate ${timestampFormatter(
                store.birthdate.model,
                'sole-day',
                'basic',
              )}`;
            },
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return dateIsAfter(store.birthdate.model, store.us_entry_date.model);
            },
          },
        },
      }),
      visa_type: useSavableProperty({
        requestMap: 'visa_type',
        group: REQ_GROUPS.INSURED,
        rules: {
          inList: {
            message: 'Must be a valid visa type',
            v: () => {
              const store = useInsuredStore(partyKey, pinia);
              return VISA_OPTIONS.includes(store.visa_type.model);
            },
          },
        },
      }),
    }),
    getters: {
      beneficiaryAmount: (s) => s.beneficiary_amount.model,
      firstName: (s) => s.first_name.asString(),
      lastName: (s) => s.last_name.asString(),
      name: (s) => [s.first_name.asString(), s.last_name.asString()].filter(Boolean).join(' '),
      displayName: (s) => s.name,
      partyKey: () => partyKey,
      insuredType: () => partyKey,
      displayType() {
        if (isPrimaryInsured(partyKey)) return 'Insured';
        if (this.roles[SPOUSE]) return 'Spouse';
        return 'Joint Insured';
      },
      age() {
        return dateToYears(this.birthdate.model) || 0;
      },
      smokerForRequests() {
        let usages = undefined;
        const smoker = this.smoker.model;
        if (smoker === SMOKING_NEVER) return { smoker, usages };
        usages = this.smoking_statuses.map((id) => {
          const usage = useSmokingUsage(id, pinia);

          return {
            category: usage.category.model,
            frequency: usage.frequency.model,
            last_use_date: usage.last_use_date.model,
            status: smoker,
          };
        });
        return { smoker, usages };
      },
      ageInMonths() {
        if (!this.birthdate.model) return null;
        return differenceInMonths(
          new Date(),
          parse(this.birthdate.model, 'yyyy-MM-dd', new Date()),
        );
      },
      netWorth() {
        const assetV = this.assets.model;
        const liabilitiesV = this.liabilities.model;
        const undefinedAssets = [null, undefined].includes(assetV);
        const undefinedLiabilities = [null, undefined].includes(liabilitiesV);
        if (undefinedAssets || undefinedLiabilities) return null;
        if (assetV >= liabilitiesV) return null;
        return assetV - liabilitiesV;
      },
      payorKey() {
        const eApp = useEappStore(pinia);

        return [INSURED_TYPES.INSURED, INSURED_TYPES.JOINT, ...eApp.parties].find((p) => {
          const store = getPartyStore(p, pinia);

          let role = PAYOR;
          if (isJointInsured(partyKey)) role = JOINT_PAYOR;

          if (!store.roles[role]) return false;
          return store.role_for_id[role] === this.id;
        });
      },
    },
    actions: {
      getCreateData(quote_id) {
        const data = {};
        if (quote_id) {
          data.quote_id = quote_id;
        } else {
          [
            'first_name',
            'last_name',
            'gender',
            'birthdate',
            'height',
            'weight',
            'marital_status',
          ].forEach((a) => {
            if (this[a].model) {
              data[this[a].requestMeta.requestMap] = this[a].model;
            }
          });

          if (this.smokerForRequests.smoker) {
            data.smoker = this.smokerForRequests.smoker;
          }
          if (this.smokerForRequests.usages && this.smokerForRequests.usages.length) {
            data.smoking_statuses = this.smokerForRequests.usages;
          }
        }

        ['email', 'phone_mobile'].forEach((a) => {
          if (this[a].model) {
            data[this[a].requestMeta.requestMap] = this[a].model;
          }
        });
        return data;
      },
      setApplicationRoles(rawRoles) {
        const { roles, beneficiary_amount, relationship, role_for_id } = generateRoleData(rawRoles);

        Object.keys(this.roles).forEach((r) => (this.roles[r] = roles[r]));
        Object.keys(this.role_for_id).forEach((r) => (this.role_for_id[r] = role_for_id[r]));

        if (beneficiary_amount) this.beneficiary_amount.load(percentToInteger(beneficiary_amount));
        this.relationship.load(relationship);
      },
      setFromEApp(insured = {}) {
        this.id = insured.id || null;

        this.intro_last_sent_at = insured.intro_last_sent_at || null;
        this.exam_date = insured.exam_date || null;

        this.exam_skip_reason.load(insured.exam_skip_reason || null);
        this.eft.load(boolOrNull(insured.eft));
        this.draft_day_of_month.load(insured.draft_day_of_month || null);
        this.marital_status.load(insured.marital_status || null);
        this.smoker.load(insured.smoker || null);
        this.health.load(insured.health || null);
        this.signature_city.load(insured.signature_city || null);
        this.height.load(insured.height || null);
        this.weight.load(insured.weight || null);
        this.first_name.load(insured.first_name || null);
        this.last_name.load(insured.last_name || null);
        this.birthdate.load(insured.birthdate || null);
        this.gender.load(insured.gender || null);
        this.sync_now.load(boolOrNull(insured.sync_now));

        if (insured.email) this.email.load(insured.email);
        this.phone_mobile.load(insured.phone_mobile || null);
        this.send_client_intro_message.load(boolOrNull(insured.send_client_intro_message));
        this.assets.load(numOrNull(insured.assets));
        this.income.load(numOrNull(insured.income));
        this.liabilities.load(numOrNull(insured.liabilities));
        this.ssn.load(insured.ssn || null);
        this.us_citizen.load(boolOrNull(insured.us_citizen));
        this.us_entry_date.load(insured.us_entry_date || null);
        this.visa_type.load(insured.visa_type || null);
        this.bankruptcy_discharge_date.load(insured.bankruptcy_discharge_date || null);
        this.bankruptcy_type.load(insured.bankruptcy_type || null);
        this.bankruptcy.load(boolOrNull(insured.bankruptcy));
        this.birth_location.load(insured.birth_location || null);
        this.country_of_citizenship.load(insured.country_of_citizenship || null);

        if (insured.drivers_license) {
          this.drivers_license_state.load(insured.drivers_license.state || null);
          this.drivers_license_number.load(insured.drivers_license.number || null);
          this.drivers_licenseless_reason.load(
            insured.drivers_license.no_drivers_license_reason || null,
          );
          if (this.drivers_license_number.model || this.drivers_license_state.model) {
            this.drivers_license_has.model = true;
          } else if (this.drivers_licenseless_reason.model) {
            this.drivers_license_has.model = false;
          }
        }

        if (insured.address) {
          this.address_id = insured.address.id;
          this.city.load(insured.address.city);
          this.country.load(insured.address.country);
          this.state.load(insured.address.state);
          this.street_address.load(insured.address.street_address);
          this.years_at_address.load(dateToYears(insured.address.start_date));
          this.zip.load(insured.address.zip);
        }

        if (insured.addresses?.length) {
          const exam_address = insured.addresses.find(
            ({ address_type }) => address_type === 'exam',
          );
          if (exam_address) {
            this.exam_address_id = exam_address.id;
            this.exam_city.load(exam_address.city);
            this.exam_country.load(exam_address.country);
            this.exam_state.load(exam_address.state);
            this.exam_street_address.load(exam_address.street_address);
            this.exam_zip.load(exam_address.zip);
          }
        }

        this.smoking_statuses.splice(0, this.smoking_statuses.length);
        if (insured.smoking_statuses?.length) {
          insured.smoking_statuses.forEach(
            ({ id: usageId, category, frequency, last_use_date }) => {
              const id = smokingUsageNameGenerator();
              const usage = useSmokingUsage(id, pinia);
              usage.id = usageId;
              usage.category.load(category);
              usage.frequency.load(frequency);
              usage.last_use_date.load(last_use_date);
              this.smoking_statuses.push(id);
            },
          );
        }

        if (insured.efts?.length) {
          const { account_number_last_4, routing_last_4 } = insured.efts[0];
          this.account_last_4 = account_number_last_4;
          this.routing_last_4 = routing_last_4;
        }

        if (insured.application_roles) {
          this.setApplicationRoles(insured.application_roles);
        }

        if (insured.medical_sources?.length) {
          this.medical_sources.push(...insured.medical_sources);
        }

        if (insured?.occupation) {
          const insuredOccupation = useInsuredOccupationStore(partyKey, pinia);
          insuredOccupation.setFromEApp(insured.occupation);
        }

        if (insured.doctor_visits?.length) {
          insured.doctor_visits.forEach((v) => {
            const doctorKey = doctorVisitKeygen(v.id);
            const visit = useDoctorVisit(doctorKey, pinia);
            visit.setFromEapp(partyKey, v);
            this.doctor_visits.push(doctorKey);
          });
        }

        if (insured.guardian) {
          const key = getGuardianKeyGenWithSideffect(this.id, pinia);
          const guardian = useIndividualStore(key, pinia);
          guardian.initializeFromEapp(insured.guardian);
        }

        if (!this.weight.model && this.gender.model) {
          this.height.load(
            {
              Male: 69,
              Female: 64,
            }[this.gender],
          );
        }
      },
      // This is only for joint insureds, should it be somewhere else?
      async create(isInsured = false) {
        const role = isInsured ? JOINT_INSURED : SPOUSE;
        const eApp = useEappStore(pinia);
        if (!eApp.id || this.id) return;

        let func = (body) => individualService.createIndividual(body);
        if (this.id) func = (body) => individualService.updateIndividual(this.id, body);

        let application_roles_attributes = undefined;
        if (!this.roles[role]) application_roles_attributes = [{ role }];

        let reqBody = {
          first_name: this.first_name.model,
          last_name: this.last_name.model,
          marital_status: this.marital_status.model,
          application_roles_attributes,
          birthdate: undefined,
          gender: undefined,
        };
        if (isInsured) {
          reqBody.birthdate = this.birthdate.model;
          reqBody.gender = this.gender.model;
        }

        const { id, application_roles } = await func(reqBody);
        this.id = id;
        this.setApplicationRoles(application_roles);
        const shouldSaveSmokingStatuses = Boolean(this.roles[INSURED] || this.roles[JOINT_INSURED]);
        if (shouldSaveSmokingStatuses) this.saveAllSmokerData();
      },
      async saveAttributes(attributes = []) {
        if (!this.id) return;

        const reqMap = {};
        const reqAttrs = {};

        attributes.forEach((attr) => {
          if (this[attr]?.requestMeta?.group) {
            const key = this[attr].requestMeta.requestMap;
            const group = this[attr].requestMeta.group;
            const value = this[attr].requestMeta.format();

            if (!reqMap[group]) {
              reqMap[group] = {};
              reqAttrs[group] = [];
            }
            reqMap[group][key] = value;
            reqAttrs[group].push(attr);
            return;
          }
        });

        const promises = [];

        if (reqMap[REQ_GROUPS.INSURED]) {
          promises.push(
            this.saveInsuredAttributes(reqMap[REQ_GROUPS.INSURED], reqAttrs[REQ_GROUPS.INSURED]),
          );
        }
        if (reqMap[REQ_GROUPS.DRIVERS_LICENSE]) {
          promises.push(
            this.saveDriversLicenseAttributes(
              reqMap[REQ_GROUPS.DRIVERS_LICENSE],
              reqAttrs[REQ_GROUPS.DRIVERS_LICENSE],
            ),
          );
        }
        if (reqMap[REQ_GROUPS.HOME_ADDRESS]) {
          promises.push(
            this.saveInsuredHomeAddress(
              reqMap[REQ_GROUPS.HOME_ADDRESS],
              reqAttrs[REQ_GROUPS.HOME_ADDRESS],
            ),
          );
        }

        if (reqMap[REQ_GROUPS.ROLE] || reqMap[REQ_GROUPS.BENEFICIARY]) {
          promises.push(
            this.saveRole(
              reqMap[REQ_GROUPS.ROLE],
              reqAttrs[REQ_GROUPS.ROLE],
              reqMap[REQ_GROUPS.BENEFICIARY],
              reqAttrs[REQ_GROUPS.BENEFICIARY],
            ),
          );
        }

        if (reqMap[REQ_GROUPS.EXAM_SKIP]) {
          promises.push(this.skipExam());
        }

        if (reqMap[REQ_GROUPS.EFT]) {
          promises.push(this.saveEft(reqMap[REQ_GROUPS.EFT], reqAttrs[REQ_GROUPS.EFT]));
        }

        await Promise.all(promises);

        const secondaryPromises = [];
        if (reqMap[REQ_GROUPS.CONTACT_ACTIONS]) {
          secondaryPromises.push(this.sendIntroLink());
        }

        await Promise.all(secondaryPromises);
      },
      async savablePropertyWrapper(func, { attributes, body, uuid }) {
        const composableAttributes = attributes.map((a) => this[a]);
        return savablePropertyRequestWrapper(func, { composableAttributes, body, uuid });
      },
      saveRole(roleReqObj, roleAttrs, beneReqObj, beneAttrs) {
        const attributes = [];
        if (beneAttrs) attributes.push(...beneAttrs);
        if (roleAttrs) attributes.push(...roleAttrs);

        const body = { application_roles_attributes: [] };
        Object.keys(this.roles).forEach((r) => {
          if (!this.roles[r]) return;
          const isBeneRole = [PRIMARY_BENEFICIARY, CONTINGENT_BENEFICIARY].includes(r);
          if (!isBeneRole) return;
          let roleReq = {};
          if (roleReqObj) roleReq = { ...roleReq, ...roleReqObj };
          if (beneReqObj) roleReq = { ...roleReq, ...beneReqObj };

          body.application_roles_attributes.push({
            id: this.roles[r],
            ...roleReq,
          });
        });

        const func = () => individualService.updateIndividual(this.id, body);
        return this.savablePropertyWrapper(func, { attributes, body, uuid: generateUuid() });
      },
      saveInsuredAttributes(reqMap, attributes = []) {
        const func = () => individualService.updateIndividual(this.id, reqMap);
        return this.savablePropertyWrapper(func, {
          body: reqMap,
          attributes,
          uuid: generateUuid(),
        });
      },
      saveDriversLicenseAttributes(reqMap, attributes = []) {
        const func = () => individualService.updateIndividualDriversLicense(this.id, reqMap);
        return this.savablePropertyWrapper(func, {
          body: reqMap,
          attributes,
          uuid: generateUuid(),
        });
      },
      async saveInsuredHomeAddress(reqMap, attributes) {
        const func = () => individualService.updateIndividualHomeAddress(this.id, reqMap);
        const res = await this.savablePropertyWrapper(func, {
          body: reqMap,
          attributes,
          uuid: generateUuid(),
        });
        this.address_id = res.id;
      },
      async saveEft(reqMap, attributes) {
        const func = () => individualService.updateEft(this.id, reqMap);
        return this.savablePropertyWrapper(func, {
          body: reqMap,
          attributes,
          uuid: generateUuid(),
        });
      },
      async saveEftAccountNumbers() {
        const body = {
          routing_number: this.routing_number.model,
          account_number: this.account_number.model,
        };
        const func = () => individualService.updateEft(this.id, body);
        const attributes = ['account_number', 'routing_number'];

        const res = await this.savablePropertyWrapper(func, {
          attributes,
          body,
          uuid: generateUuid(),
        });

        const { account_last_4, routing_last_4 } = res;
        this.account_last_4 = account_last_4;
        this.routing_last_4 = routing_last_4;
      },
      createSmokingUsage() {
        const id = smokingUsageNameGenerator();
        this.smoking_statuses.push(id);
        return id;
      },
      deleteSmokingUsage(id) {
        const index = this.smoking_statuses.findIndex((v) => v === id);
        this.smoking_statuses.splice(index, 1);
        const usage = useSmokingUsage(id, pinia);
        usage.$dispose();
      },
      async saveAllSmokerData() {
        await this.saveAttributes(['smoker']);
        await this.saveSmokingStatuses();
      },
      saveSmokingStatuses() {
        if (this.smoker.model === SMOKING_NEVER) {
          return individualService.deleteSmokingStatuses(this.id);
        }
        return individualService.saveSmokingUsages(this.id, this.smokerForRequests.usages);
      },
      async sendIntroLink() {
        await individualService.sendIntroMessage(this.id, {
          send_client_intro_message: this.send_client_intro_message.model,
          first_name: this.first_name.model,
          last_name: this.last_name.model,
          phone_mobile: this.phone_mobile.model,
          email: this.email.model,
        });

        if (this.send_client_intro_message.model) {
          this.intro_last_sent_at = new Date();
        } else {
          this.intro_last_sent_at = null;
        }
        return true;
      },
      async deletePayor() {
        if (!this.payorKey) return;
        const eApp = useEappStore(pinia);
        const role = getPayorRoleForInsured(this.id, pinia);
        return eApp.deletePartyOrRole({ partyKey: this.payorKey, role });
      },
      async deleteRole(role) {
        if (!this.roles[role]) return;

        await individualService.updateIndividual(this.id, {
          application_roles_attributes: [{ id: this.roles[role], _destroy: true }],
        });
        this.roles[role] = null;
        if (role in this.role_for_id) this.role_for_id[role] = null;
      },
      async addRole(body) {
        return await individualService.updateIndividual(this.id, body);
      },
      setCreatedRoleData(role, applicationRoles) {
        const createdRole = applicationRoles.find((r) => r.role === role);
        this.roles[role] = createdRole.id;

        if (role in this.role_for_id) this.role_for_id[role] = createdRole.insured_id;

        if ([PRIMARY_BENEFICIARY, CONTINGENT_BENEFICIARY].includes(role)) {
          this.beneficiary_amount.load(percentToInteger(createdRole.beneficiary_amount));
          this.relationship.load(createdRole.relationship);
        }
      },
      async addOwnerRole() {
        const body = {
          application_roles_attributes: [{ role: OWNER }],
        };
        const res = await this.addRole(body);
        this.setCreatedRoleData(OWNER, res.application_roles);
        return res;
      },
      async addBeneficiaryRole({ beneficiary_amount, role, insured_id }) {
        let relationship = this.marital_status.model;
        const validRelationship = INSURED_RELATIONSHIP_OPTIONS.some(
          ({ value }) => value === relationship,
        );

        const getRelationship = (gender, invert = false) => {
          if (gender.toLowerCase() === 'male') return invert ? 'Wife' : 'Husband';
          return invert ? 'Husband' : 'Wife';
        };

        // if the marital_status is not domestic partner, then it's Married.
        // Pick Husband or Wife based off the gender
        if (!validRelationship && isPrimaryInsured(partyKey)) {
          relationship = getRelationship(this.gender.model);
        } else if (!validRelationship && isJointInsured(partyKey)) {
          const insuredStore = usePrimaryInsuredStore();
          if (this.gender.model) relationship = getRelationship(this.gender.model);
          else relationship = getRelationship(insuredStore.gender.model, true);
        }

        const body = {
          application_roles_attributes: [
            {
              role,
              beneficiary_amount: integerToPercent(beneficiary_amount),
              relationship,
              insured_id,
            },
          ],
        };

        const res = await this.addRole(body);
        this.setCreatedRoleData(role, res.application_roles);

        if (!this.address_id || !this.street_address.model) return true;
      },
      async addPayorRole({ insured_id }) {
        let insuredId = insured_id;
        if (!Array.isArray(insuredId)) insuredId = [insuredId];

        const roles = [];
        const application_roles_attributes = [];
        insuredId.forEach((i) => {
          const role = getPayorRoleForInsured(i, pinia);
          roles.push(role);
          application_roles_attributes.push({ role, insured_id: i });
        });

        const body = { application_roles_attributes };
        const res = await this.addRole(body);
        roles.forEach((r) => {
          this.setCreatedRoleData(r, res.application_roles);
        });
        return res;
      },
      copyInsuredAddress() {
        if (isPrimaryInsured(partyKey)) return;

        const insuredStore = usePrimaryInsuredStore();
        // for spouse
        this.zip.load(insuredStore.zip.model);
        this.country.load(insuredStore.country.model);
        this.city.load(insuredStore.city.model);
        this.state.load(insuredStore.state.model);
        this.street_address.load(insuredStore.street_address.model);

        return this.saveAttributes(['zip', 'country', 'state', 'city', 'street_address']);
      },
      getAvailability() {
        return individualService.getAvailability(this.id, {
          street_address: this.exam_street_address.model,
          city: this.exam_city.model,
          state: this.exam_state.model,
          zip: this.exam_zip.model,
        });
      },
      async scheduleExam(date) {
        await individualService.scheduleExam(this.id, { date });
        this.exam_date = date;
        this.exam_skip_reason.model = null;
      },
      async skipExam() {
        this.exam_date = null;
        const body = { reason: this.exam_skip_reason.model };
        const func = () => individualService.skipExam(this.id, body);
        return this.savablePropertyWrapper(func, {
          attributes: ['exam_skip_reason'],
          body,
          uuid: generateUuid(),
        });
      },
    },
  })(pinia, hot);
}
