<template>
  <div>
    <v-text-field
      :id="$attrs['data-testid']"
      v-bind="$attrs"
      v-model="model"
      v-maska:[options]
      :aria-label="$attrs?.label"
      inputmode="numeric"
    >
      <template v-if="$slots.message" #message="{ message }">
        <v-row v-if="`${message}`.trim()" class="ma-0">
          {{ message }}
          <v-spacer />
          <slot name="message" />
        </v-row>
        <slot v-else name="message" />
      </template>
      <template v-if="$slots.append" #append>
        <slot name="append" />
      </template>
      <template v-if="$slots['append-inner']" #append-inner>
        <slot name="append-inner" />
      </template>
    </v-text-field>
  </div>
</template>

<script setup>
import { parse } from 'date-fns/parse';
import { isValid } from 'date-fns/isValid';
import { format } from 'date-fns/format';

import { watch, ref, computed } from 'vue';

import { vMaska } from 'maska';

const options = { mask: '##/##/####' };

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue']);
const update = (v) => emit('update:modelValue', v);

let v = null;
if (props.modelValue) {
  const date = parse(props.modelValue, 'yyyy-MM-dd', new Date());
  if (isValid(date)) v = format(date, 'MM/dd/yyyy');
}

const model = ref(v);

const emittableValue = computed(() => {
  if (`${model.value}`.length !== 10) return null;

  const date = parse(model.value, 'MM/dd/yyyy', new Date());
  if (!isValid(date)) return null;

  const formatted = format(date, 'yyyy-MM-dd');
  return formatted;
});

watch(
  () => props.modelValue,
  () => {
    if (`${props.modelValue}` === `${emittableValue.value}`) {
      return;
    } else if (!props.modelValue) {
      model.value = null;
      return;
    }
    const date = parse(props.modelValue, 'yyyy-MM-dd', new Date());
    model.value = format(date, 'MM/dd/yyyy');
  },
);

watch(model, () => {
  if (props.modelValue === emittableValue.value) return;
  update(emittableValue.value);
});
</script>
