<template>
  <step-layout show-logo>
    <template #title>
      {{ greeting }}
    </template>
    <template #body>
      <joint-insured-display-wrapper>
        <template #insured-title> Primary Applicant's name </template>
        <template #insured>
          <form-builder
            data-testid="insured-form"
            :style="{ maxWidth: smAndDown ? '100%' : '600px' }"
            :structure="structure"
          />
        </template>
        <template #joint-insured-title> Joint Applicant's name </template>
        <template #joint-insured>
          <form-builder
            data-testid="joint-insured-form"
            :style="{ maxWidth: smAndDown ? '100%' : '600px' }"
            :structure="jointStructure"
          />
        </template>
      </joint-insured-display-wrapper>
    </template>
  </step-layout>
</template>

<script setup>
import FormBuilder from '#src/components/form-builder/FormBuilder.vue';
import JointInsuredDisplayWrapper from '#src/components/shared/JointInsuredDisplayWrapper.vue';
import StepLayout from '#src/components/shared/StepLayout.vue';
import { useNameForm } from '#src/components/steps/name/name-form.composable';
import { focusOnFirstElement } from '#src/components/form-builder/FormBuilderTemplates.js';

import { storeToRefs } from 'pinia';
import { INSURED_TYPES, usePrimaryInsuredStore } from '#src/stores/insured';
import { useInstanceSettingsStore } from '#src/stores/instance-settings';
import { useEappStore } from '#src/stores/electronic-application';

import { scrollToInvalid } from '#src/composables/scroll-to.composable';
import { inject, nextTick, ref } from 'vue';
import { useDisplay } from 'vuetify/lib/framework.mjs';
import { unblockMainThread } from '#src/util/helpers';

const pinia = inject('pinia');
const primaryInsuredStore = usePrimaryInsuredStore(pinia);
const eApp = useEappStore(pinia);
const instance = useInstanceSettingsStore(pinia);

const { firstName: insuredFirstName } = storeToRefs(primaryInsuredStore);
const { activeFirstInput, greeting } = storeToRefs(instance);
const { isDualInsured } = storeToRefs(eApp);
const { smAndDown } = useDisplay();

const form = useNameForm(pinia, INSURED_TYPES.INSURED, {
  autofocus: !insuredFirstName.value && Boolean(activeFirstInput.value),
});
const jointForm = useNameForm(pinia, INSURED_TYPES.JOINT);

const structure = ref([]);
const jointStructure = ref([]);

unblockMainThread(() => (structure.value = form.buildStructure()));
if (isDualInsured.value) {
  unblockMainThread(() => (jointStructure.value = jointForm.buildStructure()));
}

function valid(goingPrevious = false) {
  if (goingPrevious) return true;

  let valid = form.validate();
  if (isDualInsured.value) {
    valid = jointForm.validate() && valid;
  }
  if (!valid) nextTick(() => scrollToInvalid(pinia));
  return valid;
}

function focus() {
  focusOnFirstElement(structure.value);
}
defineExpose({ valid, focus });
</script>
