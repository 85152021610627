<template>
  <div>
    <v-radio-group
      :id="props.dataTestid"
      key="radio"
      v-model="model"
      v-bind="$attrs"
      :color="color"
      :base-color="baseColor"
      :error-messages="errorMessages"
      :data-testid="props.dataTestid"
      :class="classes"
      @update:model-value="debounceAndValidate"
    >
      <template v-if="props.label" #label>
        {{ props.label }}
      </template>
      <v-radio v-for="(item, index) in items" :key="index" :label="item.title" :value="item.value">
        <template #label>
          {{ item.title }}
          <active-save-indicator
            v-if="!props.centered && model === item.value"
            :controller="savingBuffer"
            class="ml-1 text-left"
          />
        </template>
      </v-radio>
      <active-save-indicator
        v-if="props.centered"
        :controller="savingBuffer"
        class="ml-1 text-left"
      />
    </v-radio-group>
  </div>
</template>

<script setup>
import ActiveSaveIndicator from '#src/components/shared/ActiveSaveIndicator.vue';
import { useInput, useInputProps } from '#src/composables/savable-input.composable';
import { computed, inject } from 'vue';
const pinia = inject('pinia');
const props = defineProps({
  ...useInputProps(),
  items: { type: Array, required: true },
  label: { type: String, required: false, default: '' },
  centered: Boolean,
});

const { model, inputClasses, color, baseColor, errorMessages, savingBuffer, debounceAndValidate } =
  useInput(props, pinia, 300, {
    successClass: 'success-radio radio-opacity',
    errorClass: 'error-radio radio-opacity',
  });

const classes = computed(() => {
  const c = [...inputClasses.value, 'custom-radio-group'];
  if (props.centered) c.push('center-radio-group');
  return c;
});
</script>

<style lang="scss">
.center-radio-group .v-input__control {
  flex-direction: row;
  .v-selection-control-group {
    align-items: center;
    margin-top: 0 !important;
  }
  .v-label {
    margin-inline-start: 0 !important;
  }
}

.custom-radio-group .v-input__control {
  .v-label {
    font-size: 16px;
  }
}
</style>
