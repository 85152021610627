import { defineStore } from '#src/stores/state-wrapper.js';
import { EappService } from '#src/services/eapp.service.js';
import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';

import { useStepPaymentStore } from '#src/stores/step-payment.js';
import { useStepSignatureWaitingStore } from '#src/stores/step-signature-waiting.js';

export function useStepExamSchedulingStore(pinia, hot) {
  const eAppService = new EappService(pinia);
  return defineStore('step-exam-scheduling', {
    getters: {
      step: () => steps.EXAM_SCHEDULING,
      routeName: (s) => stepToRouteName[s.step],
      previous: () => {
        const payment = useStepPaymentStore(pinia);
        return payment.step;
      },
      next: () => {
        const signatureWaiting = useStepSignatureWaitingStore(pinia);
        return signatureWaiting.step;
      },
    },
    actions: {
      beforeLoadAction() {
        return eAppService.examRequiredBackendCheck();
      },
    },
  })(pinia, hot);
}
