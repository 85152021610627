import { createApp } from '#src/main';
import { createPinia } from 'pinia';

export function clientEntry(RootComponent) {
  const pinia = createPinia();
  const { app, router, resumeFn } = createApp(RootComponent, pinia);

  const init = () => {
    router.isReady().then(() => {
      app.mount('#app');
    });
  };

  if (resumeFn) {
    resumeFn().then(init);
  } else {
    init();
  }
}
