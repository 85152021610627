<template>
  <div>
    <p
      v-if="label"
      :id="`${props.dataTestid}-label`"
      :data-testid="`${props.dataTestid}-label`"
      class="text-left mb-3"
    >
      {{ label }}
    </p>
    <v-row
      :id="`${props.dataTestid}-group`"
      class="ma-0 pb-3"
      :justify="justify"
      v-bind="containerA11Y"
    >
      <v-btn
        v-for="(item, index) in props.items"
        :key="index"
        class="text-none ma-1 full-opacity"
        :style="{ fontSize: props.size === 'large' ? '18px' : undefined }"
        :size="props.size"
        rounded
        v-bind="buttons[index]"
      >
        <v-icon v-if="item.icon" :icon="item.icon" />
        <span :id="`${item.dataTestid}-option-label`">
          {{ item.title }}
        </span>
      </v-btn>
    </v-row>
    <v-slide-y-transition>
      <v-row v-if="errorMessages.length" cols="12" align="start" class="ma-0 btn-messages">
        <div class="v-messages__wrapper">
          <div class="v-messages__message px-5 v-field--error small-text">
            {{ errorMessages[0] }}
          </div>
        </div>
      </v-row>
    </v-slide-y-transition>
  </div>
</template>

<script setup>
import { useInput, useInputProps } from '#src/composables/savable-input.composable';
import { computed, inject, ref } from 'vue';
const pinia = inject('pinia');
const props = defineProps({
  ...useInputProps(),
  disabled: Boolean,
  ignoreChangelessClick: Boolean,
  skipValidateOnClick: Boolean,
  label: {
    type: String,
    required: false,
    default: '',
  },
  items: {
    type: Array,
    required: true,
  },
  justify: {
    type: String,
    default: 'start',
  },
  size: {
    type: String,
    default: 'default',
  },
  ariaLabelledBy: {
    type: String,
    required: false,
    default: null,
  },
});

const { model, errorMessages, savingBuffer, validateAndSave } = useInput(props, pinia, 0);

const isRadioGroup = computed(() => props.items.length > 1);

const containerA11Y = computed(() => {
  const parentData = {
    'aria-labelledby': props.ariaLabelledBy ? props.ariaLabelledBy : `${props.dataTestid}-label`,
  };

  if (isRadioGroup.value) {
    parentData.role = 'radiogroup';
  }

  return parentData;
});

const currentFocusIndex = ref(null);

const buttons = computed(() => {
  return props.items.map(({ dataTestid, title, value, color: customColor, prependIcon }, index) => {
    let color = customColor || 'primary';
    if (props.disabled) color = 'grey';
    else if (errorMessages.value.length) color = 'error';

    let disabled = false;
    if (props.disabled) disabled = true;
    else if (savingBuffer.value.saving && value !== model.value) disabled = true;

    let variant = 'outlined';
    if (model.value === value) variant = 'elevated';
    const loading = savingBuffer.value.saving && model.value === value && !props.disabled;

    const selectedIndex = props.items.findIndex((i) => i.value === model.value);
    let tabindex;
    if (selectedIndex === -1) tabindex = index === 0 ? 0 : -1;
    else tabindex = selectedIndex === index ? 0 : -1;
    const buttonA11Y = {
      tabindex,
    };
    if (isRadioGroup.value) {
      buttonA11Y.role = 'radio';
      buttonA11Y['aria-checked'] = model.value === value;
    }

    return {
      color,
      variant,
      loading,
      disabled,
      'data-testid': dataTestid,
      title,
      value,
      prependIcon,
      ...buttonA11Y,
      onFocus: () => handleFocus(index),
      onKeydown: (e) => handleKeydown(value, e),
      onClick: () => onClick(value),
    };
  });
});

function handleFocus(v) {
  currentFocusIndex.value = v;
}

function handleKeydown(v, e) {
  let focusOn = null;
  if ([' ', 'Enter'].includes(e.key)) return onClick(v);
  else if (['ArrowRight', 'ArrowDown'].includes(e.key)) {
    focusOn = Math.min(currentFocusIndex.value + 1, props.items.length - 1);
  } else if (['ArrowLeft', 'ArrowUp'].includes(e.key)) {
    focusOn = Math.max(currentFocusIndex.value - 1, 0);
  }

  if (focusOn !== null) {
    const el = document.querySelector(`[data-testid="${buttons.value[focusOn]['data-testid']}"`);
    if (el) el.focus();
  }
}

function onClick(v) {
  if (props.skipValidateOnClick) return props.onChange();
  if (model.value === v && props.ignoreChangelessClick) return;
  model.value = v;
  validateAndSave();
}
</script>
