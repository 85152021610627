export default [
  {
    value: 'Brother',
    title: 'Brother',
    gender: 'Male',
  },
  {
    value: 'Brother-in-law',
    title: 'Brother-in-law',
    gender: 'Male',
  },
  {
    value: 'Business Partner',
    title: 'Business Partner',
  },
  {
    value: 'Daughter',
    title: 'Daughter',
    gender: 'Female',
  },
  {
    value: 'Domestic Partner',
    title: 'Domestic Partner',
  },
  {
    value: 'Ex-Husband',
    title: 'Ex-Husband',
    gender: 'Male',
  },
  {
    value: 'Ex-Wife',
    title: 'Ex-Wife',
    gender: 'Female',
  },
  {
    value: 'Father',
    title: 'Father',
    gender: 'Male',
  },
  {
    value: 'Father-in-law',
    title: 'Father-in-law',
    gender: 'Male',
  },
  {
    value: 'Fiance',
    title: 'Fiance',
    gender: 'Opposite',
  },
  {
    value: 'Friend',
    title: 'Friend',
  },
  {
    value: 'Granddaughter',
    title: 'Granddaughter',
    gender: 'Female',
  },
  {
    value: 'Grandfather',
    title: 'Grandfather',
    gender: 'Male',
  },
  {
    value: 'Grandmother',
    title: 'Grandmother',
    gender: 'Female',
  },
  {
    value: 'Grandson',
    title: 'Grandson',
    gender: 'Male',
  },
  {
    value: 'Husband',
    title: 'Husband',
    gender: 'Male',
  },
  {
    value: 'Mother',
    title: 'Mother',
    gender: 'Female',
  },
  {
    value: 'Mother-in-law',
    title: 'Mother-in-law',
    gender: 'Female',
  },
  {
    value: 'Relative',
    title: 'Relative',
  },
  {
    value: 'Sister',
    title: 'Sister',
    gender: 'Female',
  },
  {
    value: 'Sister-in-law',
    title: 'Sister-in-law',
    gender: 'Female',
  },
  {
    value: 'Son',
    title: 'Son',
    gender: 'Male',
  },
  {
    value: 'Stepfather',
    title: 'Stepfather',
    gender: 'Male',
  },
  {
    value: 'Stepmother',
    title: 'Stepmother',
    gender: 'Female',
  },
  {
    value: 'Wife',
    title: 'Wife',
    gender: 'Female',
  },
];

export const GUARDIAN_RELATIONSHIPS = [
  {
    value: 'Parent',
    title: 'Parent',
  },
  {
    value: 'Grandparent',
    title: 'Grandparent',
  },
  {
    value: 'Permanent Legal Guardian',
    title: 'Permanent Legal Guardian',
  },
];

export function likelyLivesWith(relationship) {
  return ['Domestic Partner', 'Fiance', 'Husband', 'Wife'].includes(relationship);
}

export const ENTITY_OWNER_RELATIONSHIPS = ['Employer', 'Other'];
export const ENTITY_BENEFICIARY_RELATIONSHIPS_DICT = {
  CREDITOR: 'Creditor',
  EMPLOYER: 'Employer',
  OTHER: 'Other',
};
export const ENTITY_BENEFICIARY_RELATIONSHIPS = Object.values(
  ENTITY_BENEFICIARY_RELATIONSHIPS_DICT,
);

export const INSURED_RELATIONSHIP_OPTIONS = [
  {
    value: 'Domestic Partner',
    title: 'Domestic Partner',
  },
  {
    value: 'Husband',
    title: 'Husband',
    gender: 'Male',
  },
  {
    value: 'Wife',
    title: 'Wife',
    gender: 'Female',
  },
];
