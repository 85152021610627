import { httpClient } from '#src/HttpClient';
import { useInstanceSettingsStore } from '#src/stores/instance-settings';

export class AgentsService {
  #pinia;
  #httpClient;
  #baseUrl;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/agents`;
    this.#httpClient = httpClient(pinia);
  }

  async getAllAgents(npn) {
    const params = {};
    if (npn) params.npn = npn;
    const { data } = await this.#httpClient.get(this.#baseUrl, {
      params,
    });

    return data.agents
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .map(({ id, name, url }) => ({ id, name, url }));
  }
}
