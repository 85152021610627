import { REQS } from '#src/enumerations/data-requirements.js';
import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { QuestionsService } from '#src/services/questions.service.js';
import { defineStore } from '#src/stores/state-wrapper.js';

import {
  INSURED_TYPES,
  useInsuredStore,
  usePrimaryInsuredStore,
  useJointInsuredStore,
} from '#src/stores/insured.js';
import { useStepSecondaryAddresseeStore } from '#src/stores/step-secondary-addressee.js';
import { useStepPaymentStore } from '#src/stores/step-payment.js';
import { useUnderwritingQuestion } from '#src/stores/underwriting-question.js';
import { HttpIndividualsService } from '#src/services/http-individuals.service.js';

export function useStepUnderwritingStore(pinia, hot) {
  const individualService = new HttpIndividualsService(pinia);
  const questionsService = new QuestionsService(pinia);
  return defineStore('step-underwriting', {
    state: () => ({
      requiredFields: {
        build: false,
      },
      insuredBuild: {
        valid: null,
        exists: null,
        minWeight: null,
        maxWeight: null,
        rating: null,
        okayToProceed: null,
      },
      jointInsuredBuild: {
        valid: null,
        exists: null,
        minWeight: null,
        maxWeight: null,
        rating: null,
        okayToProceed: null,
      },
      insuredRootQuestions: [],
      insuredChildQuestions: {},
      jointRootQuestions: [],
      jointChildQuestions: {},
      sharedRootQuestions: [],
    }),
    getters: {
      step: () => steps.UNDERWRITING,
      routeName: (s) => stepToRouteName[s.step],
      previous() {
        const secondary = useStepSecondaryAddresseeStore(pinia);
        if (secondary.inactive) return secondary.previous;
        return secondary.step;
      },
      next() {
        const payment = useStepPaymentStore(pinia);
        return payment.step;
      },
    },
    actions: {
      async beforeLoadAction() {
        const requiredFields = this.requiredFields;
        this.$reset();
        this.requiredFields = requiredFields;

        const { insuredQuestions, jointInsuredQuestions } =
          await questionsService.getUnderwritingQuestions();

        const insured = usePrimaryInsuredStore(pinia);
        const joint = useJointInsuredStore(pinia);

        const hasInsuredQuestions = Boolean(insuredQuestions.length);
        const hasJointInsuredQuestions = Boolean(jointInsuredQuestions.length);

        const questionGroups = {};
        const insuredQuestionsById = {};
        const jointQuestionsById = {};

        const newQuestionGroup = (question) => {
          questionGroups[question.label] = {
            insuredQuestion: null,
            jointQuestion: null,
            label: question.label,
          };
        };

        insuredQuestions.forEach((q) => {
          const question = useUnderwritingQuestion(q, pinia);
          insuredQuestionsById[q] = question;

          const { label, parentId } = question;
          if (parentId) {
            if (!this.insuredChildQuestions[parentId]) {
              this.insuredChildQuestions[parentId] = [];
            }
            this.insuredChildQuestions[parentId].push(q);
          } else {
            if (!questionGroups[label]) newQuestionGroup(question);
            questionGroups[label].insuredQuestion = q;
          }
        });

        jointInsuredQuestions.forEach((q) => {
          const question = useUnderwritingQuestion(q, pinia);
          jointQuestionsById[q] = question;

          const { label, parentId } = question;
          if (parentId) {
            if (!this.jointChildQuestions[parentId]) {
              this.jointChildQuestions[parentId] = [];
            }
            this.jointChildQuestions[parentId].push(q);
          } else {
            if (!questionGroups[label]) newQuestionGroup(question);
            questionGroups[label].jointQuestion = q;
          }
        });

        Object.keys(questionGroups).forEach((q) => {
          const { insuredQuestion, jointQuestion } = questionGroups[q];

          if (insuredQuestion && jointQuestion) {
            const insuredQuestion = useUnderwritingQuestion(
              questionGroups[q].insuredQuestion,
              pinia,
            );
            insuredQuestion.inputLabel = insured.first_name.model;
            insuredQuestion.label = null;

            const jointQuestion = useUnderwritingQuestion(questionGroups[q].jointQuestion, pinia);
            jointQuestion.inputLabel = joint.first_name.model;
            jointQuestion.label = null;

            this.sharedRootQuestions.push(questionGroups[q]);
          } else if (insuredQuestion) {
            this.insuredRootQuestions.push(insuredQuestion);
          } else if (jointQuestion) {
            this.jointRootQuestions.push(jointQuestion);
          }
        });

        return hasInsuredQuestions || hasJointInsuredQuestions;
      },
      clearBuild(insuredKey) {
        let buildKey = 'insuredBuild';
        if (insuredKey === INSURED_TYPES.JOINT) {
          buildKey = 'jointInsuredBuild';
        }
        this[buildKey].minWeight = null;
        this[buildKey].maxWeight = null;
        this[buildKey].rating = null;
        this[buildKey].okayToProceed = null;
        this[buildKey].exists = null;
        this[buildKey].valid = null;
      },
      async getBuild(insuredKey) {
        let buildKey = 'insuredBuild';
        if (insuredKey === INSURED_TYPES.JOINT) {
          buildKey = 'jointInsuredBuild';
        }

        const insured = useInsuredStore(insuredKey, pinia);
        const { minWeight, maxWeight, rating, okayToProceed } = await individualService.getBuild({
          id: insured.id,
          height: insured.height.model,
          weight: insured.weight.model,
        });
        this[buildKey].minWeight = minWeight;
        this[buildKey].maxWeight = maxWeight;
        this[buildKey].rating = rating;
        this[buildKey].okayToProceed = okayToProceed;
        this[buildKey].exists = !Object.values(this[buildKey]).every((v) => v === null);

        this[buildKey].valid =
          this[buildKey].okayToProceed ||
          (insured.weight >= minWeight && insured.weight <= maxWeight);
      },
      setStepRequirements(srq) {
        this.requiredFields.build = srq[REQS.BUILD];
      },
    },
  })(pinia, hot);
}
