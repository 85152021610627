import { parse } from 'date-fns/parse';
import { isBefore } from 'date-fns/isBefore';
import { isValid } from 'date-fns/isValid';
import { isSameDay } from 'date-fns/isSameDay';
import { format } from 'date-fns/format';
import { isBoolean } from '#src/util/helpers.js';

// Savable Property validators
export const validateAccountNumber = (property) => {
  return {
    v: () => {
      const v = property();
      return v && `${v}`.length >= 5;
    },
    message: 'Must be at least 5 digits long',
  };
};

export const validateRoutingNumber = (property) => {
  return {
    v: () => {
      const v = property();
      return v && `${v}`.length === 9;
    },
    message: 'Must be 9 digits long',
  };
};

export const validateBoolean = (property) => {
  return {
    v: () => {
      const v = property();
      return isBoolean(v);
    },
    message: 'Please specify',
  };
};

const isNumber = (v) => typeof v === 'number' && !isNaN(v);

export const validateText = (property, { minLength = 1, maxLength = 255, exactLength = null }) => {
  let message;

  if (isNumber(exactLength)) {
    message = `Must be exactly ${exactLength} characters long`;
  } else if (isNumber(minLength) && isNumber(maxLength)) {
    message = `Must be between ${minLength} and ${maxLength} characters long`;
  } else if (isNumber(minLength)) {
    message = `Must be at least ${minLength} characters long`;
  } else if (isNumber(maxLength)) {
    message = `Must be no more than ${maxLength} characters long`;
  }

  return {
    v: () => {
      let value = property();
      if (value?.toString) value = value.toString();
      if (!value || !value?.replace) return false;

      if (minLength && value.replace(/ /g, '').length < minLength) return false;
      if (exactLength && value.length !== exactLength) return false;
      if (maxLength && value.length > maxLength) return false;
      return true;
    },
    message,
  };
};

export const validateIsBeforeNow = (property) => {
  return {
    v: () => {
      try {
        const v = property();

        if (!v) return false;
        const date = parse(v, 'yyyy-MM-dd', new Date());
        if (!isValid(date)) return false;
        return isSameDay(new Date(), date) || isBefore(date, new Date());
      } catch (e) {
        return false;
      }
    },
    message: 'Must be before today',
  };
};

export const validateIsAfter = (dateProperty, dateIsAfterProperty) => {
  return {
    v: () => {
      try {
        const v = dateProperty();
        const v2 = dateIsAfterProperty();

        if (!v || !v2) return false;
        const date = parse(v, 'yyyy-MM-dd', new Date());
        if (!isValid(date)) return false;
        const date2 = parse(v2, 'yyyy-MM-dd', new Date());
        if (!isValid(date2)) return false;
        return isBefore(date2, date);
      } catch (e) {
        return false;
      }
    },
    message: () => {
      try {
        const v = dateIsAfterProperty();
        const date = parse(v, 'yyyy-MM-dd', new Date());
        const formattedDate = format(date, 'MM/dd/yyyy');
        return `Must be after ${formattedDate}`;
      } catch (e) {
        return 'Invalid comparison date';
      }
    },
  };
};

export const validatePhone = (property) => {
  return {
    v: () => {
      try {
        const v = property();
        return v && /\([2-9]{1}[0-9]{2}\) [0-9]{3}-[0-9]{4}/.test(v);
      } catch (e) {
        return false;
      }
    },
    message: 'Must be a valid phone number not beginning with 1 or 0',
  };
};
