import { useDialogStore } from '#src/stores/dialog';

import { PingService } from '#src/services/ping.service';
import { markRaw, onUnmounted, ref } from 'vue';
import NetworkFixedDialog from '#src/components/dialogs/NetworkFixedDialog.vue';

export const useOfflineDetector = (pinia) => {
  const pingService = new PingService(pinia);
  const previousConnection = ref(false);
  const events = ['online', 'offline', 'load'];
  const dialog = useDialogStore(pinia);

  function initialize() {
    events.forEach((event) => {
      window.addEventListener(event, isConnected);
    });

    onUnmounted(() => {
      events.forEach((event) => {
        window.removeEventListener(event, isConnected);
      });
    });
  }

  async function isConnected() {
    try {
      const ping = await pingService.pingServer();
      if (ping || navigator.onLine) {
        networkIsUp(true);
      }
    } catch (error) {
      if (error || !navigator.onLine) {
        networkIsUp(false);
      }
    }
  }

  function networkIsUp(status) {
    if (previousConnection.value !== status) return;
    previousConnection.value = !status;
    return dialog.showDialog({
      component: markRaw(NetworkFixedDialog),
      persistent: true,
      connectedToInternet: status,
    });
  }

  return { initialize };
};
