<template>
  <v-text-field
    :id="$attrs['data-testid']"
    v-model="model"
    v-maska:[options]
    v-bind="$attrs"
    :aria-label="$attrs?.label"
    inputmode="numeric"
  >
    <template v-if="$slots.message" #message="{ message }">
      <v-row v-if="message.trim()" class="ma-0">
        {{ message }}
        <v-spacer />
        <slot name="message" />
      </v-row>
      <slot v-else name="message" />
    </template>
    <template v-if="$slots.append" #append>
      <slot name="append" />
    </template>
    <template v-if="$slots['append-outer']" #append-outer>
      <slot name="append-outer" />
    </template>
    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
  </v-text-field>
</template>

<script setup>
import { vMaska } from 'maska';
import { watch, ref, toRef } from 'vue';

const emit = defineEmits(['update:model-value']);
const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: false,
    default: null,
  },
  includeNegative: Boolean,
  includeDecimals: Boolean,
});

const options = {
  preProcess: unmaskVal,
  postProcess: maskVal,
};

function maskVal(v) {
  if (!v && v !== 0) return '';
  if (v === '-' && props.includeNegative) return '-';
  if (v === '.' && props.includeDecimals) return '.';
  if (v === '-.' && props.includeDecimals && props.includeNegative) return '-.';

  let val = v.toString();

  let sub = 3 - (val.includes('.') ? val.length - val.indexOf('.') : 0);

  const formatterOptions = {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    signDisplay: props.includeNegative ? 'auto' : 'never',
  };

  if (!props.includeDecimals) {
    formatterOptions.maximumFractionDigits = 0;
    formatterOptions.minimumFractionDigits = 0;
    sub = 0;
  }
  const formattedValue = Intl.NumberFormat('en-US', formatterOptions).format(val);

  return formattedValue.slice(0, sub ? -sub : undefined).replace('$', '');
}

function unmaskVal(val) {
  return val.replace(/[$,]/g, '');
}

const propValue = toRef(props, 'modelValue');
let v = null;
if (propValue.value || propValue.value === 0) v = propValue.value;
if (props.includeDecimals && v?.toString && v.toString().indexOf('.') > -1) {
  v = v.toFixed(2);
}
const model = ref(v);

watch(
  () => propValue.value,
  () => {
    let unmasked = null;
    if (model.value || model.value === 0) unmasked = +unmaskVal(model.value);
    if (propValue.value === unmasked) return;
    model.value = maskVal(propValue.value);
  },
);

watch(model, () => {
  let unmasked = null;
  if (model.value || model.value === 0) unmasked = +unmaskVal(model.value);
  if (propValue.value === unmasked) return;
  emit('update:model-value', unmasked);
});
</script>
