import { format } from 'date-fns/format';
import { subYears } from 'date-fns/subYears';
import { differenceInYears } from 'date-fns/differenceInYears';
import { parse } from 'date-fns/parse';
import { isAfter } from 'date-fns/isAfter';

import { PAY_DURATIONS } from '#src/data/pay-durations.js';
import { STATES } from '#src/data/states-and-countries.js';

import { MONEY_POOL_OPTIONS, UNLIMITED_MONEY_POOL } from '#src/data/quoteParams.js';

import { v4 } from 'uuid';

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function generateUuid() {
  return v4();
}
export function isNotDefined(val) {
  return [null, undefined].includes(val);
}
export const clamp = (num, min, max) => {
  if (isNotDefined(min) && isNotDefined(max)) return num;
  if (isNotDefined(min)) return Math.min(num, max);
  if (isNotDefined(max)) return Math.max(num, min);
  return Math.min(Math.max(num, min), max);
};

export const parseErrorMessage = (e) => {
  if (typeof e === 'string') return e.substring(0, 250);

  const message =
    e?.response?.data?.message ||
    e?.response?.data?.error ||
    e?.response?.data?.errors ||
    e?.response?.data ||
    e?.response ||
    e;
  if (Array.isArray(message)) return message.join('. ');
  if (typeof message === 'object') {
    const key = Object.keys(message)[0];
    return `${key} ${message[key]}`;
  }

  if (typeof message === 'string') return message.substring(0, 250);
  return message;
};

export const capitalize = (str) => (!str ? 'null' : str.charAt(0).toUpperCase() + str.slice(1));

/** This generates value and validation e.g a prop of firstName => firstName & firstNameValidation */

export const dateToYears = (value) => {
  if (!value) return null;
  let years;
  try {
    years = differenceInYears(new Date(), parse(value, 'yyyy-MM-dd', new Date()));
  } catch (e) {
    years = null;
  }
  return years;
};

export const yearsToDate = (value) => {
  if ((!value && value !== 0) || isNaN(value)) {
    return null;
  }
  const startDate = subYears(new Date(), value);
  return format(startDate, 'yyyy-MM-dd');
};

export const closestCalc = (current, allValues) => {
  let closest = allValues[0];
  let closestDelta = Number.MAX_SAFE_INTEGER;
  allValues.forEach((value) => {
    const delta = Math.abs(current - value);
    if (delta < closestDelta) {
      closest = value;
      closestDelta = delta;
    }
  });
  return closest;
};

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export function compoundInterest({ principle, time, rate, compoundMode }) {
  const amount = principle * Math.pow(1 + rate / compoundMode, compoundMode * time);
  const interest = amount - principle;
  return interest;
}

export function presentValue({ future, time, rate, compoundMode }) {
  return future * (1 / Math.pow(1 + rate, time * compoundMode));
}

export const percentToInteger = (v) => (v || 0) * 100;
export const integerToPercent = (v) => ((v || 0) / 100).toFixed(2);

export function generateValidations(validations, root) {
  const friendlyValidations = {};
  for (const [key, validation] of Object.entries(validations)) {
    const errorMessages = [];
    Object.keys(validation).forEach((type) => {
      try {
        if (!validation[type].v(root)) {
          const message = validation[type].message;
          if (typeof message === 'string') errorMessages.push(validation[type].message);
          else errorMessages.push(validation[type].message(root));
        }
      } catch (e) {
        errorMessages.push('error');
      }
    });

    friendlyValidations[key] = {
      errorMessages,
      success: !errorMessages.length,
      validation,
    };
  }

  return friendlyValidations;
}

export const createLoaderWindow = () => {
  const newWindow = window.open();
  newWindow.document.body.innerHTML = `<style>
        .loader {
          border: 16px solid #f3f3f3; /* Light grey */
          border-top: 16px solid #3498db; /* Blue */
          border-radius: 50%;
          width: 100px;
          height: 100px;
          animation: spin 2s linear infinite;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        .main-content {
            width: 100vw;
            position:fixed;
            top: 300px;
            font-family: Roboto, sans-serif;
            text-align:center;
            font-size: 3.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
      </style>
        <div class="main-content">
        <div class="loader"></div>
          You'll be redirected to the PDF
          <br/> in the next 30 seconds.
        </div>`;
  return newWindow;
};

export function calcFaceAmountFromLTC(poolOfMoney, insuredAge, assumedRate = null, simple = false) {
  let principle = poolOfMoney;
  if (principle === UNLIMITED_MONEY_POOL) {
    principle = MONEY_POOL_OPTIONS[MONEY_POOL_OPTIONS.length - 2]; // 500,000
  }
  const time = 85 - insuredAge;
  const compoundMode = 1;
  const rateOptions = [0.04, 0.02, 0.01];

  const calcFaceAmount = (rate) => {
    let value;
    if (simple) value = simpleInterest(principle, time, rate);
    else value = compoundInterest({ principle, time, rate, compoundMode });
    return value;
  };
  let bestInflationRate = assumedRate;
  if (bestInflationRate === null) {
    bestInflationRate = rateOptions.find((rate) => calcFaceAmount(rate) * rate <= 50000);
  }

  return calcFaceAmount(bestInflationRate) + principle;
}

const simpleInterest = (principle, time, rate) => principle * time * rate;

export const undefinedOrNull = (val) => [null, undefined].includes(val);

export function listToSentence(textItems) {
  if (textItems.length === 0) return null;
  if (textItems.length === 1) return textItems[0];
  const allMinusLast = textItems.slice(0, textItems.length - 1).join(', ');
  const oxfordComma = textItems.length > 2 ? ',' : '';
  return `${allMinusLast}${oxfordComma} and ${textItems[textItems.length - 1]}`;
}

export function currencyFormatter(value, digits = 0, prefix = '$') {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
  });

  let result = formatter.format(value).replace('$', prefix);
  if (digits === 0) {
    const index = result.lastIndexOf('.');
    if (index > 0) {
      result = result.slice(0, index);
    }
  }

  return result;
}

export function durationText(value, abbreviateLife = false) {
  const lifetimeText = abbreviateLife ? 'Life' : 'Lifetime';
  if (value === PAY_DURATIONS.LIFETIME) return lifetimeText;

  if (value === PAY_DURATIONS.AGE_65) return 'age 65';
  if (typeof value === 'number') {
    return value === PAY_DURATIONS.SINGLE_PAY ? value + ' year' : value + ' years';
  }
  if (value) {
    return value
      .replace(/([0-9]+)/, '$1 years')
      .replace('life', 'life')
      .replace('1 years', '1 year');
  }
}

export function timestampFormatter(value, timeParser = 'none', timeFormat = 'basic') {
  const parsers = {
    none: null,
    'date-time': 'MM/dd/yyyy h:mma',
    'sole-day': 'yyyy-MM-dd',
    'formatted-day': 'MM/dd/yyyy',
  };

  const formats = {
    basic: 'MM/dd/yyyy',
    date: 'MMM do',
    'full-date-time': "MMMM do, yyyy 'at' h:mm a",
    'full-date': 'MMM d, yyyy',
    'date-time': 'MM/dd/yyyy h:mma',
    time: 'h:mma',
    'full-localize': 'EEEE',
    'full-month-date': 'MMMM do, yyyy',
    'full-friendly': 'EEEE, MMM yyyy',
    'localized-full-date-time': "EEE, MMM d, yyyy 'at' h:mm a",
  };

  if (!value) return null;

  const parser = parsers[timeParser];
  const dateFormat = formats[timeFormat];

  let parsedDay;

  if (parser) {
    parsedDay = parse(value, parser, new Date());
  } else {
    parsedDay = new Date(value);
  }

  return format(parsedDay, dateFormat);
}

export function heightText(value) {
  const inchesInFoot = 12;

  const quotient = Math.floor(value / inchesInFoot);
  const remainder = value % inchesInFoot;

  return `${quotient}' ${remainder}"`;
}

export function modeToAdverb(mode, capitalize = false, forPremium = false) {
  const text = {
    12: 'Monthly',
    4: 'Quarterly',
    2: forPremium ? 'Semi-Annual' : 'Semi-Annually',
    1: forPremium ? 'Annual' : 'Annually',
  }[mode];

  if (!capitalize) return text.toLowerCase();
  return text;
}

export function modeToNoun(mode) {
  return {
    12: 'month',
    4: 'quarter',
    2: 'half-year',
    1: 'year',
  }[mode];
}

export function modeToAdjective(mode) {
  return {
    12: 'monthly',
    4: 'quarterly',
    2: 'semi-annual',
    1: 'annual',
  }[mode];
}

export function modeToAbbreviation(mode) {
  return {
    12: 'mo.',
    4: 'qtr.',
    2: 'bi-yr.',
    1: 'yr.',
  }[mode];
}

export function yearsAndMonths(value) {
  const years = Math.trunc(value);
  const months = Math.trunc((value % 1) * 12);

  const text = [];

  if (years) {
    let suffix = 'Yrs.';
    if (years === 1) suffix = 'Yr.';
    text.push(`${years} ${suffix}`);
  }

  if (months) {
    text.push(`${months} Mo.`);
  }

  return text.join(' ');
}

export function searchToArray(value, { isNumeric = false } = {}) {
  if (Array.isArray(value)) return value;
  const arr = value
    .replaceAll('[', '')
    .replaceAll(']', '')
    .replaceAll(' ', '')
    .split(',')
    .filter(Boolean);

  if (isNumeric) return arr.map((v) => +v).filter((v) => !isNaN(v));
  return arr;
}

export const tin = (tin) => {
  if (!tin || tin.length === 0) return false;
  const noDashTIN = tin.replace(/-/g, '');
  return noDashTIN.length === 9;
};

export const ssn = (ssn) => {
  if (!ssn || ssn.length === 0) {
    return false;
  }
  const noDashSSN = ssn.replace(/-/g, '');
  const match = noDashSSN.match(/^([0-9]{3})([0-9]{2})([0-9]{4})$/);
  if (ssn === '123-45-6789') return false;
  return Boolean(match && noDashSSN.length === 9);
};

export const dateIsAfter = (date, dateThatIsAfter) => {
  if (!date || !dateThatIsAfter) return false;
  try {
    const firstDate = parse(date, 'yyyy-MM-dd', new Date());
    const afterDate = parse(dateThatIsAfter, 'yyyy-MM-dd', new Date());
    return isAfter(afterDate, firstDate);
  } catch (e) {
    return false;
  }
};

export const email = (value) => {
  const isEmpty = [null, undefined, ''].includes(value);
  if (isEmpty) return false;
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validFromRegex = regex.test(value.trim());

  return validFromRegex;
};

export function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

export function goTo(selector, container, offset = 0) {
  let elOffsetY = 0;
  if (selector) {
    let theElement = selector;
    if (typeof selector === 'string') {
      theElement = document.querySelector(selector);
    }
    elOffsetY = theElement?.offsetTop || 0;
  }
  const containerEl = document.querySelector(container);
  if (!containerEl) return;
  containerEl.scrollTop = elOffsetY + offset;
}

export function stateToFull(v) {
  return STATES.find(({ value }) => value === v)?.title;
}

export const dynamicColCompute = (rowElements, MAX_PER_ROW = 3, MAX_COLS = 12) => {
  if (!rowElements?.length) return null;
  const elements = {};
  rowElements.forEach((element, index) => {
    const multiple = Math.floor(index / MAX_PER_ROW);
    const nextIndex = (multiple + 1) * MAX_PER_ROW;

    if (rowElements.length >= nextIndex) {
      elements[index] = MAX_COLS / MAX_PER_ROW;
      return;
    }
    const remainder = rowElements.length % MAX_PER_ROW;
    elements[index] = MAX_COLS / remainder;
  });
  return elements;
};

export function unblockMainThread(fn) {
  setTimeout(fn, 0);
}

export const isBoolean = (v) => [true, false].includes(v);

export const boolOrNull = (v) => ([false, true].includes(v) ? v : null);
export const numOrNull = (v) => ([0, '0'].includes(v) || (v && !isNaN(+v)) ? +v : null);
export const valOrNull = (value) => {
  if (!value && value !== 0) return null;
  return value;
};

export const strToBoolean = (v) => ({ true: true, false: false })[v];

export const stringPrefillValue = (key, searchFn) => {
  const value = searchFn((v) => v?.[key])?.[key];
  return value || null;
};

export const booleanPrefillValue = (key, searchFn) => {
  const value = searchFn((v) => v?.[key])?.[key];
  const boolVal = strToBoolean(value);
  return isBoolean(boolVal) ? boolVal : null;
};

export const numberPrefillValue = (key, searchFn) => {
  const value = searchFn((v) => v?.[key])?.[key];
  if (isNaN(+value)) return null;
  return +value;
};

export const objectPrefillValue = (key, searchFn) => {
  const value = searchFn((v) => v?.[key])?.[key];
  if (!value) return { value: null, valid: true };
  if (typeof value === 'object') return { value, valid: true };
  try {
    JSON.parse(value);
    return { value, valid: true };
  } catch (e) {
    return { value: null, valid: false };
  }
};

export function getEnv() {
  try {
    import.meta.env.VITE_API_URL;
    return import.meta.env;
  } catch (e) {
    return process.env;
  }
}

export function isServerSide() {
  return typeof window === 'undefined';
}
