import { httpClient } from '#src/HttpClient.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';

export class ValidationsService {
  #pinia;
  #baseUrl;
  #httpClient;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/validations`;
    this.#httpClient = httpClient(pinia);
  }

  async validateEmail(email) {
    try {
      const result = await this.#httpClient.post(`${this.#baseUrl}/email_verify`, {
        email,
      });
      if (!result || !result.data) return false;
      return result.data.valid;
    } catch (e) {
      return false;
    }
  }
}
