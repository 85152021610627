import { httpClient } from '#src/HttpClient.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';

export class InitializationsService {
  #pinia;
  #baseUrl;
  #httpClient;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/initializations`;
    this.#httpClient = httpClient(pinia);
  }

  async fetchStateByIp() {
    const { data } = await this.#httpClient.get(`${this.#baseUrl}/addr_info`);
    return data?.state;
  }
}
