import { validateFormStructure } from '#src/components/form-builder/FormBuilderTemplates.js';

import {
  useInsuredBirthdateProperty,
  useInsuredSplitBirthdateProperty,
} from '#src/composables/insured-form-property.composable.js';

import { INSURED_TYPES, useInsuredStore } from '#src/stores/insured.js';
import { useQuotingStore } from '#src/stores/quoting.js';
import { ref } from 'vue';

export const useBirthdateForm = (pinia, module, { autofocus, dense } = {}) => {
  const stepValidated = ref(false);

  const birthdateField = () => {
    let property = useInsuredSplitBirthdateProperty;
    if (dense) property = useInsuredBirthdateProperty;
    return property(pinia, module, {
      mdColSize: 12,
      autofocus,
      onChange() {
        const insured = useInsuredStore(module, pinia);
        if (module !== INSURED_TYPES.INSURED) return;
        const quotingStore = useQuotingStore(pinia);

        const income_start_age = Math.max(66, insured.age + 10);
        const income_end_age = Math.max(income_start_age + 10, 90);

        quotingStore.params.income_start_age = income_start_age;
        quotingStore.params.income_end_age = income_end_age;
      },
      dirtyDependents: () => {
        const insured = useInsuredStore(module, pinia);
        return Boolean(insured.birthdate.model) || stepValidated.value;
      },
    });
  };

  function buildStructure() {
    return [
      {
        key: `${module}-birthdate-root`,
        fields: [birthdateField()],
      },
    ];
  }

  function validate({ throwOnFailure = false } = {}) {
    stepValidated.value = true;
    return validateFormStructure(buildStructure(), throwOnFailure);
  }

  const reactiveDependencies = [stepValidated];

  return { validate, buildStructure, reactiveDependencies };
};
