import { defineStore } from '#src/stores/state-wrapper.js';

import { usePrimaryInsuredStore, getPayorRoleForInsured } from '#src/stores/insured.js';

import { HttpIndividualsService } from '#src/services/http-individuals.service.js';

import RELATIONSHIP_OPTIONS, { GUARDIAN_RELATIONSHIPS } from '#src/data/relationships.js';
import { GENDER_ITEMS } from '#src/data/genders.js';
import { COUNTRIES_WITH_US, STATES, STATES_AND_COUNTRIES } from '#src/data/states-and-countries.js';

import {
  integerToPercent,
  percentToInteger,
  ssn as ssnValidator,
  email as emailValidator,
  dateToYears,
  generateUuid,
  isBoolean,
} from '#src/util/helpers.js';

import {
  CONTINGENT_BENEFICIARY,
  generateRoleData,
  JOINT_PAYOR,
  OWNER,
  PAYOR,
  PRIMARY_BENEFICIARY,
  SECONDARY_ADDRESSEE,
} from '#src/structures/Role.js';
import {
  savablePropertyRequestWrapper,
  useSavableProperty,
} from '#src/composables/savable-property.composable.js';

import {
  validateAccountNumber,
  validateBoolean,
  validateIsBeforeNow,
  validatePhone,
  validateRoutingNumber,
  validateText,
} from '#src/composables/savable-property-validators.mjs';

export const individualKeyGen = (id) => `parties-individual-${id}`;
export const individualGuardianKeyGen = (guardianForId) => `parties-guardian-for-${guardianForId}`;

//TODO: fix this. not a very good solution
export function getGuardianKeyGenWithSideffect(guardianForId, pinia) {
  const key = individualGuardianKeyGen(guardianForId);
  useIndividualStore(key, pinia).guardian_for_id = guardianForId;
  return key;
}
// TODO: (for individuals and entities)
// Use `id` for the actual fairway ID (currently party_id)
// Use `store_id` for the pinia store ID (currently id)

export function useIndividualStore(partyKey, pinia, hot) {
  const individualService = new HttpIndividualsService(pinia);

  return defineStore(partyKey, {
    state: () => ({
      //ids
      id: '',
      guardian_for_id: null,

      party_id: null,
      address_id: null,

      account_last_4: null,
      routing_last_4: null,

      roles: {
        [PRIMARY_BENEFICIARY]: null,
        [CONTINGENT_BENEFICIARY]: null,
        [OWNER]: null,
        [SECONDARY_ADDRESSEE]: null,
        [PAYOR]: null,
        [JOINT_PAYOR]: null,
      },
      role_for_id: {
        [PRIMARY_BENEFICIARY]: null,
        [CONTINGENT_BENEFICIARY]: null,
        [PAYOR]: null,
        [JOINT_PAYOR]: null,
        [OWNER]: null,
        [SECONDARY_ADDRESSEE]: null,
      },

      //savable properties
      draft_day_of_month: useSavableProperty({
        requestMap: 'draft_day_of_month',
        group: 'eft',
        rules: {
          inRange: {
            v: () => {
              const store = useIndividualStore(partyKey, pinia);
              return store.draft_day_of_month.model >= 1 && store.draft_day_of_month.model <= 28;
            },
            message: 'Must be between 1 and 28',
          },
        },
      }),
      street_address: useSavableProperty({
        requestMap: 'street_address',
        group: 'address',
        rules: {
          minLength: validateText(() => useIndividualStore(partyKey, pinia).street_address.model, {
            minLength: 2,
            maxLength: 255,
          }),
        },
      }),
      city: useSavableProperty({
        requestMap: 'city',
        group: 'address',
        rules: {
          minLength: validateText(() => useIndividualStore(partyKey, pinia).city.model, {
            minLength: 2,
            maxLength: 255,
          }),
        },
      }),
      state: useSavableProperty({
        requestMap: 'state',
        group: 'address',
        rules: {
          minLength: {
            message: 'Must be a valid state',
            v: () => {
              const store = useIndividualStore(partyKey, pinia);
              return STATES.some(({ value }) => value === store.state.model);
            },
          },
        },
      }),
      zip: useSavableProperty({
        requestMap: 'zip',
        group: 'address',
        rules: {
          minLength: validateText(() => useIndividualStore(partyKey, pinia).zip.model, {
            exactLength: 5,
          }),
        },
      }),
      country: useSavableProperty({
        requestMap: 'country',
        group: 'address',
        rules: {
          minLength: {
            message: 'Must be a valid country',
            v: () => {
              const store = useIndividualStore(partyKey, pinia);
              return COUNTRIES_WITH_US.some(({ value }) => value === store.country.model);
            },
          },
        },
      }),
      beneficiary_amount: useSavableProperty({
        requestMap: 'beneficiary_amount',
        group: 'beneficiary',
        requestFormatter: (v) => +integerToPercent(v),
        rules: {
          inRange: {
            v: () => {
              const store = useIndividualStore(partyKey, pinia);
              return store.beneficiary_amount.model >= 1 && store.beneficiary_amount.model <= 100;
            },
            message: 'Must be between 1 and 100',
          },
        },
      }),
      birthdate: useSavableProperty({
        requestMap: 'birthdate',
        group: 'root',
        rules: {
          dateIsBeforeNow: validateIsBeforeNow(
            () => useIndividualStore(partyKey, pinia).birthdate.model,
          ),
        },
      }),
      email: useSavableProperty({
        requestMap: 'email',
        group: 'root',
        rules: {
          // BasicEmailInput triggers this on its own. look into refactoring if possible
          isValid: {
            message: 'Must be a valid email',
            v: () => {
              const store = useIndividualStore(partyKey, pinia);
              return store.email.requestMeta.externallyValid === true;
            },
          },
          regexMatch: {
            message: 'Must be a valid email',
            v: () => {
              const store = useIndividualStore(partyKey, pinia);
              return emailValidator(store.email.model);
            },
          },
          cannotMatchInsured: {
            message: () => {
              const insuredStore = usePrimaryInsuredStore(pinia);
              return `Must not match ${insuredStore.first_name.model}'s email ${insuredStore.email.model}`;
            },
            v: () => {
              const store = useIndividualStore(partyKey, pinia);
              const insuredStore = usePrimaryInsuredStore(pinia);
              if (insuredStore.age < 18) {
                return true;
              }
              return store.email.model !== insuredStore.email.model;
            },
          },
        },
      }),
      first_name: useSavableProperty({
        requestMap: 'first_name',
        group: 'root',
        rules: {
          validLength: validateText(() => useIndividualStore(partyKey, pinia).first_name.model, {
            minLength: 1,
            maxLength: 50,
          }),
        },
      }),
      last_name: useSavableProperty({
        requestMap: 'last_name',
        group: 'root',
        rules: {
          validLength: validateText(() => useIndividualStore(partyKey, pinia).last_name.model, {
            minLength: 1,
            maxLength: 50,
          }),
        },
      }),
      gender: useSavableProperty({
        requestMap: 'gender',
        group: 'root',
        rules: {
          validLength: {
            v: () => {
              const store = useIndividualStore(partyKey, pinia);
              return GENDER_ITEMS.some(({ value }) => value === store.gender.model);
            },
            message: 'Must be ' + GENDER_ITEMS.map(({ title }) => title).join(' or '),
          },
        },
      }),
      phone_mobile: useSavableProperty({
        requestMap: 'phone_mobile',
        group: 'root',
        rules: {
          validNumber: validatePhone(() => useIndividualStore(partyKey, pinia).phone_mobile.model),
        },
      }),
      guardian_relationship: useSavableProperty({
        requestMap: 'relationship',
        group: 'root',
        rules: {
          inList: {
            message: 'Must be in list',
            v: () => {
              const individual = useIndividualStore(partyKey, pinia);
              return GUARDIAN_RELATIONSHIPS.some(
                ({ value }) => value === individual.guardian_relationship.model,
              );
            },
          },
        },
      }),
      relationship: useSavableProperty({
        requestMap: 'relationship',
        group: 'role',
        rules: {
          inList: {
            message: 'Must be in list',
            v: () => {
              const individual = useIndividualStore(partyKey, pinia);

              return RELATIONSHIP_OPTIONS.some(
                ({ value }) => value === individual.relationship.model,
              );
            },
          },
        },
      }),
      signature_city: useSavableProperty({
        requestMap: 'signature_city',
        group: 'root',
        rules: {
          minLength: validateText(() => useIndividualStore(partyKey, pinia).signature_city.model, {
            minLength: 2,
            maxLength: 255,
          }),
        },
      }),
      ssn: useSavableProperty({
        requestMap: 'ssn',
        group: 'root',
        rules: {
          validSsn: {
            v: () => {
              const store = useIndividualStore(partyKey, pinia);
              return ssnValidator(store.ssn.model);
            },
            message: 'Must be a valid SSN',
          },
        },
      }),
      account_number: useSavableProperty({
        group: 'eftNumbers',
        requestMap: 'account_number',
        rules: {
          validLength: validateAccountNumber(
            () => useIndividualStore(partyKey, pinia).account_number.model,
          ),
        },
      }),
      routing_number: useSavableProperty({
        group: 'eftNumbers',
        requestMap: 'routing_number',
        rules: {
          validLength: validateRoutingNumber(
            () => useIndividualStore(partyKey, pinia).routing_number.model,
          ),
        },
      }),
      country_of_citizenship: useSavableProperty({
        requestMap: 'country_of_citizenship',
        group: 'root',
        rules: {
          isInList: {
            v: () => {
              const store = useIndividualStore(partyKey, pinia);
              return COUNTRIES_WITH_US.some(
                ({ value }) => store.country_of_citizenship.model === value,
              );
            },
            message: 'Must be a valid country',
          },
        },
      }),
      birth_location: useSavableProperty({
        requestMap: 'birth_location',
        group: 'root',
        rules: {
          isInList: {
            message: 'Must be in list',
            v: () => {
              const store = useIndividualStore(partyKey, pinia);
              return STATES_AND_COUNTRIES.some(({ value }) => value === store.birth_location.model);
            },
          },
        },
      }),
      us_citizen: useSavableProperty({
        requestMap: 'us_citizen',
        group: 'root',
        rules: {
          isTrueOrFalse: validateBoolean(
            () => useIndividualStore(partyKey, pinia).us_citizen.model,
          ),
        },
      }),
    }),
    getters: {
      beneficiaryAmount: (s) => s.beneficiary_amount.asString(),
      generalType: () => 'individual',
      firstName: (s) => s.first_name.model,
      lastName: (s) => s.last_name.model,
      name: (s) => [s.firstName, s.lastName].filter(Boolean).join(' '),
      displayName: (s) => s.name,
      type: () => 'individual',
      age: (s) => dateToYears(s.birthdate.model) || 0,
      partyKey: () => partyKey,
    },
    actions: {
      initializeFromEapp(rawModel = {}) {
        const { roles, beneficiary_amount, relationship, role_for_id } = new generateRoleData(
          rawModel.application_roles,
        );

        let address = rawModel?.address;
        if (rawModel?.addresses?.length) address = rawModel.addresses[0];
        address = address || {};

        this.id = partyKey;
        this.party_id = rawModel?.id;
        this.address_id = address.id || '';

        Object.keys(this.roles).forEach((r) => (this.roles[r] = roles[r]));
        Object.keys(this.role_for_id).forEach((r) => (this.role_for_id[r] = role_for_id[r]));

        if (beneficiary_amount) this.beneficiary_amount.load(percentToInteger(beneficiary_amount));
        if (relationship) this.relationship.load(relationship);
        if (rawModel?.draft_day_of_month) this.draft_day_of_month.load(rawModel.draft_day_of_month);
        if (rawModel?.first_name) this.first_name.load(rawModel.first_name);
        if (rawModel?.last_name) this.last_name.load(rawModel.last_name);
        if (rawModel?.phone_mobile) this.phone_mobile.load(rawModel.phone_mobile);
        if (rawModel?.birthdate) this.birthdate.load(rawModel.birthdate);
        if (rawModel?.ssn) this.ssn.load(rawModel.ssn);
        if (rawModel?.gender) this.gender.load(rawModel.gender);
        if (rawModel?.signature_city) this.signature_city.load(rawModel.signature_city);
        if (rawModel?.relationship) this.guardian_relationship.load(rawModel.relationship);
        if (rawModel?.email) this.email.load(rawModel.email);
        if (rawModel?.country_of_citizenship) {
          this.country_of_citizenship.load(rawModel.country_of_citizenship);
        }
        if (isBoolean(rawModel?.us_citizen)) this.us_citizen.load(rawModel.us_citizen);
        if (rawModel?.birth_location) this.birth_location.load(rawModel.birth_location);
        if (address?.street_address) this.street_address.load(address.street_address);
        if (address?.city) this.city.load(address.city);
        if (address?.state) this.state.load(address.state);
        if (address?.zip) this.zip.load(address.zip);
        if (address?.country) this.country.load(address.country);

        if (rawModel?.guardian) {
          const guardianPiniaKey = getGuardianKeyGenWithSideffect(this.party_id, pinia);
          const guardian = useIndividualStore(guardianPiniaKey, pinia);
          guardian.initializeFromEapp(rawModel.guardian);
        }

        if (rawModel.efts?.length) {
          const { account_number_last_4, routing_last_4 } = rawModel.efts[0];
          this.account_last_4 = account_number_last_4;
          this.routing_last_4 = routing_last_4;
        }
      },
      async savablePropertyWrapper(func, { attributes, body, uuid }) {
        const composableAttributes = attributes.map((a) => this[a]);
        return savablePropertyRequestWrapper(func, { composableAttributes, body, uuid });
      },
      saveAttributes(attributes = []) {
        if (!attributes.length) return;

        const reqMap = {};
        const reqAttrs = {};
        attributes.forEach((a) => {
          const key = this[a].requestMeta.requestMap;
          const group = this[a].requestMeta.group;
          const value = this[a].requestMeta.format();
          if (!reqMap[group]) {
            reqMap[group] = {};
            reqAttrs[group] = [];
          }
          reqMap[group][key] = value;
          reqAttrs[group].push(a);
        });

        const { eft: eftReqGroup, ...baseReqGroups } = reqMap;
        const { eft: eftAttrs, ...baseAttrs } = reqAttrs;

        const promises = [];
        if (eftAttrs?.length) promises.push(this.saveEftData(eftAttrs, eftReqGroup));
        if (baseAttrs) {
          const attrs = Object.values(baseAttrs).reduce((acc, v) => [...acc, ...v], []);

          if (attrs.length) promises.push(this.saveBaseAttributes(attrs, baseReqGroups));
        }
        return Promise.all(promises);
      },
      async saveBaseAttributes(attributes, { root, address, role, beneficiary }) {
        let body = {};
        if (root) body = { ...root };

        if (address) {
          const addressAttributes = { ...address };
          if (this.address_id) addressAttributes.id = this.address_id;
          else addressAttributes.address_type = 'home';
          body.addresses_attributes = [addressAttributes];
        }

        if (role || beneficiary) {
          body.application_roles_attributes = [];

          Object.keys(this.roles).forEach((r) => {
            if (!this.roles[r]) return;

            let roleReq = {};
            if (role) roleReq = { ...roleReq, ...role };

            const isBeneRole = [PRIMARY_BENEFICIARY, CONTINGENT_BENEFICIARY].includes(r);
            if (isBeneRole && beneficiary) roleReq = { ...roleReq, ...beneficiary };

            body.application_roles_attributes.push({
              id: this.roles[r],
              ...roleReq,
            });
          });
        }

        let func = () => individualService.updateIndividual(this.party_id, body);
        if (this.guardian_for_id) {
          func = () => individualService.updateIndividualGuardian(this.guardian_for_id, body);
        }

        const res = await this.savablePropertyWrapper(func, {
          attributes,
          body,
          uuid: generateUuid(),
        });

        if (!this.guardian_for_id && res?.addresses) {
          const homeAddress = res.addresses.find((address) => address.address_type === 'home');
          if (homeAddress) this.address_id = homeAddress.id;
        }
      },
      async saveEftData(attributes, eftBody) {
        const func = () => individualService.updateEft(this.party_id, eftBody);
        return this.savablePropertyWrapper(func, {
          attributes,
          body: eftBody,
          uuid: generateUuid(),
        });
      },
      async saveEftAccountNumbers() {
        const body = {
          routing_number: this.routing_number.model,
          account_number: this.account_number.model,
        };
        const func = () => individualService.updateEft(this.party_id, body);
        const attributes = ['account_number', 'routing_number'];

        const res = await this.savablePropertyWrapper(func, {
          attributes,
          body,
          uuid: generateUuid(),
        });

        const { account_last_4, routing_last_4 } = res;
        this.account_last_4 = account_last_4;
        this.routing_last_4 = routing_last_4;
      },
      async deleteRole(role) {
        await individualService.updateIndividual(this.party_id, {
          application_roles_attributes: [{ id: this.roles[role], _destroy: true }],
        });
        this.roles[role] = null;
        if (role in this.role_for_id) this.role_for_id[role] = null;
      },
      async deleteParty() {
        await individualService.deleteIndividual(this.party_id);
        this.$dispose();
      },
      async addRole(body) {
        return await individualService.updateIndividual(this.party_id, body);
      },
      setCreatedRoleData(role, applicationRoles) {
        const createdRole = applicationRoles.find((r) => r.role === role);
        this.roles[role] = createdRole.id;
        if (role in this.role_for_id) this.role_for_id[role] = createdRole.insured_id;

        if ([PRIMARY_BENEFICIARY, CONTINGENT_BENEFICIARY].includes(role)) {
          this.beneficiary_amount.load(percentToInteger(createdRole.beneficiary_amount));
          this.relationship.load(createdRole.relationship);
        }
      },
      async addBeneficiaryRole({ beneficiary_amount, role, insured_id }) {
        const body = {
          application_roles_attributes: [
            {
              role,
              beneficiary_amount: integerToPercent(beneficiary_amount),
              insured_id,
              relationship: this.relationship.model,
            },
          ],
        };
        const res = await this.addRole(body);
        this.setCreatedRoleData(role, res.application_roles);
      },
      async addPayorRole({ insured_id }) {
        let insuredId = insured_id;
        if (!Array.isArray(insuredId)) insuredId = [insuredId];

        const roles = [];
        const application_roles_attributes = [];
        insuredId.forEach((i) => {
          const role = getPayorRoleForInsured(i, pinia);
          roles.push(role);
          application_roles_attributes.push({ role, insured_id: i });
        });

        const body = { application_roles_attributes };
        const res = await this.addRole(body);
        roles.forEach((r) => {
          this.setCreatedRoleData(r, res.application_roles);
        });
        return res;
      },
    },
  })(pinia, hot);
}
