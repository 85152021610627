export class WidgetCommunicatorService {
  #widgetCommunicator(message) {
    window.parent.postMessage(message, '*');
  }

  closeModal() {
    this.#widgetCommunicator({ type: 'close' });
  }

  resizeIframe(value) {
    this.#widgetCommunicator({ value, type: 'height-resize' });
  }

  focusIframe(value = 0, behavior) {
    this.#widgetCommunicator({ type: 'focus', value, behavior });
  }
}
