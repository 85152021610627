import { httpClient } from '#src/HttpClient';
import { useInstanceSettingsStore } from '#src/stores/instance-settings';

export class PingService {
  #pinia;
  #baseUrl;
  #httpClient;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/ping`;
    this.#httpClient = httpClient(pinia);
  }

  pingServer() {
    return this.#httpClient.post(this.#baseUrl);
  }
}
