import { createRouter as _createRouter, createWebHistory, createMemoryHistory } from 'vue-router';

import ApplyFlow from '#src/ApplyFlow.vue';

export const createRouter = (base) => {
  const router = _createRouter({
    history: import.meta.env.SSR ? createMemoryHistory('') : createWebHistory(''),
    routes: [
      {
        name: 'Flow',
        path: base,
        component: ApplyFlow,
      },
      {
        name: 'Flow',
        path: '/:pathMatch(.*)*',
        component: ApplyFlow,
      },
    ],
  });

  return router;
};
