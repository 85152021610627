import { mdiCalendar } from '@mdi/js';
import {
  AccountNumberField,
  AddressField,
  CheckAlertField,
  DateField,
  EmailField,
  FormFieldAdapter,
  IntegerField,
  PhoneField,
  RoutingNumberField,
  SelectField,
  TextField,
  TinField,
  YesNoField,
} from '#src/components/form-builder/FormBuilderTemplates.js';
import {
  ENTITY_BENEFICIARY_RELATIONSHIPS,
  ENTITY_OWNER_RELATIONSHIPS,
} from '#src/data/relationships.js';
import { BUSINESS_TAX_TYPES, useEntityStore } from '#src/stores/entity.js';

function storeKeyPrefix(storeKey, testId) {
  return `${storeKey}-${testId}`;
}
function labelPrefix(subjectivePronoun, text) {
  return `${subjectivePronoun}'s ${text}`;
}

export const IRREVOCABLE_OPTIONS = [
  { title: 'Irrevocable', value: true },
  { title: 'Revocable', value: false },
];

export function useEntityAddressFormProperty(
  pinia,
  module,
  fieldProps = { subjectivePronoun: null, countryVisible: false },
  switcher = { manualEntry: false, setManualEntry: () => {} },
) {
  return AddressField({
    switcher: {
      manualEntry: switcher.manualEntry,
      setManualEntry: switcher.setManualEntry,
    },
    fieldOptions: {
      street: {
        dataTestid: storeKeyPrefix(module, 'street-address'),
        label: labelPrefix(fieldProps.subjectivePronoun, 'Street Address'),
        placeholder: labelPrefix(fieldProps.subjectivePronoun, 'Street Address'),
        savableProperty: useEntityStore(module, pinia).street_address,
        overrideSave: () => useEntityStore(module, pinia).saveAttributes(['street_address']),
      },
      state: {
        dataTestid: storeKeyPrefix(module, 'state'),
        label: labelPrefix(fieldProps.subjectivePronoun, 'State'),
        placeholder: labelPrefix(fieldProps.subjectivePronoun, 'State'),
        savableProperty: useEntityStore(module, pinia).state,
        overrideSave: () => useEntityStore(module, pinia).saveAttributes(['state']),
      },
      city: {
        dataTestid: storeKeyPrefix(module, 'city'),
        label: labelPrefix(fieldProps.subjectivePronoun, 'City'),
        placeholder: labelPrefix(fieldProps.subjectivePronoun, 'City'),
        savableProperty: useEntityStore(module, pinia).city,
        overrideSave: () => useEntityStore(module, pinia).saveAttributes(['city']),
      },
      zip: {
        dataTestid: storeKeyPrefix(module, 'zip'),
        label: labelPrefix(fieldProps.subjectivePronoun, 'Zip'),
        placeholder: labelPrefix(fieldProps.subjectivePronoun, 'Zip'),
        savableProperty: useEntityStore(module, pinia).zip,
        overrideSave: () => useEntityStore(module, pinia).saveAttributes(['zip']),
      },
      country: {
        visible: fieldProps.countryVisible,
        dataTestid: storeKeyPrefix(module, 'country'),
        label: labelPrefix(fieldProps.subjectivePronoun, 'Country'),
        placeholder: labelPrefix(fieldProps.subjectivePronoun, 'Country'),
        savableProperty: useEntityStore(module, pinia).country,
        overrideSave: () => useEntityStore(module, pinia).saveAttributes(['country']),
      },
      autofill: {
        dataTestid: storeKeyPrefix(module, 'address-autofill'),
        label: labelPrefix(fieldProps.subjectivePronoun, 'Address'),
        placeholder: labelPrefix(fieldProps.subjectivePronoun, 'Address'),
        savableProperty: useEntityStore(module, pinia).street_address,
        save: () => {
          const attributes = ['street_address', 'city', 'state', 'zip'];
          if (useEntityStore(module, pinia).country.model) attributes.push('country');
          return useEntityStore(module, pinia).saveAttributes(attributes);
        },
      },
    },
  });
}

function genBuilderProps(key, additionalProps) {
  return {
    mdColSize: additionalProps?.mdColSize || 6,
    colSize: 12,
    key,
    slots: additionalProps?.slots || undefined,
  };
}

export function useEntityNameProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    TextField({
      savableProperty: useEntityStore(storeKey, pinia).name,
      dataTestid: storeKeyPrefix(storeKey, 'name'),
    }),
    genBuilderProps('name', additionalProps),
    {
      overrideSave: () => useEntityStore(storeKey, pinia).saveAttributes(['name']),
      ...additionalProps,
    },
  );
}
export function useEntityTinProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    TinField({
      savableProperty: useEntityStore(storeKey, pinia).tin,
      dataTestid: storeKeyPrefix(storeKey, 'tin'),
    }),
    genBuilderProps('tin', additionalProps),
    {
      overrideSave: () => useEntityStore(storeKey, pinia).saveAttributes(['tin']),
      ...additionalProps,
    },
  );
}
export function useEntityFormationDateProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    DateField({
      savableProperty: useEntityStore(storeKey, pinia).formation_date,
      dataTestid: storeKeyPrefix(storeKey, 'formation-date'),
    }),
    genBuilderProps('formation_date', additionalProps),
    {
      overrideSave: () => useEntityStore(storeKey, pinia).saveAttributes(['formation_date']),
      ...additionalProps,
    },
  );
}
export function useEntityPhoneWorkProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    PhoneField({
      savableProperty: useEntityStore(storeKey, pinia).phone_work,
      dataTestid: storeKeyPrefix(storeKey, 'phone'),
    }),
    genBuilderProps('phone_work', additionalProps),
    {
      overrideSave: () => useEntityStore(storeKey, pinia).saveAttributes(['phone_work']),
      ...additionalProps,
    },
  );
}
export function useEntityBeneficiaryAmountProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    IntegerField({
      savableProperty: useEntityStore(storeKey, pinia).beneficiary_amount,
      dataTestid: storeKeyPrefix(storeKey, 'beneficiary-amount'),
      min: 1,
      max: 100,
    }),
    genBuilderProps('beneficiary_amount', additionalProps),
    {
      overrideSave: () => useEntityStore(storeKey, pinia).saveAttributes(['beneficiary_amount']),
      ...additionalProps,
    },
  );
}
export function useEntityInsuredIsSignerProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    YesNoField({
      savableProperty: useEntityStore(storeKey, pinia).insured_is_signer,
      dataTestid: storeKeyPrefix(storeKey, 'insured-is-signer'),
    }),
    genBuilderProps('insured_is_signer', additionalProps),
    {
      overrideSave: () => useEntityStore(storeKey, pinia).saveAttributes(['insured_is_signer']),
      ...additionalProps,
    },
  );
}
export function useEntityResponsiblePartyRelationshipProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    TextField({
      savableProperty: useEntityStore(storeKey, pinia).responsible_party_relationship,
      dataTestid: storeKeyPrefix(storeKey, 'responsible-party-relationship'),
    }),
    genBuilderProps('responsible_party_relationship', additionalProps),
    {
      overrideSave: () =>
        useEntityStore(storeKey, pinia).saveAttributes(['responsible_party_relationship']),
      ...additionalProps,
    },
  );
}
export function useEntityResponsiblePartySoleSignerProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    CheckAlertField({
      savableProperty: useEntityStore(storeKey, pinia).responsible_party_sole_signer,
      dataTestid: storeKeyPrefix(storeKey, 'responsible-party-sole-signer'),
    }),
    genBuilderProps('responsible_party_sole_signer', additionalProps),
    {
      overrideSave: () =>
        useEntityStore(storeKey, pinia).saveAttributes(['responsible_party_sole_signer']),
      ...additionalProps,
    },
  );
}
export function useEntityResponsibleIndividualFirstNameProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    TextField({
      savableProperty: useEntityStore(storeKey, pinia).responsible_individual_first_name,
      dataTestid: storeKeyPrefix(storeKey, 'responsible-party-first-name'),
    }),
    genBuilderProps('responsible_individual_first_name', additionalProps),
    {
      overrideSave: () =>
        useEntityStore(storeKey, pinia).saveAttributes(['responsible_individual_first_name']),
      ...additionalProps,
    },
  );
}
export function useEntityResponsibleIndividualLastNameProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    TextField({
      savableProperty: useEntityStore(storeKey, pinia).responsible_individual_last_name,
      dataTestid: storeKeyPrefix(storeKey, 'responsible-party-last-name'),
    }),
    genBuilderProps('responsible_individual_last_name', additionalProps),
    {
      overrideSave: () =>
        useEntityStore(storeKey, pinia).saveAttributes(['responsible_individual_last_name']),
      ...additionalProps,
    },
  );
}
export function useEntityResponsibleIndividualEmailProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    EmailField({
      savableProperty: useEntityStore(storeKey, pinia).responsible_individual_email,
      dataTestid: storeKeyPrefix(storeKey, 'responsible-individual-email'),
    }),
    genBuilderProps('responsible_individual_email', additionalProps),
    {
      overrideSave: () =>
        useEntityStore(storeKey, pinia).saveAttributes(['responsible_individual_email']),
      ...additionalProps,
    },
  );
}

function useEntityRelationshipProperty(pinia, storeKey, additionalProps, items) {
  return FormFieldAdapter(
    SelectField({
      savableProperty: useEntityStore(storeKey, pinia).relationship,
      dataTestid: storeKeyPrefix(storeKey, 'relationship'),
      items,
    }),
    genBuilderProps('relationship', additionalProps),
    {
      overrideSave: () => useEntityStore(storeKey, pinia).saveAttributes(['relationship']),
      ...additionalProps,
    },
  );
}

export function useEntityBeneficiaryRelationshipProperty(pinia, storeKey, additionalProps) {
  return useEntityRelationshipProperty(
    pinia,
    storeKey,
    additionalProps,
    ENTITY_BENEFICIARY_RELATIONSHIPS,
  );
}

export function useEntityOwnerRelationshipProperty(pinia, storeKey, additionalProps) {
  return useEntityRelationshipProperty(
    pinia,
    storeKey,
    additionalProps,
    ENTITY_OWNER_RELATIONSHIPS,
  );
}

export function useEntityPayorRelationshipProperty(pinia, storeKey, additionalProps) {
  return useEntityRelationshipProperty(
    pinia,
    storeKey,
    additionalProps,
    ENTITY_OWNER_RELATIONSHIPS,
  );
}

export function useEntityIrrevocableProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    SelectField({
      savableProperty: useEntityStore(storeKey, pinia).irrevocable,
      items: IRREVOCABLE_OPTIONS,
      dataTestid: storeKeyPrefix(storeKey, 'irrevocable'),
    }),
    genBuilderProps('irrevocable', additionalProps),
    {
      overrideSave: () => useEntityStore(storeKey, pinia).saveAttributes(['irrevocable']),
      ...additionalProps,
    },
  );
}
export function useEntityTaxTypeProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    SelectField({
      savableProperty: useEntityStore(storeKey, pinia).tax_type,
      items: BUSINESS_TAX_TYPES,
      dataTestid: storeKeyPrefix(storeKey, 'tax-type'),
    }),
    genBuilderProps('tax_type', additionalProps),
    {
      overrideSave: () => useEntityStore(storeKey, pinia).saveAttributes(['tax_type']),
      ...additionalProps,
    },
  );
}
export function useEntityRoutingNumberProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    RoutingNumberField({
      savableProperty: useEntityStore(storeKey, pinia).routing_number,
      dataTestid: storeKeyPrefix('routing-number'),
      inputmode: 'numeric',
    }),
    genBuilderProps('routing_number', additionalProps),
    {
      overrideSave: () => {},
      ...additionalProps,
    },
  );
}
export function useEntityAccountNumberProperty(pinia, storeKey, additionalProps) {
  return FormFieldAdapter(
    AccountNumberField({
      savableProperty: useEntityStore(storeKey, pinia).account_number,
      dataTestid: storeKeyPrefix('account-number'),
      inputmode: 'numeric',
    }),
    genBuilderProps('account_number', additionalProps),
    {
      overrideSave: () => {},
      ...additionalProps,
    },
  );
}

export function useEntityDraftDayOfMonth(pinia, storeKey, additionalProps = {}) {
  return FormFieldAdapter(
    IntegerField({
      dataTestid: storeKeyPrefix(storeKey, 'draft-day-of-month'),
      prependInnerIcon: mdiCalendar,
      min: 0,
      max: 28,
      savableProperty: useEntityStore(storeKey, pinia).draft_day_of_month,
    }),
    genBuilderProps('draft_day_of_month', additionalProps),
    {
      overrideSave: () => useEntityStore(storeKey, pinia).saveAttributes(['draft_day_of_month']),
      ...additionalProps,
    },
  );
}
