export const PRIMARY_BENEFICIARY = 'Primary Beneficiary';
export const CONTINGENT_BENEFICIARY = 'Contingent Beneficiary';
export const OWNER = 'Owner';
export const JOINT_OWNER = 'Joint Owner';
export const EMPLOYER = 'Employer';
export const TRUST = 'Trust';
export const INSURED = 'Insured';
export const JOINT_INSURED = 'Joint Insured';
export const SPOUSE = 'Spouse';
export const SECONDARY_ADDRESSEE = 'Secondary Addressee';
export const GUARDIAN = 'Guardian';
export const PAYOR = 'Payor';
export const JOINT_PAYOR = 'Joint Payor';

export function generateRoleData(rawRoles) {
  const roles = {
    [JOINT_OWNER]: null,
    [OWNER]: null,
    [PRIMARY_BENEFICIARY]: null,
    [CONTINGENT_BENEFICIARY]: null,
    [INSURED]: null,
    [JOINT_INSURED]: null,
    [SECONDARY_ADDRESSEE]: null,
    [PAYOR]: null,
    [JOINT_PAYOR]: null,
  };

  let beneficiary_amount = null;
  let relationship = null;
  const role_for_id = {
    [PAYOR]: null,
    [JOINT_PAYOR]: null,
    [OWNER]: null,
    [SECONDARY_ADDRESSEE]: null,
    [PRIMARY_BENEFICIARY]: null,
    [CONTINGENT_BENEFICIARY]: null,
  };

  if (rawRoles) {
    rawRoles.forEach((role) => {
      if (role.beneficiary_amount) beneficiary_amount = role.beneficiary_amount;
      if (role.relationship) relationship = role.relationship;
      if (role.insured_id && Object.keys(role_for_id).includes(role.role)) {
        role_for_id[role.role] = role.insured_id;
      }
      roles[role.role] = role.id;
    });
  }

  return { roles, beneficiary_amount, relationship, role_for_id };
}
