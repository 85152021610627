import { httpClient } from '#src/HttpClient';
import { useInstanceSettingsStore } from '#src/stores/instance-settings';

export class NiprService {
  #pinia;
  #baseUrl;
  #httpClient;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/nipr`;
    this.#httpClient = httpClient(pinia);
  }

  async validateNpn(npn) {
    const response = await this.#httpClient.post(`${this.#baseUrl}/validate`, { npn });
    if (response?.data?.url) {
      let url = `https://${response.data.url}`;
      if (response.data.url.indexOf('://') > -1) url = `${response.data.url}`;
      return { url };
    }
    return {
      validNpn: Boolean(response?.data?.valid),
      error: response?.data?.error,
    };
  }
}
