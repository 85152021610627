<template>
  <v-row>
    <v-col cols="12">
      <step-layout>
        <template #title> We've received your request! </template>
        <template #body>
          <h3 class="text-left ma-3 reg-text">Here's what happens next:</h3>
          <ul class="text-left reg-text ml-7">
            <li class="test-left">
              One of our licensed experts will call {{ name }} to review quotes and verify
              information over the phone.
            </li>
            <li class="test-left">
              We'll help {{ name }} choose the right insurer, complete the application, and assist
              them through underwriting.
            </li>
            <li class="test-left">
              Track the status of this referral
              <a
                :href="`${app_url}/electronic-applications/${id}`"
                class="text-primary clickable"
                target="_blank"
                >in BOSS.</a
              >
            </li>
            <li class="test-left">You can click Next if you'd like to continue the application.</li>
          </ul>
        </template>
      </step-layout>
    </v-col>
    <v-col v-if="agent.phone" cols="12">
      <step-layout>
        <template #title> Prefer to call us? </template>
        <template #body>
          If you call now, your request can be processed immediately
          <a :href="`tel:${agent.phone}`" target="_parent" class="text-primary clickable">{{
            agent.phone
          }}</a>
        </template>
      </step-layout>
    </v-col>
  </v-row>
</template>

<script setup>
import StepLayout from '#src/components/shared/StepLayout.vue';
import { useEappStore } from '#src/stores/electronic-application';
import { useInstanceSettingsStore } from '#src/stores/instance-settings';
import { usePrimaryInsuredStore } from '#src/stores/insured';
import { storeToRefs } from 'pinia';
import { inject } from 'vue';

const pinia = inject('pinia');
const primaryInsuredStore = usePrimaryInsuredStore(pinia);
const eApp = useEappStore(pinia);
const instance = useInstanceSettingsStore(pinia);

const { name } = storeToRefs(primaryInsuredStore);
const { app_url, agent } = storeToRefs(instance);
const { id } = storeToRefs(eApp);
</script>
