export function OverridePrefillBehavior(model = {}) {
  if (typeof model !== 'object') return {};

  const behavior = {}
  if('send_client_intro_message' in model) {
    behavior.send_client_intro_message = model.send_client_intro_message;
  }

  if('start_page' in model) {
    behavior.start_page = model.start_page;
  }

  if('step' in model) {
    behavior.step = model.step;
  }

  return behavior;
}
