import { parseErrorMessage } from '#src/util/helpers.js';
import { computed, reactive, ref, toRaw } from 'vue';

export function useSavableProperty({
  rules,
  requestMap,
  group,
  requestFormatter,
  validationSideEffects = () => {},
} = {}) {
  const _internalValue = ref(null);
  const _externallyValid = ref(null);

  const model = computed({
    get() {
      return _internalValue.value;
    },
    set(v) {
      _internalValue.value = v;

      resetRequestMeta();
      validate();
      validationSideEffects();
    },
  });

  const externallyValid = computed({
    get() {
      return _externallyValid.value;
    },
    set(v) {
      _externallyValid.value = v;
      validate();
    },
  });

  const requestMeta = ref({
    requests: [],
    errorMessage: '',
    externallyValid,
    requestMap,
    group,
    format,
    deleteRequest: (uuid) => {
      const index = requestMeta.value.requests.findIndex(({ id }) => id === uuid);
      if (index > -1) requestMeta.value.requests.splice(index, 1);
    },
  });

  function resetRequestMeta() {
    requestMeta.value.errorMessage = '';
  }

  function validate(markDirty = false) {
    validation.value.errorMessages.splice(0, validation.value.errorMessages.length);

    Object.keys(rules).forEach((rule) => {
      try {
        if (rules[rule].v()) return;

        let message = rules[rule].message;
        if (typeof message === 'function') message = rules[rule].message();

        validation.value.errorMessages.push(message);
      } catch (e) {
        validation.value.errorMessages.push('error');
      }
    });

    if (requestMeta.value.errorMessage) {
      validation.value.errorMessages.push(requestMeta.value.errorMessage);
    }

    validation.value.success = !validation.value.errorMessages.length;
    if (markDirty) validation.value.dirty = true;
    return validation.value.success === true;
  }

  const validation = ref({
    success: null,
    errorMessages: [],
    validate,
    dirty: false,
  });

  function format() {
    let reqValue = model.value;

    if (requestFormatter) reqValue = requestFormatter(model.value);

    return reqValue;
  }

  function load(v) {
    _externallyValid.value = true;
    model.value = v;
    validation.value.dirty = false;
  }

  function asString() {
    return model.value;
  }

  return {
    model,
    validation,
    requestMeta,
    load,
    asString,
  };
}

// This is useful if we need the returnType of a savableProperty needs to line up with pinia's storeToRefs
//  -> pretty much any time we use it in a non pinia stateful fashion.

// should we always just return a reactive value (even in pinia stores)? why would we use reactive instead of nested refs?
export const useInComponentSavableProperty = (args) => reactive(toRaw(useSavableProperty(args)));

export const savablePropertyRequestWrapper = async (func, { composableAttributes, body, uuid }) => {
  composableAttributes.forEach((a) => {
    a.requestMeta.requests.push({
      id: uuid,
      initiatedAt: new Date().getTime(),
      payload: JSON.stringify(body),
    });
  });
  let errorMessage, response, error;
  try {
    response = await func();
  } catch (e) {
    error = e;
    errorMessage = parseErrorMessage(e);
  } finally {
    composableAttributes.forEach((a) => {
      const index = a.requestMeta.deleteRequest(uuid);
      if (index > -1) a.requestMeta.requests.splice(index, 1);
      if (errorMessage) a.requestMeta.errorMessage = errorMessage;

      a.requestMeta.externallyValid = !errorMessage;
      if (errorMessage) a.requestMeta.errorMessage = errorMessage;
      a.validation.validate();
    });
  }

  if (error) throw error;
  return response;
};
