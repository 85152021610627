import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';

let hasLoaded;
export function attachTagManager(pinia) {
  const instance = useInstanceSettingsStore(pinia);
  hasLoaded = true;

  const tags = [import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID];
  if (instance.google_tag_manager_id) {
    tags.push(instance.google_tag_manager_id);
  }
  initializeDataLayer(tags);
}

function gtm() {
  try {
    window.dataLayerGtag.push(arguments);
  } catch (e) {
    // do nothing
  }
}

export function initializeDataLayer(tags) {
  if (!hasLoaded) return;

  window.dataLayerGtag = window.dataLayerGtag || [];

  // Send page view must be false due to this being an SPA
  tags.forEach((tag) => {
    gtm('config', tag, {
      send_page_view: false,
      cookie_flags: 'samesite=none;secure',
    });
  });

  gtm('event', 'gtm.js', { 'gtm.start': new Date().getTime() });
}

export function trackPageChange({ page_title, page_location }) {
  if (!hasLoaded) return;
  gtm({
    event: 'gtm.js',
    page_title,
    page_location,
  });
}
