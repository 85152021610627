import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from '#src/stores/state-wrapper.js';

export const useStepAgentSignupStore = (pinia, hot) =>
  defineStore('step-agent-signup', {
    getters: {
      hideNoLicenseBanner: () => true,
      step: () => steps.AGENT_SIGN_UP,
      routeName: (s) => stepToRouteName[s.step],
      next: () => null,
      previous: () => null,
      skipStepSave: () => true,
      hidesHeader: () => true,
    },
  })(pinia, hot);
