import { httpClient } from '#src/HttpClient';
import { useInstanceSettingsStore } from '#src/stores/instance-settings';

export class UsersService {
  #pinia;
  #baseUrl;
  #httpClient;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/users`;
    this.#httpClient = httpClient(pinia);
  }

  createUser({
    email,
    password,
    source,
    connections,
    npn,
    referrer_id,
    referrer_type,
    assignment_npn,
    commission_lock,
    marketing_manager_id,
  }) {
    return this.#httpClient.post(this.#baseUrl, {
      user: {
        email,
        password,
        source,
        connections,
        npn,
        referrer_id,
        referrer_type,
        assignment_npn,
        commission_lock,
        marketing_manager_id,
      },
    });
  }
}
