<template>
  <div class="pos-fixed" style="right: 0; left: 0; height: 4px; bottom: 0">
    <v-progress-linear
      v-if="progressWidth > 0"
      ref="header"
      :model-value="progressWidth"
      color="primary"
      aria-label="Application Progress"
    />
  </div>
</template>

<script setup>
import { steps } from '#src/enumerations/step-enumeration';

import { useFlowStore } from '#src/stores/flow';
import { computed, inject } from 'vue';

const pinia = inject('pinia');
const flow = useFlowStore(pinia);

const stepValues = Object.values(steps);
const quoteIndex = stepValues.findIndex((s) => +s === +steps.QUOTE);

const progressWidth = computed(() => {
  if (flow.initialStep === flow.step) return 0;

  const currIndex = stepValues.findIndex((s) => +s === +flow.step);
  // quote step = 50% progress

  if (currIndex <= quoteIndex) {
    return Math.ceil((currIndex / quoteIndex) * 50);
  }

  return Math.ceil((currIndex / (stepValues.length - 1)) * 50) + 50;
});
</script>
