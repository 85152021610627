<template>
  <v-row class="ma-0" dense>
    <template v-if="showDualView">
      <v-col cols="12" :md="stacked ? 12 : 6">
        <v-card variant="outlined" style="border-color: lightgrey">
          <v-card-title> <slot name="insured-title" /> </v-card-title>
          <v-card-text>
            <slot name="insured" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :md="stacked ? 12 : 6">
        <v-card variant="outlined" style="border-color: lightgrey">
          <v-card-title> <slot name="joint-insured-title" /> </v-card-title>
          <v-card-text>
            <slot name="joint-insured" />
          </v-card-text>
        </v-card>
      </v-col>
    </template>
    <v-col v-else cols="12">
      <div v-if="alwaysShowInsuredTitle" class="text-left mb-3">
        <slot name="insured-title" />
      </div>
      <slot name="insured" />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'pinia';
import { useEappStore } from '#src/stores/electronic-application';
export default {
  props: {
    alwaysShowInsuredTitle: Boolean,
    stacked: Boolean,
    hideDualView: Boolean,
  },
  computed: {
    ...mapState(useEappStore, ['isDualInsured']),
    showDualView() {
      if (!this.isDualInsured) return false;
      if (this.hideDualView) return false;
      return true;
    },
  },
};
</script>
