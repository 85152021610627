<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 436.54 172.54"
  >
    <rect x="1" y="1" width="434.54" height="53.28" fill="#fafafa" />
    <rect
      x="1"
      y="118.26"
      width="142.08"
      height="53.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <rect
      x="147.23"
      y="118.26"
      width="142.08"
      height="53.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <rect
      x="293.46"
      y="118.26"
      width="142.08"
      height="53.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <polyline
      points="143.08 48.28 143.08 54.28 137.08 54.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      x1="125.25"
      y1="54.28"
      x2="12.91"
      y2="54.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
      stroke-dasharray="11.83 11.83"
    />
    <polyline
      points="7 54.28 1 54.28 1 48.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      x1="1"
      y1="34.52"
      x2="1"
      y2="13.88"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
      stroke-dasharray="13.76 13.76"
    />
    <polyline
      points="1 7 1 1 7 1"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      x1="18.83"
      y1="1"
      x2="131.16"
      y2="1"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
      stroke-dasharray="11.83 11.83"
    />
    <polyline
      points="137.08 1 143.08 1 143.08 7"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      x1="143.08"
      y1="20.76"
      x2="143.08"
      y2="41.4"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
      stroke-dasharray="13.76 13.76"
    />
    <polyline
      points="289.31 48.28 289.31 54.28 283.31 54.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      x1="271.48"
      y1="54.28"
      x2="159.14"
      y2="54.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
      stroke-dasharray="11.83 11.83"
    />
    <polyline
      points="153.23 54.28 147.23 54.28 147.23 48.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      x1="147.23"
      y1="34.52"
      x2="147.23"
      y2="13.88"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
      stroke-dasharray="13.76 13.76"
    />
    <polyline
      points="147.23 7 147.23 1 153.23 1"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      x1="165.06"
      y1="1"
      x2="277.4"
      y2="1"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
      stroke-dasharray="11.83 11.83"
    />
    <polyline
      points="283.31 1 289.31 1 289.31 7"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      x1="289.31"
      y1="20.76"
      x2="289.31"
      y2="41.4"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
      stroke-dasharray="13.76 13.76"
    />
    <polyline
      points="435.54 48.28 435.54 54.28 429.54 54.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      x1="417.71"
      y1="54.28"
      x2="305.37"
      y2="54.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
      stroke-dasharray="11.83 11.83"
    />
    <polyline
      points="299.46 54.28 293.46 54.28 293.46 48.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      x1="293.46"
      y1="34.52"
      x2="293.46"
      y2="13.88"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
      stroke-dasharray="13.76 13.76"
    />
    <polyline
      points="293.46 7 293.46 1 299.46 1"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      x1="311.29"
      y1="1"
      x2="423.63"
      y2="1"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
      stroke-dasharray="11.83 11.83"
    />
    <polyline
      points="429.54 1 435.54 1 435.54 7"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      x1="435.54"
      y1="20.76"
      x2="435.54"
      y2="41.4"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
      stroke-dasharray="13.76 13.76"
    />
    <rect
      x="74.12"
      y="59.63"
      width="142.08"
      height="53.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <rect
      x="220.35"
      y="59.63"
      width="142.08"
      height="53.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <rect
      x="366.58"
      y="59.63"
      width="68.96"
      height="53.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <rect
      x="1"
      y="59.63"
      width="68.96"
      height="53.28"
      fill="none"
      stroke="#231f20"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'BadDomain',
};
</script>
