import { stepToRouteName, steps } from '#src/enumerations/step-enumeration.js';
import { defineStore } from '#src/stores/state-wrapper.js';

export const CRITICAL_FAILURE_REASONS = {
  FAILED_TO_BOOT: 'failed-to-boot',
  BAD_DOMAIN: 'bad-domain',
  NO_PRODUCTS: 'no-products',
  REQUIRES_SUBSCRIPTION: 'requires-subscription',
};

export const useStepCriticalFailure = (pinia, hot) =>
  defineStore('step-critical-failure', {
    state: () => ({ criticalFailureReason: null }),
    getters: {
      step: () => steps.CRITICAL_FAILURE,
      routeName: (s) => stepToRouteName[s.step],
      next: () => null,
      previous: () => null,
      hidesFooter: () => true,
      controlsNext: () => true,
      hidesHeader: () => true,
    },
  })(pinia, hot);
