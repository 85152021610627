import { numOrNull, valOrNull } from '#src/util/helpers.js';

export function Agent(model = {}) {
  return {
    id: numOrNull(model?.id),
    npn: valOrNull(model?.npn),
    name: valOrNull(model?.name),
    title: valOrNull(model?.title),
    type: valOrNull(model?.type),
    phone: valOrNull(model?.phone),
    address: valOrNull(model?.address),
    email: valOrNull(model?.email),
    insurance_licenses: model?.insurance_licenses || [],
  };
}

export function setAgentFromRequest(raw = {}) {
  const agent = Agent();
  if (!raw) return agent;

  agent.id = numOrNull(raw.id);
  agent.npn = valOrNull(raw.npn);
  agent.name = valOrNull(raw.name);
  agent.title = valOrNull(raw.title);
  agent.type = valOrNull(raw.type);
  agent.phone = valOrNull(raw.phone);
  agent.address = valOrNull(raw.address);
  agent.email = valOrNull(raw.email);

  if (Array.isArray(raw.insurance_licenses)) {
    agent.insurance_licenses = raw.insurance_licenses.map((l) => ({
      state: l.state,
      number: l.number,
    }));
  }

  return agent;
}
