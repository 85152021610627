<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 133.34">
    <path
      d="M0,165.65c16.25,0,39,75.39,105.21,97.61,65.16,21.87,117.4-10,129-17.52"
      transform="translate(0 -144.57)"
      fill="none"
      stroke="#231f20"
      stroke-miterlimit="10"
      stroke-width="7"
    />
    <path
      d="M612,247.7c-12.19,0-53.17-52.71-113.78-85-64.42-34.31-127,8.72-139.49,15.26"
      transform="translate(0 -144.57)"
      fill="none"
      stroke="#ffffff"
      stroke-miterlimit="10"
      stroke-width="7"
    />
    <rect
      x="313.66"
      y="150.31"
      width="48.64"
      height="79.14"
      rx="11.67"
      transform="translate(-49.55 34.97) rotate(-27.8)"
      fill="none"
      stroke="#ffffff"
      stroke-miterlimit="10"
      stroke-width="5"
    />
    <rect
      x="231.56"
      y="193.59"
      width="48.64"
      height="79.14"
      rx="11.67"
      transform="translate(-79.21 1.68) rotate(-27.8)"
      fill="none"
      stroke="#231f20"
      stroke-miterlimit="10"
      stroke-width="5"
    />
    <rect
      x="268.06"
      y="199.27"
      width="22.14"
      height="1.62"
      transform="translate(-61.1 8.7) rotate(-27.8)"
      fill="none"
      stroke="#231f20"
      stroke-miterlimit="10"
      stroke-width="3"
    />
    <rect
      x="285.39"
      y="232.15"
      width="22.14"
      height="1.62"
      transform="translate(-74.43 20.58) rotate(-27.8)"
      fill="none"
      stroke="#231f20"
      stroke-miterlimit="10"
      stroke-width="3"
    />
  </svg>
</template>

<script>
export default {
  name: 'ConnectionIssue',
};
</script>
