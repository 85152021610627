import { parseErrorMessage } from '#src/util/helpers.js';
import { defineStore } from '#src/stores/state-wrapper.js';
const DEFAULT_SNACKBAR_TIMEOUT = 2000;
const SUCCESS = 'success';
const ERROR = 'error';

export const useSnackbarStore = (pinia, hot) =>
  defineStore('snackbar', {
    state: () => ({
      show: false,
      message: null,
      timeout: 4000,
      color: null,
    }),
    actions: {
      showSnackbar({ message, timeout, color }) {
        this.message = message;
        this.timeout = timeout;
        this.color = color;
        this.show = true;
      },
      showSuccessSnackbar({ message, timeout = DEFAULT_SNACKBAR_TIMEOUT }) {
        this.showSnackbar({ message, timeout, color: SUCCESS });
      },
      showServerErrorSnackbar({ error, timeout = DEFAULT_SNACKBAR_TIMEOUT }) {
        this.showErrorSnackbar({ message: parseErrorMessage(error), timeout });
      },
      showErrorSnackbar({ message, timeout = DEFAULT_SNACKBAR_TIMEOUT }) {
        this.showSnackbar({ message, timeout, color: ERROR });
      },
      hideSnackbar() {
        this.show = false;
      },
    },
  })(pinia, hot);
