import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from '#src/stores/state-wrapper.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';

export const useStepLoginStore = (pinia, hot) =>
  defineStore('step-login', {
    getters: {
      step: () => steps.LOGIN,
      routeName: (s) => stepToRouteName[s.step],
      next: () => null,
      previous: () => null,
      hidesFooter: () => true,
      skipStepSave: () => true,
      hidesShare: () => true,
      hidesHeader: () => {
        const instance = useInstanceSettingsStore(pinia);
        return instance.chat || instance.use_modal || instance.use_webapp;
      },
    },
  })(pinia, hot);
