import { computed, watch, ref } from 'vue';

import { parseErrorMessage } from '#src/util/helpers';
import { useSnackbarStore } from '#src/stores/snackbar';

export const useInputProps = () => {
  return {
    store: {
      type: Function,
      required: false,
    },
    storeKey: {
      type: String,
      required: false,
    },
    savableProperty: {
      type: Object,
      required: false,
    },
    onChange: {
      type: Function,
      required: false,
      default: null,
    },
    overrideSave: {
      type: Function,
      required: false,
      default: null,
    },
    onAfterSave: {
      type: Function,
      required: false,
      default: null,
    },
    asyncValidator: {
      type: Function,
      required: false,
      default: null,
    },
    dirtyDependents: {
      type: Function,
      required: false,
      default: null,
    },
    dataTestid: {
      type: String,
      required: true,
    },
    isOptional: Boolean,
    executeSaveHook: Boolean,
  };
};

export const useInput = (
  {
    // Old, deprecated
    store,
    storeKey,
    // New, use instead
    savableProperty,
    onChange,
    overrideSave,
    asyncValidator,
    dirtyDependents,
    isOptional,
    onAfterSave,
    executeSaveHook,
  },
  pinia,
  DEBOUNCE_TIME = 300,
  { successClass, errorClass } = {},
) => {
  let propertyRef;
  if (store) {
    const fieldStore = store();
    propertyRef = fieldStore[storeKey];
  } else if (savableProperty) {
    propertyRef = savableProperty;
  } else {
    throw 'No Store or Savable Property Provided';
  }

  const model = computed({
    get() {
      return propertyRef.model;
    },
    set(v) {
      const oldValue = propertyRef.model;
      propertyRef.model = v;
      if (onChange) onChange(v, oldValue);
    },
  });

  const externallyValid = computed({
    get() {
      return propertyRef.requestMeta.externallyValid;
    },
    set(v) {
      propertyRef.requestMeta.externallyValid = v;
    },
  });

  const inputClasses = computed(() => {
    if (propertyRef.validation.success) return successClass || 'success-field';
    if (errorMessages.value.length) return errorClass || 'error-field';
    return '';
  });

  const color = computed(() => {
    return propertyRef.validation.success ? 'success' : 'primary';
  });

  const baseColor = computed(() => {
    return propertyRef.validation.success ? 'success' : 'basic';
  });

  const errorMessages = computed(() => {
    if (isOptional) return [];

    if (!propertyRef.validation.dirty && !propertyRef.requestMeta.errorMessage) {
      return [];
    }

    if (typeof dirtyDependents === 'function' && !dirtyDependents()) return [];

    return propertyRef.validation.errorMessages;
  });

  // debounce the validation so that we don't run a function on input event
  let timer;
  function debounceAndValidate() {
    propertyRef.validation.dirty = true;
    if (timer) clearTimeout(timer);
    timer = setTimeout(validateAndSave, DEBOUNCE_TIME);
  }

  const savingBuffer = ref({
    saving: false,
    saved: false,
    error: false,
    errorMessage: '',
    promise: null,
  });

  watch(
    () => propertyRef.requestMeta.requests.length,
    (v) => {
      savingBuffer.value.saving = Boolean(v);
      if (!v) {
        savingBuffer.value.saved = true;
        setTimeout(() => {
          savingBuffer.value.saved = false;
        }, 250);
      }
    },
  );

  watch(
    () => propertyRef.requestMeta.errorMessage,
    (v) => {
      savingBuffer.value.error = Boolean(v);
      const message = parseErrorMessage(v);
      savingBuffer.value.errorMessage = message;
      if (v) {
        useSnackbarStore(pinia).showErrorSnackbar({ message, timeout: -1 });
      }
    },
  );

  async function validateAndSave() {
    propertyRef.validation.dirty = true;

    if (asyncValidator) await asyncValidator();
    if (!propertyRef.validation.validate() && !executeSaveHook) return;

    let save = overrideSave;
    if (!overrideSave && store) {
      save = () => store().saveAttributes([storeKey]);
    }

    if (save) await save();
    if (onAfterSave) onAfterSave();
    if (!executeSaveHook) propertyRef.validation.dirty = false;
  }

  return {
    model,
    inputClasses,
    color,
    baseColor,
    errorMessages,
    savingBuffer,
    debounceAndValidate,
    validateAndSave,
    externallyValid,
  };
};
