<template>
  <v-col class="form-builder">
    <v-slide-y-transition group hide-on-leave>
      <v-row
        v-for="group in props.structure"
        :key="group.key"
        dense
        v-bind="group.componentProps"
        class="form-builder-row"
      >
        <v-slide-y-transition group hide-on-leave>
          <v-col
            v-for="field in group.fields"
            :key="field.key"
            :cols="field.colSize"
            :md="field.mdColSize"
            class="form-builder-column"
          >
            <form-builder-inner-component :field="field" />
          </v-col>
        </v-slide-y-transition>
      </v-row>
    </v-slide-y-transition>
  </v-col>
</template>

<script setup>
import FormBuilderInnerComponent from '#src/components/form-builder/FormBuilderInnerComponent.vue';

const props = defineProps({
  structure: {
    type: Array,
    required: true,
  },
});
</script>

<style lang="scss">
.form-builder {
  .form-builder-row,
  .form-builder-column {
    transition-duration: 50ms !important;
  }
}
</style>
