import { defineStore } from '#src/stores/state-wrapper.js';
import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';

import { useStepExamSchedulingStore } from '#src/stores/step-exam-scheduling.js';
import { useStepSignatureStore } from '#src/stores/step-signature.js';

export const useStepSignatureWaitingStore = (pinia, hot) =>
  defineStore('step-signature-waiting', {
    state: () => ({ signingByEmail: null, carrierEappUrl: null }),
    getters: {
      step: () => steps.SIGNATURE_WAITING,
      routeName: (s) => stepToRouteName[s.step],
      previous() {
        const examScheduling = useStepExamSchedulingStore(pinia);
        return examScheduling.step;
      },
      next() {
        const signature = useStepSignatureStore(pinia);
        return signature.step;
      },
      hidesFooter: (s) => s.signingByEmail !== null || s.carrierEappUrl,
      controlsNext: () => true,
    },
  })(pinia, hot);
