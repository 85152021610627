import {
  AutocompleteField,
  FormFieldAdapter,
  TextField,
} from '#src/components/form-builder/FormBuilderTemplates.js';
import { validateText } from '#src/composables/savable-property-validators.mjs';
import { useInComponentSavableProperty } from '#src/composables/savable-property.composable.js';
import { SelectQuestion, TextQuestion } from '#src/structures/CustomQuestions.js';
import { mdiFormSelect, mdiFormTextbox } from '@mdi/js';

export function customTextQuestion() {
  const property = useInComponentSavableProperty({
    rules: {
      validLength: validateText(() => property.model, { minLength: 1, maxLength: 255 }),
    },
  });

  return property;
}

export function customSelectQuestion(q) {
  const question = SelectQuestion(q);

  const property = useInComponentSavableProperty({
    rules: {
      validLength: {
        v() {
          return question.selectOptions.some(({ value }) => value === property.model);
        },
        message: 'Required',
      },
    },
  });

  return property;
}

export function customTextField(q, composable) {
  const question = TextQuestion(q);
  return FormFieldAdapter(
    TextField({
      savableProperty: composable,
      dataTestid: `custom-text-question-${question.id}`,
      label: question.question,
      placeholder: question.question,
      prependInnerIcon: mdiFormTextbox,
    }),
    { key: q.id, mdColSize: 6 },
    {
      isOptional: !question.required,
      clearable: true,
    },
  );
}

export function customSelectField(q, composable) {
  const question = SelectQuestion(q);

  return FormFieldAdapter(
    AutocompleteField({
      savableProperty: composable,
      dataTestid: `custom-select-question-${question.id}`,
      label: question.question,
      placeholder: question.question,
      items: question.selectOptions.map(({ value, text }) => ({ value, title: text })),
      prependInnerIcon: mdiFormSelect,
    }),
    { key: q.id, mdColSize: 6 },
    {
      isOptional: !question.required,
      clearable: true,
    },
  );
}
