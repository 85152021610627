export const STANDARD_HEALTH = 2;

export const LTC_ONLY_OPTIONS = [
  {
    value: 1,
    text: 'Poor - Life Insurance: Standard - LTCi: Class 1',
  },
  {
    value: STANDARD_HEALTH,
    text: '50% of applicants qualify for Average or better - LTCi: Select',
  },
  {
    value: 3,
    text: '50% of applicants qualify for Good or better - LTCi: Select',
  },
  {
    value: 4,
    text: '15% of applicants qualify for Great or better - LTCi: Preferred',
  },
  {
    value: 5,
    text: '15% of applicants qualify for Excellent - LTCi: Preferred',
  },
];

export const LIFE_ONLY_OPTIONS = [
  {
    value: 1,
    text: 'Poor - Life Insurance: Standard',
  },
  {
    value: 2,
    text: '75% of applicants qualify for Average or better - Life Insurance: Standard',
  },
  {
    value: 3,
    text: '40% of applicants qualify for Good or better - Life Insurance: Standard Plus',
  },
  {
    value: 4,
    text: '20% of applicants qualify for Great or better - Life Insurance: Preferred',
  },
  {
    value: 5,
    text: '10% of applicants qualify for Excellent - Life Insurance: Super Preferred',
  },
];

export const ALL_OPTIONS = [
  {
    value: 1,
    text: 'Poor - Life Insurance: Standard - LTCi: Class 1',
  },
  {
    value: STANDARD_HEALTH,
    text: '75% of applicants qualify for Average or better - Life Insurance: Standard - LTCi: Select',
  },
  {
    value: 3,
    text: '40% of applicants qualify for Good or better - Life Insurance: Standard Plus - LTCi: Select',
  },
  {
    value: 4,
    text: '20% of applicants qualify for Great or better - Life Insurance: Preferred - LTCi: Preferred',
  },
  {
    value: 5,
    text: '10% of applicants qualify for Excellent - Life Insurance: Super Preferred - LTCi: Preferred',
  },
];

export const AVAILABLE_HEALTH_CLASSES = [
  { text: 'Super Preferred', value: 5 },
  { text: 'Preferred', value: 4 },
  { text: 'Standard Plus', value: 3 },
  { text: 'Standard', value: STANDARD_HEALTH, range: [1, 2] },
  { text: 'Table 1', value: -1 },
  { text: 'Table 2', value: -2 },
  { text: 'Table 3', value: -3 },
  { text: 'Table 4', value: -4 },
  { text: 'Table 5', value: -5 },
  { text: 'Table 6', value: -6 },
  { text: 'Table 7', value: -7 },
  { text: 'Table 8 (Graded)', value: -8 },
];

export const AVAILABLE_LTC_HEALTH_CLASSES = [
  {
    text: 'Preferred',
    value: 5,
    range: [4, 5],
  },
  {
    text: 'Select',
    value: 3,
    range: [3, 2],
  },
  {
    text: 'Class 1',
    value: 1,
  },
  {
    text: 'Class 2',
    value: -1,
  },
];
