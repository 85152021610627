import { httpClient } from '#src/HttpClient.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';

export class ProductsService {
  #pinia;
  #baseUrl;
  #httpClient;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/products`;
    this.#httpClient = httpClient(pinia);
  }

  async validateCitizenship(product_id, visa_type) {
    const { data } = await this.#httpClient.post(`${this.#baseUrl}/validate_citizenship`, {
      product_id,
      visa_type,
    });
    return data.accepted;
  }
}
