import { validateFormStructure } from '#src/components/form-builder/FormBuilderTemplates.js';
import {
  useInsuredHeightProperty,
  useInsuredWeightProperty,
} from '#src/composables/insured-form-property.composable.js';

import { useInsuredStore } from '#src/stores/insured.js';

import { storeToRefs } from 'pinia';
import { computed, isRef } from 'vue';

// required must be a ref
export const useBuildForm = (
  pinia,
  module,
  { onAfterSave, onNextCb, hideSlots, skippable } = {},
) => {
  const insured = useInsuredStore(module, pinia);

  const { weight, height, gender, firstName } = storeToRefs(insured);

  const isSkippable = computed(() => {
    return isRef(skippable) ? skippable.value : false;
  });

  if (!weight.value.model) {
    height.value.model = {
      Male: 69,
      Female: 64,
    }[gender.value.model];
  }

  const hintText = computed(() => {
    let name = 'their';
    if (firstName.value) name = `${firstName.value}'s`;
    return `Don't know ${name} weight?`;
  });

  const heightField = () => {
    return useInsuredHeightProperty(pinia, module, { onAfterSave, mdColSize: 6 });
  };

  const weightField = () => {
    return useInsuredWeightProperty(pinia, module, {
      mdColSize: 6,
      label: 'Weight',
      placeholder: 'Weight',
      onAfterSave,
    });
  };

  const optionalWeightField = (slots = []) => {
    return useInsuredWeightProperty(pinia, module, {
      mdColSize: 6,
      label: 'Weight (Optional)',
      placeholder: 'Weight',
      persistentHint: true,
      hint: ' ',
      slots,
      onAfterSave,
    });
  };

  const optionalWeightFieldWithSkipButton = () => {
    return optionalWeightField([
      {
        name: 'message',
        fields: [
          {
            is: 'a',
            componentProps: {
              'data-testid': `${module}-skip-link`,
              class: 'text-primary clickable',
              onClick: onNextCb,
              text: hintText,
            },
          },
        ],
      },
    ]);
  };

  function buildStructure() {
    const fields = [heightField()];

    if (!isSkippable.value) fields.push(weightField());
    else if (hideSlots) fields.push(optionalWeightField());
    else fields.push(optionalWeightFieldWithSkipButton());

    return [{ fields, key: `${module}-build-root` }];
  }

  function validate() {
    if (isSkippable.value) return true;
    return validateFormStructure(buildStructure());
  }

  const reactiveDependencies = [isSkippable];

  return { validate, buildStructure, reactiveDependencies };
};
