import { httpClient } from '#src/HttpClient.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';
import { useEappStore } from '#src/stores/electronic-application.js';
import { isBefore } from 'date-fns/isBefore';

import { JOINT_INSURED, SPOUSE } from '#src/structures/Role.js';

export class HttpIndividualsService {
  #pinia;
  #baseUrl;
  #httpClient;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/individuals`;
    this.#httpClient = httpClient(pinia);
  }

  #attachEappData(params) {
    const { id, uuid } = useEappStore(this.#pinia);
    return {
      electronic_application_id: id,
      uuid: uuid,
      ...params,
    };
  }

  async createIndividual({
    application_roles_attributes,
    addresses_attributes,
    ...otherAttributes
  }) {
    const individual = {
      application_roles_attributes,
      addresses_attributes,
      ...otherAttributes,
    };
    const { data } = await this.#httpClient.post(
      this.#baseUrl,
      this.#attachEappData({ individual }),
    );
    return data?.individual;
  }

  async updateIndividual(id, individual) {
    const { data } = await this.#httpClient.put(
      `${this.#baseUrl}/${id}`,
      this.#attachEappData({ individual }),
    );
    return data.individual;
  }

  async updateIndividualGuardian(id, guardian) {
    const { data } = await this.#httpClient.post(
      `${this.#baseUrl}/${id}/guardian`,
      this.#attachEappData({ guardian }),
    );
    return data.individual;
  }

  async updateIndividualHomeAddress(id, address) {
    const { data } = await this.#httpClient.put(
      `${this.#baseUrl}/${id}/addresses/home`,
      this.#attachEappData({ address }),
    );

    return data.address;
  }

  deleteIndividual(id) {
    return this.#httpClient.delete(`${this.#baseUrl}/${id}`, {
      params: this.#attachEappData({}),
    });
  }

  async updateEft(id, { routing_number, account_number, draft_day_of_month }) {
    const body = this.#attachEappData({ routing_number, account_number, draft_day_of_month });

    const { data } = await this.#httpClient.put(`${this.#baseUrl}/${id}/eft`, body);
    return {
      account_last_4: data.account_number_last_4,
      routing_last_4: data.routing_number_last_4,
    };
  }

  scheduleExam(insuredId, { date }) {
    const body = this.#attachEappData({ date });
    return new this.#httpClient.post(`${this.#baseUrl}/${insuredId}/exam/schedule`, body);
  }

  skipExam(insuredId, { reason }) {
    const body = this.#attachEappData({ reason });
    return new this.#httpClient.post(`${this.#baseUrl}/${insuredId}/exam/skip`, body);
  }
  async getAvailability(insuredId, { street_address, city, state, zip }) {
    const body = this.#attachEappData({ street_address, city, state, zip });
    const { data } = await this.#httpClient.post(
      `${this.#baseUrl}/${insuredId}/exam/availabilities`,
      body,
    );

    const availabilities = [];
    if (data?.availabilities) availabilities.push(...data.availabilities);
    availabilities.sort((a, b) => isBefore(new Date(a.DateStart), new Date(b.DateStart)));
    return availabilities;
  }

  updateIndividualDriversLicense(insuredId, drivers_license) {
    return this.#httpClient.put(
      `${this.#baseUrl}/${insuredId}/drivers_license`,
      this.#attachEappData({ drivers_license }),
    );
  }

  sendIntroMessage(
    insuredId,
    { send_client_intro_message, first_name, last_name, phone_mobile, email },
  ) {
    const body = this.#attachEappData({
      send_client_intro_message,
      first_name,
      last_name,
      phone_mobile,
      email,
    });

    return this.#httpClient.post(`${this.#baseUrl}/${insuredId}/send_intro_message`, body);
  }

  async giveSpouseRole(insuredId) {
    const body = this.#attachEappData({});

    const response = await this.#httpClient.post(`${this.#baseUrl}/${insuredId}/spouse`, body);

    const spousalRole = response.data.individual.application_roles.find(
      (role) => role.role === SPOUSE,
    );

    return spousalRole.id;
  }

  async giveJointInsuredRole(insuredId) {
    const body = this.#attachEappData({});

    const response = await this.#httpClient.post(
      `${this.#baseUrl}/${insuredId}/joint_insured`,
      body,
    );

    const jointInsuredRole = response.data.individual.application_roles.find(
      (role) => role.role === JOINT_INSURED,
    );

    return jointInsuredRole.id;
  }

  saveSmokingUsages(insuredId, statuses) {
    return this.#httpClient.put(
      `${this.#baseUrl}/${insuredId}/smoking-statuses`,
      this.#attachEappData({ statuses }),
    );
  }

  deleteSmokingStatuses(insuredId) {
    return this.#httpClient.delete(`${this.#baseUrl}/${insuredId}/smoking-statuses`, {
      params: this.#attachEappData({}),
    });
  }

  async occupationUpdate(insuredId, { occupation = undefined, employer = undefined }) {
    const params = this.#attachEappData({ occupation, employer, new_behavior: true });
    const { data } = await this.#httpClient.put(`${this.#baseUrl}/${insuredId}/occupation`, params);

    return data.id;
  }

  occupationDelete(insuredId = null) {
    return this.#httpClient.delete(`${this.#baseUrl}/${insuredId}/occupation`, {
      params: this.#attachEappData({}),
    });
  }

  async getInsuredEnvelopeId(insuredId) {
    const body = this.#attachEappData({});
    const {
      data: { url },
    } = await this.#httpClient.post(`${this.#baseUrl}/${insuredId}/signature`, body);
    return url;
  }

  async getBuild({ id, height, weight }) {
    const body = this.#attachEappData({ height, weight });
    const { data } = await this.#httpClient.post(`${this.#baseUrl}/${id}/build`, body);
    return {
      minWeight: data?.build?.min_weight,
      maxWeight: data?.build?.max_weight,
      rating: data?.build?.rating,
      okayToProceed: data?.okay_to_proceed,
    };
  }

  async humanSession(id) {
    const body = this.#attachEappData({});
    const { data } = await this.#httpClient.post(`${this.#baseUrl}/${id}/human_session`, body);
    return data?.session_token;
  }

  updateMedicalSources(id, requested, connected) {
    const body = this.#attachEappData({ requested, connected });

    return this.#httpClient.post(`${this.#baseUrl}/${id}/medical_source`, body);
  }
}
