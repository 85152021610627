import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from '#src/stores/state-wrapper.js';
import { REQS } from '#src/enumerations/data-requirements.js';
import { SECONDARY_ADDRESSEE } from '#src/structures/Role.js';

import { useStepPhysicianStore } from '#src/stores/step-physician.js';
import { getPartyStore, useEappStore } from '#src/stores/electronic-application.js';
import { useStepUnderwritingStore } from '#src/stores/step-underwriting.js';

export const useStepSecondaryAddresseeStore = (pinia, hot) =>
  defineStore('step-secondary-addressee', {
    state: () => ({
      //defined by eApp update
      inactive: false,
      requiredFields: {
        address: false,
        phone: false,
        email: false,
        relationship: false,
        birthdate: false,
      },
    }),
    getters: {
      step: () => steps.SECONDARY_ADDRESSEE,
      routeName: (s) => stepToRouteName[s.step],
      previous() {
        const physician = useStepPhysicianStore(pinia);
        if (physician.inactive) return physician.previous;
        return physician.step;
      },
      next() {
        const underwriting = useStepUnderwritingStore(pinia);
        return underwriting.step;
      },
      secondaryAddresseeId() {
        const eApp = useEappStore(pinia);
        return eApp.parties.find((p) => {
          const store = getPartyStore(p, pinia);
          return Boolean(store.roles[SECONDARY_ADDRESSEE]);
        });
      },
    },
    actions: {
      setStepRequirements(srDict) {
        this.inactive = !srDict[REQS.SECONDARY_ADDRESSEE];
        this.requiredFields.address = srDict[REQS.SECONDARY_ADDRESSEE_ADDRESS];
        this.requiredFields.phone = srDict[REQS.SECONDARY_ADDRESSEE_PHONE];
        this.requiredFields.email = srDict[REQS.SECONDARY_ADDRESSEE_EMAIL];
        this.requiredFields.relationship = srDict[REQS.SECONDARY_ADDRESSEE_RELATIONSHIP];
        this.requiredFields.birthdate = srDict[REQS.SECONDARY_ADDRESSEE_BIRTHDATE];
      },
    },
  })(pinia, hot);
