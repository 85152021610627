import {
  AutocompleteField,
  DateField,
  FormFieldAdapter,
} from '#src/components/form-builder/FormBuilderTemplates.js';
import { SMOKING_FREQUENCIES, SMOKING_TYPES } from '#src/structures/SmokingUsage.js';
import { useSmokingUsage } from '#src/stores/insured-smoking-usage.js';
import { mdiCalendar, mdiCalendarEnd, mdiSmoking } from '@mdi/js';

function storeKeyPrefix(storeKey, testId) {
  return `${storeKey}-${testId}`;
}

export function useLastUseDateProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    DateField({
      storeKey: 'last_use_date',
      label: 'When did you quit?',
      placeholder: 'mm/dd/yyyy',
      dataTestid: storeKeyPrefix(module, 'last-use-date'),
      prependInnerIcon: mdiCalendarEnd,
      savableProperty: useSmokingUsage(module, pinia).last_use_date,
    }),
    {
      mdColSize: additionalProps?.mdColSize || 6,
      colSize: 12,
      key: additionalProps.key || 'last_use_date',
    },
    additionalProps,
  );
}

export function useCategoryProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    AutocompleteField({
      storeKey: 'category',
      dataTestid: storeKeyPrefix(module, 'category'),
      label: 'What Type?',
      placeholder: 'What Type?',
      prependInnerIcon: mdiSmoking,
      items: SMOKING_TYPES,
      savableProperty: useSmokingUsage(module, pinia).category,
    }),
    {
      mdColSize: additionalProps?.mdColSize || 6,
      colSize: 12,
      key: additionalProps.key || 'category',
    },
    additionalProps,
  );
}

export function useFrequencyProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    AutocompleteField({
      dataTestid: storeKeyPrefix(module, 'frequency'),
      label: 'How Often?',
      placeholder: 'How Often?',
      prependInnerIcon: mdiCalendar,
      items: SMOKING_FREQUENCIES,
      savableProperty: useSmokingUsage(module, pinia).frequency,
    }),
    {
      mdColSize: additionalProps?.mdColSize || 6,
      colSize: 12,
      key: additionalProps.key || 'frequency',
    },
    additionalProps,
  );
}
