import { mdiHumanFemale, mdiHumanMale } from '@mdi/js';

export const GENDER_ITEMS = [
  {
    id: 'Male',
    title: 'Male',
    value: 'Male',
    color: 'male',
    icon: mdiHumanMale,
    dataTestid: 'gender-Male',
    defaultHeight: 69,
  },
  {
    id: 'Female',
    title: 'Female',
    value: 'Female',
    color: 'female',
    icon: mdiHumanFemale,
    dataTestid: 'gender-Female',
    defaultHeight: 64,
  },
];
