<template>
  <referral-complete v-if="referralIsComplete" />
  <step-layout v-else show-logo>
    <template #title> {{ greeting }} </template>
    <template #body>
      <form-builder :style="{ maxWidth: smAndDown ? '100%' : '900px' }" :structure="structure" />
    </template>
  </step-layout>
</template>

<script setup>
import ReferralComplete from '#src/components/steps/referral/ReferralComplete.vue';
import FormBuilder from '#src/components/form-builder/FormBuilder.vue';
import StepLayout from '#src/components/shared/StepLayout.vue';

import { INSURED_TYPES, usePrimaryInsuredStore } from '#src/stores/insured';
import { useInstanceSettingsStore } from '#src/stores/instance-settings';
import { useEappStore } from '#src/stores/electronic-application';

import { useDisplay } from 'vuetify/lib/framework.mjs';
import { useReferralForm } from '#src/components/steps/referral/referral-form.composable';
import { useNameForm } from '#src/components/steps/name/name-form.composable';
import { useGenderForm } from '#src/components/steps/gender/gender-form.composable';
import { useBirthdateForm } from '#src/components/steps/birthdate/birthdate-form.composable';
import { ref, computed, inject, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { dynamicColCompute, unblockMainThread } from '#src/util/helpers';

const { smAndDown } = useDisplay();

const pinia = inject('pinia');
const insured = usePrimaryInsuredStore(pinia);
const eApp = useEappStore(pinia);
const instance = useInstanceSettingsStore(pinia);

const { activeFirstInput, greeting, show_refer_text } = storeToRefs(instance);
if (show_refer_text.value) eApp.send_refer_text.load(true);

const referralIsComplete = ref(eApp.refer);
const shouldAutofocus = computed(
  () => !insured.first_name.model && Boolean(activeFirstInput.value),
);

function valid() {
  if (referralIsComplete.value) return true;

  const validatedForms = [
    name.validate(),
    gender.validate(),
    birthdate.validate(),
    referral.validate(),
  ];
  return validatedForms.every((v) => v === true);
}

function buildStructure() {
  const structure = [];

  const meshableFields = [];

  const nameStruct = name.buildStructure();
  nameStruct.forEach((s) => {
    meshableFields.push(...s.fields);
  });

  const genderStruct = gender.buildStructure();
  genderStruct.forEach((s) => {
    meshableFields.push(...s.fields);
  });

  const birthdateStruct = birthdate.buildStructure();
  birthdateStruct.forEach((s) => {
    meshableFields.push(...s.fields);
  });

  const chunkedFields = Object.values(dynamicColCompute(meshableFields, 2, 12));
  chunkedFields.forEach((colSize, index) => (meshableFields[index].mdColSize = colSize));

  structure.push({ fields: meshableFields, key: 'insured-data' });

  structure.push(...referral.buildStructure());
  return structure;
}

const name = useNameForm(pinia, INSURED_TYPES.INSURED, { autofocus: shouldAutofocus });
const gender = useGenderForm(pinia, INSURED_TYPES.INSURED, { dense: true });
const birthdate = useBirthdateForm(pinia, INSURED_TYPES.INSURED, { dense: true });
const referral = useReferralForm(pinia, valid);

const renderStructure = () => unblockMainThread(() => (structure.value = buildStructure()));

watch(birthdate.reactiveDependencies, renderStructure);
watch(referral.reactiveDependencies, () => (referralIsComplete.value = true));

const structure = ref([]);
renderStructure();

defineExpose({ valid });
</script>
