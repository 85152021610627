<template>
  <p>
    <template v-for="part in props.textLinkParts" :key="part.text">
      <a v-if="part.type === 'a'" v-bind="part.props"> {{ part.text }}</a>
      <span v-else v-bind="part.props"> {{ part.text }}</span>
    </template>
  </p>
</template>

<script setup>
const props = defineProps({
  textLinkParts: {
    type: Array,
    default: () => [],
  },
});
</script>
