import { httpClient } from '#src/HttpClient.js';
import { useEappStore } from '#src/stores/electronic-application.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';
import { parseErrorMessage } from '#src/util/helpers.js';

export class EappService {
  #pinia;
  #baseUrl;
  #httpClient;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/electronic_applications`;
    this.#httpClient = httpClient(pinia);
  }

  #attachEappData(params) {
    const { uuid, id } = useEappStore(this.#pinia);
    return {
      electronic_application_id: id,
      uuid: uuid,
      ...params,
    };
  }

  async createElectronicApplication(electronic_application) {
    try {
      const { data } = await this.#httpClient.post(this.#baseUrl, {
        electronic_application,
      });

      return data.electronic_application;
    } catch (e) {
      throw parseErrorMessage(e);
    }
  }

  async updateElectronicApplication(electronic_application) {
    const body = this.#attachEappData({ electronic_application });

    const { data } = await this.#httpClient.put(
      `${this.#baseUrl}/${body.electronic_application_id}`,
      body,
    );

    return data.electronic_application;
  }

  async examRequiredBackendCheck() {
    const body = this.#attachEappData({});
    const { data } = await this.#httpClient.post(
      `${this.#baseUrl}/${body.electronic_application_id}/exam_required`,
      body,
    );
    return data.exam_required;
  }

  async fetchEapp(body) {
    const params = this.#attachEappData(body);
    const { data } = await this.#httpClient.get(`${this.#baseUrl}/${params.uuid}`, {
      params,
    });
    return data.electronic_application;
  }

  async resumeEApp(resume, id, uuid) {
    const { data } = await this.#httpClient.get(`${this.#baseUrl}/${id}`, {
      params: {
        electronic_application_id: id,
        uuid,
        resume,
      },
    });
    return data.electronic_application;
  }

  async sendEmail({ to, message }) {
    const body = this.#attachEappData({ to, message });
    return this.#httpClient.post(
      `${this.#baseUrl}/${body.electronic_application_id}/send_email`,
      body,
    );
  }

  notifyUnlicensedAgent(not_licensed_in_state) {
    const body = this.#attachEappData({ not_licensed_in_state });
    return this.#httpClient.post(
      `${this.#baseUrl}/${body.electronic_application_id}/not_licensed_in_state`,
      body,
    );
  }

  async getCarrierEAppUrl() {
    const body = this.#attachEappData({});
    const { data } = await this.#httpClient.post(
      `${this.#baseUrl}/${body.electronic_application_id}/resume_carrier_eapp`,
      body,
    );

    return data.url;
  }
}
