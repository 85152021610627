import { defineStore } from '#src/stores/state-wrapper.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings';
import { getEnv } from '#src/util/helpers';

export const useGoogleMaps = (pinia, hot) => {
  return defineStore('google-maps', {
    state: () => ({
      initializing: false,
      initialized: false,
      promise: null,
      googlePlaces: null,
      googleAutocomplete: null,
    }),
    actions: {
      async initGoogleMaps() {
        const mapsEl = document.getElementById('quote-and-apply-google-maps');
        if (mapsEl && this.initialized) return;
        if (mapsEl && this.initializing) return this.promise;

        this.promise = new Promise((resolve, reject) => {
          this.initializing = true;
          const script = document.createElement('script');
          script.id = 'quote-and-apply-google-maps';
          script.setAttribute('async', true);
          script.setAttribute('defer', true);
          const instance = useInstanceSettingsStore(pinia);
          script.setAttribute('nonce', instance.nonce);
          const env = getEnv();
          const key = env.VITE_GOOGLE_MAPS_ID;
          const mapsUrl = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback`;
          script.setAttribute('src', mapsUrl);
          script.onload = () => {
            this.initializing = false;
            this.initialized = true;

            try {
              this.googleAutocomplete = new window.google.maps.places.AutocompleteService();
              this.googlePlaces = new window.google.maps.places.PlacesService(
                document.createElement('div'),
              );
            } catch (e) {
              reject();
            }
            resolve();
          };
          script.onerror = () => {
            this.initializing = false;
            this.initialized = true;
            reject();
          };
          document.body.appendChild(script);
        });

        return this.promise;
      },
      async getPlaces(search) {
        return new Promise((resolve, reject) => {
          this.googleAutocomplete.getPlacePredictions(
            {
              input: search,
              types: ['geocode'],
              componentRestrictions: { country: 'us' },
            },
            (res) => resolve(res?.filter(Boolean) || []),
            reject,
          );
        });
      },
      async getPlaceDetails(placeId) {
        return new Promise((resolve, reject) => {
          this.googlePlaces.getDetails(
            {
              placeId,
              fields: ['address_components', 'name'],
            },
            (res) => {
              let street_address, city, state, zip, country;
              street_address = res?.name;
              res?.address_components?.forEach((component) => {
                const type = component.types[0];
                if (type === 'locality') {
                  city = component.long_name;
                } else if (type === 'administrative_area_level_1') {
                  state = component.short_name;
                } else if (type === 'postal_code') {
                  zip = component.long_name;
                } else if (type === 'country') {
                  country = component.long_name;
                }
              });

              resolve({ street_address, city, state, zip, country });
            },
            reject,
          );
        });
      },
    },
  })(pinia, hot);
};
