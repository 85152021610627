import { httpClient } from '#src/HttpClient.js';
import { useEappStore } from '#src/stores/electronic-application.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';

export class CoverageService {
  #pinia;
  #baseUrl;
  #httpClient;

  static CARRIERS = {
    OTHER: 'Other',
  };

  constructor(pinia) {
    this.#pinia = pinia;

    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/electronic_application_cases`;
    this.#httpClient = httpClient(pinia);
  }

  async createCoverage(electronic_application_case) {
    const { data } = await this.#httpClient.post(
      this.#baseUrl,
      this.#attachEAppData({
        electronic_application_case,
      }),
    );

    return data.electronic_application_case;
  }

  updateCoverage(coverageId, electronic_application_case) {
    return this.#httpClient.put(
      `${this.#baseUrl}/${coverageId}`,
      this.#attachEAppData({ electronic_application_case }),
    );
  }

  deleteCoverage(coverageId) {
    return this.#httpClient.delete(`${this.#baseUrl}/${coverageId}`, {
      params: this.#attachEAppData({}),
    });
  }

  async getCoverageCarriers() {
    const { data } = await this.#httpClient.get(`${this.#baseUrl}/carriers`);
    return data.carriers.map(({ id: value, name: title }) => ({
      value,
      title,
    }));
  }

  #attachEAppData(params) {
    const { id, uuid } = useEappStore(this.#pinia);
    return {
      electronic_application_id: id,
      uuid: uuid,
      ...params,
    };
  }
}
