import { INSURED_TYPES } from '#src/stores/insured.js';
import {
  useInsuredEmailProperty,
  useInsuredPhoneMobileProperty,
} from '#src/composables/insured-form-property.composable.js';
import {
  AutocompleteField,
  FormButton,
  FormFieldAdapter,
  validateFormStructure,
} from '#src/components/form-builder/FormBuilderTemplates.js';
import { computed, ref } from 'vue';
import { useEappStore } from '#src/stores/electronic-application.js';
import { useSnackbarStore } from '#src/stores/snackbar.js';
import { dynamicColCompute, parseErrorMessage } from '#src/util/helpers.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';
import { QUESTION_TYPES } from '#src/structures/CustomQuestions.js';
import {
  customSelectField,
  customSelectQuestion,
  customTextField,
  customTextQuestion,
} from '#src/components/steps/referral/dynamic-refer-question.composable.js';
import {
  useEappBestTimeToCallProperty,
  useEappPurposeOfInsuranceProperty,
  useEappReferNotesProperty,
  useEappReferTextProperty,
  useEappRoundRobinAgentIdProperty,
} from '#src/composables/eapp-form-property.composable.js';
import { useQuotingStore } from '#src/stores/quoting.js';
import { useInComponentSavableProperty } from '#src/composables/savable-property.composable.js';
import { STATES } from '#src/data/states-and-countries.js';
import { mdiHomeMapMarker } from '@mdi/js';

// externalValidate -> not my favorite solution, but it'll do.
export const useReferralForm = (pinia, externalValidate) => {
  const eApp = useEappStore(pinia);
  const instance = useInstanceSettingsStore(pinia);
  const snackbar = useSnackbarStore(pinia);
  const quoting = useQuotingStore(pinia);
  const loading = ref(false);
  const loadingComp = computed(() => loading.value);
  const customFields = [];
  const questions = [];

  const eappCreated = ref(false);

  const stateProperty = useInComponentSavableProperty({
    rules: {
      isInList: {
        v: () => STATES.some((s) => s.value === stateProperty.model),
      },
    },
  });
  stateProperty.load(quoting.params.state);

  const stateQuestion = FormFieldAdapter(
    AutocompleteField({
      savableProperty: stateProperty,
      dataTestid: 'insured-state',
      items: STATES,
      label: 'State',
      placeholder: 'State',
      prependInnerIcon: mdiHomeMapMarker,
    }),
    { key: 'insured-state', mdColSize: 6, colSize: 12 },
    {
      onChange: () => {
        quoting.params.state = stateProperty.model;
      },
      overrideSave: () => {},
    },
  );

  if (instance.approved_domain_questions?.length) {
    instance.approved_domain_questions.forEach((q) => {
      // we need id and answer...
      if (q.inputType === QUESTION_TYPES.TEXT) {
        const composable = customTextQuestion(q);
        questions.push({ composable, question: q });
        customFields.push(customTextField(q, composable));
      } else if (q.inputType === QUESTION_TYPES.SELECT) {
        const composable = customSelectQuestion(q);
        questions.push({ composable, question: q });
        customFields.push(customSelectField(q, composable));
      }
    });
  }

  function buildStructure() {
    const fields = [
      stateQuestion,
      useInsuredEmailProperty(pinia, INSURED_TYPES.INSURED, {
        mdColSize: 6,
        overrideSave: () => {},
      }),
      useInsuredPhoneMobileProperty(pinia, INSURED_TYPES.INSURED, {
        mdColSize: 6,
        overrideSave: () => {},
      }),
      useEappPurposeOfInsuranceProperty(pinia, { mdColSize: 6, overrideSave: () => {} }),
      useEappBestTimeToCallProperty(pinia, { mdColSize: 6, overrideSave: () => {} }),
      ...customFields,
    ];

    if (instance.round_robin_agents.length) {
      eApp.round_robin_agent_id.validation.validate();
      fields.push(
        useEappRoundRobinAgentIdProperty(pinia, {
          mdColSize: 6,
          overrideSave: () => {},
          items: [{ title: 'No Preference', value: null }, ...instance.round_robin_agents],
        }),
      );
    }

    const chunkedFields = Object.values(dynamicColCompute(fields, 2, 12));
    chunkedFields.forEach((colSize, index) => {
      fields[index].mdColSize = colSize;
      fields.colSize = 12;
    });

    fields.push(useEappReferNotesProperty(pinia, { mdColSize: 12, overrideSave: () => {} }));
    if (instance.show_refer_text) {
      fields.push(useEappReferTextProperty(pinia, { mdColSize: 12, overrideSave: () => {} }));
    }

    fields.push(
      FormButton('Complete Referral', 'complete-referral', {
        dataTestid: 'complete-referral',
        onClick: async () => {
          if (!externalValidate()) return;
          loading.value = true;
          eApp.refer = true;

          const customQuestionDataMap = [];
          if (questions.length) {
            questions.forEach((q) => {
              if (!q.composable.model && q.composable.model !== 0) return;
              customQuestionDataMap.push({ id: q.question.id, answer: q.composable.model });
            });
          }

          try {
            await eApp.createAndPrefill({}, { questions: customQuestionDataMap });
            eappCreated.value = true;
          } catch (e) {
            snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
          } finally {
            loading.value = false;
          }
        },
        loading: loadingComp,
        class: 'text-none',
        color: 'accent',
        size: 'large',
      }),
    );
    return [{ fields, key: 'form' }];
  }

  function validate() {
    return validateFormStructure(buildStructure());
  }

  const reactiveDependencies = [eappCreated];

  return { buildStructure, validate, reactiveDependencies };
};
