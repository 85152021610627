<template>
  <v-row align="center" justify="center" dense>
    <v-col :sm="dense ? 12 : 4" cols="12">
      <div>
        <span :for="`${dataTestid}-month`" class="sr-only">Birth Month</span>
        <v-select
          :id="`${dataTestid}-month`"
          ref="month"
          v-model="month"
          required
          variant="outlined"
          label="Birth Month"
          placeholder="Month"
          density="compact"
          :class="{
            'success-field': !!month,
            'error-field': errorMessages.length,
          }"
          :base-color="!!month ? 'success' : 'basic'"
          :color="!!month ? 'success' : 'primary'"
          :menu-props="{ eager: true, offsetY: true, auto: true }"
          :items="months"
          :autofocus="autofocus"
          :error-messages="errorMessages"
          data-testid="birthdate-month"
          @keydown="handleKeydown"
          @update:model-value="updateMonth"
        />
      </div>
    </v-col>
    <v-col :sm="dense ? 12 : 4" cols="12">
      <integer-input
        ref="day"
        v-model.number="day"
        :min="0"
        :max="maxDay"
        placeholder="Day"
        required
        variant="outlined"
        density="compact"
        autocomplete="off"
        label="Birth Day"
        data-testid="birthdate-day"
        :class="{ 'success-field': !!day, 'error-field': errorMessages.length }"
        :base-color="!!day ? 'success' : 'basic'"
        :color="!!day ? 'success' : 'primary'"
        :error-messages="errorMessages"
        @update:model-value="updateDay"
      >
      </integer-input>
    </v-col>
    <v-col :sm="dense ? 12 : 4" cols="12">
      <integer-input
        ref="year"
        v-model.number="year"
        variant="outlined"
        density="compact"
        placeholder="Year"
        :class="{ 'success-field': !!year, 'error-field': errorMessages.length }"
        :base-color="!!year ? 'success' : 'basic'"
        :color="!!year ? 'success' : 'primary'"
        :max="maxYear"
        :error-messages="errorMessages"
        required
        autocomplete="off"
        label="Birth Year"
        data-testid="birthdate-year"
        @update:model-value="updateValue"
      >
      </integer-input>
    </v-col>
  </v-row>
</template>

<script>
import IntegerInput from '#src/components/inputs/IntegerInput.vue';
import { subYears } from 'date-fns/subYears';
import { setDate } from 'date-fns/setDate';
import { setMonth } from 'date-fns/setMonth';
import { setYear } from 'date-fns/setYear';
import { parse } from 'date-fns/parse';
import { isValid } from 'date-fns/isValid';
import { getDaysInMonth } from 'date-fns/getDaysInMonth';
import { format } from 'date-fns/format';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default {
  components: {
    IntegerInput,
  },
  props: {
    dataTestid: { type: String, required: true },
    valid: Boolean,
    autofocus: Boolean,
    dense: Boolean,
    modelValue: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    const minYear = subYears(new Date(), 86).getFullYear();
    const maxYear = new Date().getFullYear();

    let year = null,
      month = null,
      day = null;

    if (this.modelValue) {
      [year, month, day] = this.modelValue.split('-').map(Number);
    }

    return {
      months: MONTHS.map((title, value) => ({ title, value: value + 1 })),
      month,
      day,
      year,
      minDay: 1,
      minYear,
      maxYear,
    };
  },
  computed: {
    isMobile() {
      let check = false;

      /* eslint-disable no-useless-escape */
      /* eslint-disable max-len */
      /* eslint-disable func-names */
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4),
          )
        ) {
          check = true;
        }
      })(navigator.userAgent || navigator.vendor || window.opera);

      return check;
    },
    maxDay() {
      if (!this.month || !this.year) return 31;
      const date = new Date();
      date.setMonth(this.month - 1);
      date.setYear(this.year);
      return getDaysInMonth(date);
    },
  },
  watch: {
    modelValue(value) {
      if (!value) return;
      const date = parse(value, 'yyyy-MM-dd', new Date());

      if (isValid(date)) {
        this.month = date.getMonth() + 1;
        this.day = date.getDate();
        this.year = date.getFullYear();
      } else {
        this.month = null;
        this.day = null;
        this.year = null;
      }
    },
    month() {
      if (!this.year || !this.month) return;
      const date = new Date();
      date.setMonth(this.month - 1);
      date.setYear(this.year);
      this.day = Math.min(getDaysInMonth(date), this.day);
      this.$refs.day.inputValue = this.day;
    },
    year() {
      if (!this.month || !this.month) return;
      const date = new Date();
      date.setMonth(this.month - 1);
      date.setYear(this.year);
      if (isNaN(getDaysInMonth(date))) return;
      this.day = Math.min(getDaysInMonth(date), this.day);
      this.$refs.day.inputValue = this.day;
    },
  },
  methods: {
    handleKeydown(e) {
      e.preventDefault();
      const permittedKeys = {
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        J: 1,
        j: 1,
        F: 2,
        f: 2,
        M: 3,
        m: 3,
        A: 4,
        a: 4,
        S: 9,
        s: 9,
        O: 10,
        o: 10,
        N: 11,
        n: 11,
        D: 12,
        d: 12,
      };

      if (permittedKeys[e.key]) {
        this.month = +permittedKeys[e.key];
        this.focusDay();
        return;
      }
    },
    focusYear() {
      if (this.$refs.year?.focus) this.$refs.year.focus();
    },
    focusDay() {
      if (this.$refs.day?.focus) this.$refs.day.focus();
    },
    updateMonth() {
      const shouldFocusDay = !this.day && !this.isMobile;
      if (shouldFocusDay) this.focusDay();

      this.updateValue();
    },
    updateDay() {
      const shouldFocusYear = !this.year && !this.isMobile && this.day >= 4;

      if (shouldFocusYear) this.focusYear();

      this.updateValue();
    },
    updateValue() {
      if (!this.year || !this.month || !this.day || this.year.toString().length !== 4) {
        if (this.modelValue !== null) this.$emit('update:modelValue', null);
        return;
      }

      const dateWithoutDay = setMonth(setYear(new Date(), this.year), this.month - 1);

      const endOfMonthDay = getDaysInMonth(dateWithoutDay);

      if (this.day > endOfMonthDay) {
        this.day = endOfMonthDay;
      }

      let date = new Date();
      date = setYear(date, this.year);
      date = setMonth(date, this.month - 1);
      date = setDate(date, this.day);

      const dateIsValid = isValid(date);

      if (dateIsValid) {
        this.$emit('update:modelValue', format(date, 'yyyy-MM-dd'));
      } else {
        this.$emit('update:modelValue', null);
      }
    },
  },
};
</script>
