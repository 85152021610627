import {
  savablePropertyRequestWrapper,
  useSavableProperty,
} from '#src/composables/savable-property.composable.js';

import {
  validateIsAfter,
  validateIsBeforeNow,
  validateText,
} from '#src/composables/savable-property-validators.mjs';

import { STATES } from '#src/data/states-and-countries.js';
import { HttpDoctorVisitsService } from '#src/services/http-doctor-visits.service.js';
import { generateUuid } from '#src/util/helpers.js';
import { defineStore } from '#src/stores/state-wrapper.js';
import { useInsuredStore } from '#src/stores/insured.js';

const REQ_GROUPS = {
  ROOT: 'ROOT',
  ADDRESS: 'ADDRESS',
};

export const doctorVisitKeygen = (id) => `doctor-visit-${id}`;

export function useDoctorVisit(id, pinia, hot) {
  const httpDoctorVisitsService = new HttpDoctorVisitsService(pinia);
  return defineStore(id, {
    state: () => ({
      id: null,
      insuredType: null,
      doctor: useSavableProperty({
        group: REQ_GROUPS.ROOT,
        requestMap: 'doctor',
        rules: {
          validText: validateText(() => useDoctorVisit(id, pinia).doctor.model, {
            minLength: 1,
            maxLength: 255,
          }),
        },
      }),
      visit_date: useSavableProperty({
        group: REQ_GROUPS.ROOT,
        requestMap: 'visit_date',
        rules: {
          dateIsBeforeNow: validateIsBeforeNow(() => useDoctorVisit(id, pinia).visit_date.model),
          dateAfterInsuredBirthdate: validateIsAfter(
            () => useDoctorVisit(id, pinia).visit_date.model,
            () => useInsuredStore(useDoctorVisit(id, pinia).insuredType, pinia).birthdate.model,
          ),
        },
      }),
      visit_details: useSavableProperty({
        group: REQ_GROUPS.ROOT,
        requestMap: 'visit_details',
        rules: {
          validText: validateText(() => useDoctorVisit(id, pinia).visit_details.model, {
            minLength: 1,
            maxLength: 255,
          }),
        },
      }),
      street_address: useSavableProperty({
        group: REQ_GROUPS.ADDRESS,
        requestMap: 'street_address',
        rules: {
          validText: validateText(() => useDoctorVisit(id, pinia).street_address.model, {
            minLength: 1,
            maxLength: 255,
          }),
        },
      }),
      city: useSavableProperty({
        group: REQ_GROUPS.ADDRESS,
        requestMap: 'city',
        rules: {
          validText: validateText(() => useDoctorVisit(id, pinia).city.model, {
            minLength: 1,
            maxLength: 255,
          }),
        },
      }),
      state: useSavableProperty({
        requestMap: 'state',
        group: REQ_GROUPS.ADDRESS,
        rules: {
          minLength: {
            message: 'Must be a valid state',
            v: () => {
              const store = useDoctorVisit(id, pinia);
              return STATES.some(({ value }) => value === store.state.model);
            },
          },
        },
      }),
      zip: useSavableProperty({
        requestMap: 'zip',
        group: REQ_GROUPS.ADDRESS,
        rules: {
          correctLength: validateText(() => useDoctorVisit(id, pinia).zip.model, {
            exactLength: 5,
          }),
        },
      }),
    }),
    actions: {
      setFromEapp(insuredType, doctorVisit) {
        this.id = doctorVisit.id;
        this.insuredType = insuredType;
        this.doctor.load(doctorVisit.doctor);
        this.visit_date.load(doctorVisit.visit_date);
        this.visit_details.load(doctorVisit.visit_details);
        if (doctorVisit.address) {
          this.street_address.load(doctorVisit.address.street_address);
          this.city.load(doctorVisit.address.city);
          this.state.load(doctorVisit.address.state);
          this.zip.load(doctorVisit.address.zip);
        }
      },
      async savablePropertyWrapper(func, { attributes, body, uuid }) {
        const composableAttributes = attributes.map((a) => this[a]);
        return savablePropertyRequestWrapper(func, { composableAttributes, body, uuid });
      },
      saveAttributes(attributes) {
        if (!attributes.length) return;

        const reqMap = {};
        attributes.forEach((a) => {
          const key = this[a].requestMeta.requestMap;
          const group = this[a].requestMeta.group;
          const value = this[a].requestMeta.format();
          if (!reqMap[group]) reqMap[group] = {};
          reqMap[group][key] = value;
        });

        let body = {};
        if (reqMap[REQ_GROUPS.ROOT]) body = { ...reqMap[REQ_GROUPS.ROOT] };
        if (reqMap[REQ_GROUPS.ADDRESS]) body.address = { ...reqMap[REQ_GROUPS.ADDRESS] };

        return this.savablePropertyWrapper(
          () => httpDoctorVisitsService.updateDoctorVisit(this.id, body),
          {
            attributes,
            body,
            uuid: generateUuid(),
          },
        );
      },
    },
  })(pinia, hot);
}
