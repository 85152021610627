import { httpClient } from '#src/HttpClient.js';
import { useEappStore } from '#src/stores/electronic-application.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';

export class IllustrationsService {
  #pinia;
  #baseUrl;
  #httpClient;
  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/illustrations`;
    this.#httpClient = httpClient(pinia);
  }

  #attachEappData(params) {
    const { id, uuid } = useEappStore(this.#pinia);
    return {
      electronic_application_id: id,
      uuid: uuid,
      ...params,
    };
  }

  getIllustration(id) {
    return this.#httpClient.put(`${this.#baseUrl}/${id}`, this.#attachEappData({}));
  }
}
